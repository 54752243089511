export default function tabsHTML(idx, breedingShow, progenyShow, feedlotShow, copShow, saleShow) {
  return `
    <ul class="nav nav-tabs justify-content-center" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="infoa${idx}-tab" data-bs-toggle="tab"
          data-bs-target="#infoa${idx}" type="button" role="tab" aria-controls="infoa${idx}"
          aria-selected="true">
          Info
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="carcassa${idx}-tab" data-bs-toggle="tab"
          data-bs-target="#carcassa${idx}" type="button" role="tab" aria-controls="carcassa${idx}"
          aria-selected="true">
          Carcass
        </button>
      </li>
      <li class="nav-item ${!breedingShow ? 'd-none' : ''}" role="presentation">
        <button class="nav-link" id="breedinga${idx}-tab" data-bs-toggle="tab"
          data-bs-target="#breedinga${idx}" type="button" role="tab" aria-controls="breedinga${idx}"
          aria-selected="false" tabindex="-1">
          Breeding
        </button>
      </li>
      <li class="nav-item ${!progenyShow ? 'd-none' : ''}" role="presentation">
        <button class="nav-link" id="progenya${idx}-tab" data-bs-toggle="tab"
          data-bs-target="#progenya${idx}" type="button" role="tab" aria-controls="progenya${idx}"
          aria-selected="false" tabindex="-1">
          Progeny
        </button>
      </li>
      <li class="nav-item ${!feedlotShow ? 'd-none' : ''}" role="presentation">
        <button class="nav-link" id="feedlota${idx}-tab" data-bs-toggle="tab"
          data-bs-target="#feedlota${idx}" type="button" role="tab" aria-controls="feedlota${idx}"
          aria-selected="false" tabindex="-1">
          Feedlot
        </button>
      </li>
      <li class="nav-item ${!copShow ? 'd-none' : ''}" role="presentation">
        <button class="nav-link" id="copa${idx}-tab" data-bs-toggle="tab"
          data-bs-target="#copa${idx}" type="button" role="tab" aria-controls="copa${idx}"
          aria-selected="false" tabindex="-1">
          COP
        </button>
      </li>
      <li class="nav-item ${!saleShow ? 'd-none' : ''}" role="presentation">
        <button class="nav-link" id="salea${idx}-tab" data-bs-toggle="tab"
          data-bs-target="#salea${idx}" type="button" role="tab" aria-controls="salea${idx}"
          aria-selected="false" tabindex="-1">
          Sale $
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="animal-imagea${idx}-tab" data-bs-toggle="tab"
          data-bs-target="#animal-imagea${idx}" type="button" role="tab" aria-controls="animal-imagea${idx}"
          aria-selected="false" tabindex="-1">
          Animal images
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="meat-imagea${idx}-tab" data-bs-toggle="tab"
          data-bs-target="#meat-imagea${idx}" type="button" role="tab" aria-controls="meat-imagea${idx}"
          aria-selected="false" tabindex="-1">
          Meat images
        </button>
      </li>
    </ul>
  `
}
