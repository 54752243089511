import axios from "axios";
import {initSelectpickerCustomization} from "../bs-select-custom";

$('.js-toggle-user-friendship-invite-details').on('click', function(e) {
    const button = $(this);
    const isLoaded = button.hasClass('isLoaded');
    const parentTableRow = button.parents('tr');
    const inviteId = button.attr('data-invite-id');

    if (isLoaded){
        $(`.js-friendship-invite-details-${inviteId}`).fadeToggle();
        button.find('i').toggleClass('opened');
    } else {
        axios.get(`/get-friendship-invitation-details?inviteId=${inviteId}`)
            .then((response) => {
                $(response.data.html).insertAfter(parentTableRow);
                initSelectpickerCustomization(`.js-friendship-invite-details-${inviteId}`);

                $(`.js-friendship-invite-details-${inviteId}`).find('.selectpicker').selectpicker();

                button
                    .addClass('isLoaded')
                    .find('i').toggleClass('opened');
            })
            .catch((error) => {
                console.error(error);
                alert('Something went wrong. Please try again or come back later');
            })
    }
})
