$('#addCattleType').on('hide.bs.modal', function () {
    $('.js-select-cattle-type-gender').val('male');

})

$('.js-add-cattle-type').on('click', function(e) {
    e.preventDefault();

    let switchAges = $('#addCattleType .js-age-border-switch input').prop('checked')

    if ( switchAges && !confirm(`Do you want to merge all the ages within this age border? This action can't be undone`) ) {
        return
    }

    let validated = valid();
    if (validated === false) {
        return;
    }


    $('.js-form-add-cattle-type').trigger('submit');
});

function valid() {
    let isExistsCattleInACurrentProperty = false;
    let validated = true;
    $('[data-cattle]').each(function(index) {
        if ($('#setCattleTypeName').val() === $(this).attr('data-cattle')) {
            isExistsCattleInACurrentProperty = true;
        }
    });

    if($('#setCattleTypeName').val() === '') {
        $('.js-small-text:first').html('Enter a name');
        $('.js-small-text:first').removeClass('d-none');
        $('#setCattleTypeName').addClass('is-invalid');
        validated = false;
    } else if ($('#setCattleTypeName').val().length > 20) {
        $('.js-small-text:first').html('This field must not be greater than 20 characters');
        $('.js-small-text:first').removeClass('d-none');
        $('#setCattleTypeName').addClass('is-invalid');
        validated = false;
    } else if (isExistsCattleInACurrentProperty === true) {
        $('.js-small-text:first').html('Cattle type already exists');
        $('.js-small-text:first').removeClass('d-none');
        $('#setCattleTypeName').addClass('is-invalid');
        validated = false;
    } else {
        $('.js-small-text:first').html('');
        $('.js-small-text:first').addClass('d-none');
        $('#setCattleTypeName').removeClass('is-invalid');
    }

    const yearOfBirth = $('#setCattleTypeAge').val();
    const minYearOfBirth = (new Date().getFullYear()) - 50;
    if(minYearOfBirth > yearOfBirth) {
        $('.js-small-text:eq(1)').html(`Please enter the full year not earlier than ${minYearOfBirth}, only 2 last digits will be displayed`);
        $('.js-small-text:eq(1)').removeClass('d-none');
        $('#setCattleTypeAge').addClass('is-invalid');
        validated = false;
    } else if((new Date().getFullYear()) < yearOfBirth) {
        $('.js-small-text:eq(1)').html(`Please enter the full year not later than ${new Date().getFullYear()}, only 2 last digits will be displayed`);
        $('.js-small-text:eq(1)').removeClass('d-none');
        $('#setCattleTypeAge').addClass('is-invalid');
        validated = false;
    } else {
        $('.js-small-text:eq(1)').html('');
        $('.js-small-text:eq(1)').addClass('d-none');
        $('#setCattleTypeAge').removeClass('is-invalid');
    }

    return validated;
}
