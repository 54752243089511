import { setInvalid, clearValidation } from "./cattle-type/actions-cattle-type";
import axios from "axios";

$('.js-form-select').on('change', '.js-select-property', function(e) {
    e.preventDefault();
    let propertyId = $('.js-select-property').val();
    if (propertyId != 0) {
        let url = '/property/' + propertyId;
        window.location = url;
    }
    return false;
});

$('.property-name__edit-icon').on('click', function() {
    let propertyNameBlock = $('.property-name'),
        elPropertyNameInput = $('.property-name-input');

    elPropertyNameInput.attr('data-old-value', elPropertyNameInput.val());
    propertyNameBlock.addClass('d-none');
    elPropertyNameInput.attr('type', 'text')
        .trigger('focus');
    $('.property-name__check-icon').removeClass('d-none');

});

$('.property-name-input').on('blur', function() {
    let propertyNameBlock = $('.property-name');
    let elPropertyNameInput = $(this),
        newPropertyName = $(this).val(),
        propertyId = $('[data-property-id]').attr('data-property-id'),
        oldValue = $(this).attr('data-old-value');

    if (newPropertyName === '') {
        $('.property-name span').text(oldValue);
        elPropertyNameInput.val(oldValue);
    }
    else if (newPropertyName !== $(this).attr('data-property-id')) {
        $('.property-name span').text(newPropertyName);
        axios.post('/property/updateAjax', {
            "property_id": propertyId,
            "property_name": $(this).val()
        })
    }
    $(this).attr('type', 'hidden');
    $('.property-name__check-icon').addClass('d-none');

    propertyNameBlock.removeClass('d-none');
});

$('body').on('input', '#paddockSize', function(){
    this.value = this.value.replace(/[^0-9Aa-zA-Z]/gi, '');

    let paddockSize = this.value;
    $('input#paddockSize').val(paddockSize);

});

$('.js-mob-cattle-list').on('click', async function (e) {
    e.preventDefault();

   let mobId = $(this).data('id');

   $('#MOBCattleList #MOBTitle').text($(this).text());

    let response = await axios.get(`/get-mob-cattle-list-ajax/${mobId}`)

    $('#MOBCattleList .modal-body ul').empty();
    response.data.cattles.forEach(function (val, i) {
        $('#MOBCattleList .modal-body ul').append(`<li>VID: ${val.visual_id.split('/')[1]}, EID: ${val.animal_id.split('/')[1]}, Year of Birth: ${val.property_cattle_type.year_of_birth}</li>`)
    });
});
require('./property/mob-actions')


$('.js-edit-treatment').on('click', function() {

    let currentTreatmentName = $(this).closest('tr').find('td:first').text(),
        formAction = $('#editTreatment form')
            .attr('action')
            .replace(/(\/)?[0-9]+$/, '') + '/' + $(this).data('id');

    $('#editTreatment form').attr('action', formAction);
    $('#editTreatmentName').val(currentTreatmentName.trim());
    $('#editBatchNumber').val($(this).data('batch'));


    $('#editTreatment').modal();
    new bootstrap.Modal('#editTreatment').show();
});

$(document).on('click', '.js-edit-treatment-from-modal', async function(e) {
    e.preventDefault;
    let target = e.target;

    $('#addTreatment, #editTreatment').modal('hide');

    let currentTreatmentName = $(target).data('name'),
        formAction = $('#editTreatment form')
            .attr('action')
            .replace(/(\/)?[0-9]+$/, '') + '/' + $(target).data('id');

    $('#editTreatment form').attr('action', formAction);
    $('#editTreatmentName').val(currentTreatmentName);
    $('#editBatchNumber').val($(target).text().trim());

    setTimeout( function() {
        $('#editTreatment').modal();
        new bootstrap.Modal('#editTreatment').show();

        $('#info-tab, #infoe-tab').tab('show');
    }, 300);
});

$('.js-edit-paddock-group').on('click', function() {

    let currentPaddockGroupName = $(this).closest('tr').find('td:first').text();
    let groupId = $(this).data('id')
    let propertyId = $(this).data('property-id')

    let formAction = $('#editPaddockGroup form').attr('action').replace(/(\/)?[0-9]+$/, '') + '/' + groupId;
    const paddocksSelector = $('#editPaddockSelector')

    axios.get(`/get-free-paddocks?property_id=${propertyId}&groupId=${groupId}`)
        .then(response => {

            let paddocks = ''
            $(paddocksSelector).selectpicker();
            $(paddocksSelector).selectpicker('render')
            response.data.paddocks.forEach(function (value, index) {

                let isSelected = value.group_id === groupId ? 'selected' : ''

                $(paddocksSelector).append(`<option value="${value.id}" ${isSelected}>${value.name}</option>`)

            })


            $(paddocksSelector).trigger('change')
            $(paddocksSelector).selectpicker('refresh')

            $('#editPaddockGroup form').attr('action', formAction);
            $('#editGroupName').val(currentPaddockGroupName.trim());


            $('#editPaddockGroup').modal();
            new bootstrap.Modal('#editPaddockGroup').show();
        })



});

$('#editPaddockGroup').on('hide.bs.modal', function (e) {
    refreshMultipleSelector($("#editPaddockSelector"))
})

export function refreshMultipleSelector(selector)
{
    $(selector).selectpicker()
        .selectpicker('deselectAll')
        .empty()
        .selectpicker('destroy');
}

$('.js-edit-transport').on('click', function() {

    let currentTransportName = $(this).closest('tr').find('td:first').text(),
        formAction = $('#editTransport form')
            .attr('action')
            .replace(/(\/)?[0-9]+$/, '') + '/' + $(this).data('id');

    $('#editTransport form').attr('action', formAction);
    $('#editTransportName').val(currentTransportName.trim());


    $('#editTransport').modal();
    new bootstrap.Modal('#editTransport').show();
});

$('.js-edit-note').on('click', function() {

    let currentNoteName = $(this).closest('tr').find('td:first').text(),
        formAction = $('#editNote form')
            .attr('action')
            .replace(/(\/)?[0-9]+$/, '') + '/' + $(this).data('id');

    $('#editNote form').attr('action', formAction);
    $('#editNoteName').val(currentNoteName.trim());


    $('#editNote').modal();
    new bootstrap.Modal('#editNote').show();
});

$('.js-edit-sold-note').on('click', function() {

    let currentNoteName = $(this).closest('tr').find('td:first').text(),
        formAction = $('#editSoldNote form')
            .attr('action')
            .replace(/(\/)?[0-9]+$/, '') + '/' + $(this).data('id');

    $('#editSoldNote form').attr('action', formAction);
    $('#editSoldNoteName').val(currentNoteName.trim());


    $('#editSoldNote').modal();
    new bootstrap.Modal('#editSoldNote').show();
});

$('.js-edit-agent').on('click', function() {

    let currentAgentName = $(this).closest('tr').find('td:first').text(),
        formAction = $('#editAgent form')
            .attr('action')
            .replace(/(\/)?[0-9]+$/, '') + '/' + $(this).data('id');

    $('#editAgent form').attr('action', formAction);
    $('#editAgentName').val(currentAgentName.trim());


    $('#editAgent').modal();
    new bootstrap.Modal('#editAgent').show();
});

$('.js-edit-paddock').on('click', function() {

    let currentPaddockName = $(this).closest('tr').find('input[name="name"]').val(),
        currentLotNumber = $(this).closest('tr').find('input[name="lot_number"]').val(),
        currentPaddockNote = $(this).closest('tr').find('input[name="note"]').val(),
        currentPaddockSize = $(this).closest('tr').find('input[name="size"]').val(),
        formAction = $('#editPaddock form')
            .attr('action')
            .replace(/(\/)?[0-9]+$/, '') + '/' + $(this).data('id')

    $('#editPaddock form').attr('action', formAction);
    $('#editPaddockName').val(currentPaddockName);
    $('#editLotNumber').val(currentLotNumber);
    $('#editPaddockNote').val(currentPaddockNote);
    $('#editPaddockSize').val(currentPaddockSize);


    $('#editPaddock').modal();
    new bootstrap.Modal('#editPaddock').show();
});

$('.js-create-property').parents('form').on('submit', function(e) {
    e.preventDefault();

    const form = $(this);
    const nameInput = form.find('input[name="name"]');

    if (nameInput.length < 1) {
        e.target.submit();
        return;
    }

    if (nameInput.val().length > 20) {
        setInvalid(nameInput, 'This field must not be greater than 20 characters');
        return;
    }

    e.target.submit();
})

$('.js-add-treatment, .js-save-treatment').parents('form').on('submit', function(e) {
    e.preventDefault();

    const form = $(this);
    const nameInput = form.find('input[name="treatment_name"]');

    if (nameInput.length < 1) {
        e.target.submit();
        return;
    }

    if (nameInput.val().length > 16) {
        setInvalid(nameInput, 'This field must not be greater than 16 characters');
        return;
    }

    e.target.submit();
})

$('.js-add-paddock-btn').parents('form').on('submit', function(e) {
    e.preventDefault();

    const form = $(this);
    const inputs = {};

    inputs.nameInput = form.find('input[name="paddock_name"]');
    inputs.noteInput = form.find('input[name="paddock_note"]');
    inputs.sizeInput = form.find('input[name="paddock_size"]');

    let isFormValid = true;

    Object.values(inputs).forEach((input) => {
        clearValidation(input.parent());
    })

    if (inputs.nameInput?.val().length > 20) {
        setInvalid(inputs.nameInput, 'This field must not be greater than 20 characters');
        isFormValid = false;
    }

    if (inputs.noteInput?.val().length > 50) {
        setInvalid(inputs.noteInput, 'This field must not be greater than 50 characters');
        isFormValid = false;
    }

    if (inputs.sizeInput?.val().length > 20) {
        setInvalid(inputs.sizeInput, 'This field must not be greater than 20 characters');
        isFormValid = false;
    }

    if (isFormValid) {
        e.target.submit();
    }
});

const groupPropertyColumn = document.querySelectorAll('.group-table-th');
groupPropertyColumn.forEach((item) => {
    item.addEventListener('click', function () {
        let $parentTable = $(this).closest('.group-table');
        if ($parentTable.hasClass('grouped')) {
            $parentTable.find('tbody').removeAttr('hidden');
            $parentTable.removeClass('grouped');
            $(this).find('.expand-arrows').css('display', 'none');
            $(this).find('.collapse-arrows').css('display', '');
        } else {
            $parentTable.find('tbody').attr('hidden', '');
            $parentTable.addClass('grouped');
            $(this).find('.expand-arrows').css('display', '');
            $(this).find('.collapse-arrows').css('display', 'none');
        }
    });
});

const groupPropertyColumnCattleReturn = document.querySelectorAll('.group-table-th-cattle-return');
groupPropertyColumnCattleReturn.forEach((item) => {
    item.addEventListener('click', function () {
        let $parentTable = $(this).closest('.group-table');
        if ($parentTable.hasClass('grouped')) {
            $parentTable.find('thead').removeAttr('hidden');
            $parentTable.removeClass('grouped');
            $(this).find('.expand-arrows').css('display', 'none');
            $(this).find('.collapse-arrows').css('display', '');
        } else {
            $parentTable.find('thead').attr('hidden', '');
            $parentTable.addClass('grouped');
            $(this).find('.expand-arrows').css('display', '');
            $(this).find('.collapse-arrows').css('display', 'none');
        }
    });
});

$('.js-form-add-cattle-type .js-select-cattle-type-gender, .js-form-edit-cattle-type select[name="cattle_type_gender"]').on('change', function(e) {
    if( $(this).val() === 'Mixed' )
    {
        $(this).closest('form').find('select[name="splitType"]').closest('div').removeClass('d-none')
    } else {
        $(this).closest('form').find('select[name="splitType"]').val('0')
        $(this).closest('form').find('select[name="splitType"]').closest('div').addClass('d-none')
    }
})

$('.js-hide-empty-paddocks').on('change', function(e) {
    const propertyId = $(this).data('id')
    const switchState = this.checked

    axios.get(`/switch-display-empty-paddocks?property_id=${propertyId}&state=${switchState}`)

    if ( switchState ) {
        $('#ledger-table tbody tr[data-empty="1"]').addClass('d-none')
    } else {
        $('#ledger-table tbody tr[data-empty="1"]').removeClass('d-none')
    }

    $('#ledger-table').data('hide-empty-paddocks', switchState)
})

$('input[name="propertyDisplayMode"]').on('change', function(e) {
    const modeValue = this.value


    if ( modeValue === 'custom' ) {
        $('.js-custom-mode-form').removeClass('d-none')
    } else {
        $('.js-custom-mode-form')
            .addClass('d-none')
            .find('input')
            .prop('checked', false)
    }

})

require('./property/custom-display-mode-handle')

$('.js-show-extra-animals').on('change', function(e) {
    const propertyId = $(this).data('id')
    const switchState = this.checked

    axios.get(`/switch-extra-animals-in-table?property_id=${propertyId}&state=${switchState}`)

    if ( switchState ) {
        $('.js-ledger-table [data-extra]').each(function (i) {
            $(this).removeClass('extra-hidden')

            if (
                ($(this).hasClass('d-none') && !$(this).data('group-id') && !$(this).hasClass('age-hidden'))
                || ($(this).hasClass('d-none') && $(this).hasClass('age-hidden') && !$(this).prev('td').hasClass('d-none'))
            ) {
                $(this).removeClass('d-none')
            }

            if ( $(this).data('extra-column') ) {
                const cattleTypeId = $(this).data('current-cattle-type-id')
                const currentCattleTypeColspan = $('.js-ledger-table').find('thead tr:first-child th[data-current-cattle-type-id="' + cattleTypeId + '"]:last').attr('colspan')

                $('.js-ledger-table').find('thead tr:first-child th[data-current-cattle-type-id="' + cattleTypeId + '"]:last').attr('colspan', parseInt(currentCattleTypeColspan) + 1)
                $('.js-ledger-table').find('tfoot tr:first-child td[data-current-cattle-type-id="' + cattleTypeId + '"]:last').attr('colspan', parseInt(currentCattleTypeColspan) + 1)
            }
        })
    } else {
        $('.js-ledger-table [data-extra]').each(function (i) {
            $(this).addClass('d-none').addClass('extra-hidden')

            if ( $(this).data('extra-column') ) {
                const cattleTypeId = $(this).data('current-cattle-type-id')
                const currentCattleTypeColspan = $('.js-ledger-table').find('thead tr:first-child th[data-current-cattle-type-id="' + cattleTypeId + '"]:last').attr('colspan')

                $('.js-ledger-table').find('thead tr:first-child th[data-current-cattle-type-id="' + cattleTypeId + '"]:last').attr('colspan', parseInt(currentCattleTypeColspan) - 1)
                $('.js-ledger-table').find('tfoot tr:first-child td[data-current-cattle-type-id="' + cattleTypeId + '"]:last').attr('colspan', parseInt(currentCattleTypeColspan) - 1)
            }
        })


    }

})

$('input[name="hide_years_of_birth"]').on('change', function (e) {
    const checked = this.checked
    const form = $(this).closest('form')
    const minYearOfBirthSelector = $(form).find('input[name="cattle_type_min_year_of_birth"]')
    const ageBorderSelector = $(form).find('input[name="cattle_type_age_border"]')
    const ageBorderNameSelector = $(form).find('input[name="cattle_type_age_border_name"]')

    $(minYearOfBirthSelector).closest('div').toggleClass('d-none')
    $(ageBorderSelector).closest('div').toggleClass('d-none')
    $(ageBorderNameSelector).closest('div').toggleClass('d-none')

    $(minYearOfBirthSelector).val(checked ? new Date().getFullYear() : '')
    $(ageBorderSelector).val('').trigger('change')
    $(ageBorderNameSelector).val('')
})
