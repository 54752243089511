$('.js-edit-cattle-type').on('click', function() {

    let currentCattleTypeEl = $(this).closest('tr').find('td[data-cattle]'),
        currentCattleTypeVal = currentCattleTypeEl.attr('data-cattle'),
        currentCattleTypeId = $(this).closest('tr').find('input[name="cattle_type_id"]').val(),
        currentCattleTypeMinYearOfBirth = $(this).closest('tr').find('input[name="cattle_type_min_year_of_birth"]').val(),
        currentCattleTypeAgeBorder = $(this).closest('tr').find('input[name="cattle_type_age_border"]').val() ?? '',
        currentCattleTypeAgeBorderName = $(this).closest('tr').find('input[name="cattle_type_age_border_name"]').val() ?? '',
        currentCattleTypeIncreaseAgeBorder = $(this).closest('tr').find('input[name="cattle_type_increase_age_border"]').val() ?? '',
        currentCattleTypeHideBorderAges = $(this).closest('tr').find('input[name="cattle_type_hide_age_border"]').val() ?? '',
        currentCattleTypeHideYearsOfBirth = $(this).closest('tr').find('input[name="cattle_type_hide_years_of_birth"]').val() ?? '',
        currentCattleTypeGender = $(this).closest('tr').find('input[name="cattle_type_gender"]').val(),
        currentCattleTypeCattleReturnAffect = $(this).closest('tr').find('input[name="cattle_return_affect"]').val(),
        currentCattleTypeCattleReturnAffectReclass = $(this).closest('tr').find('input[name="cattle_return_affect_reclass"]').val(),
        currentCattleTypeSplitType = $(this).closest('tr').find('input[name="is_split_type"]').val(),
        formAction = $('.js-form-edit-cattle-type')
            .attr('action')
            .replace(/(\/)?[0-9]+$/, '') + '/' + currentCattleTypeId;


    $('.js-form-edit-cattle-type').attr('action', formAction);
    $('#editCattleTypeName').val(currentCattleTypeVal);
    $('#currentCattleTypeName').val(currentCattleTypeVal);
    $('#editCattleTypeGender').val(currentCattleTypeGender);
    $('input[name="cattle_type_min_year_of_birth_current"]').val(currentCattleTypeMinYearOfBirth);
    $('.js-form-edit-cattle-type input[name="cattle_type_age_border"]').val(currentCattleTypeAgeBorder);
    $('.js-form-edit-cattle-type input[name="cattle_type_age_border_name"]').val(currentCattleTypeAgeBorderName);



    if ( currentCattleTypeHideBorderAges == 0 ) {
        $('.js-form-edit-cattle-type input[name="hide_border_ages"]').prop('checked', false)
    } else {
        $('.js-form-edit-cattle-type input[name="hide_border_ages"]').prop('checked', true)
    }

    if ( currentCattleTypeIncreaseAgeBorder == 1 ) {
        $('.js-form-edit-cattle-type input[name="increase_age_border"]').prop('checked', true)
    } else {
        $('.js-form-edit-cattle-type input[name="increase_age_border"]').prop('checked', false)
    }

    const switchHideBorderAgesBlock = $('.js-form-edit-cattle-type input[name="hide_border_ages"]').closest('div')
    const switchIncreaseAgeBorderBlock = $('.js-form-edit-cattle-type input[name="increase_age_border"]').closest('div')

    if ( currentCattleTypeAgeBorder !== '' ) {

        if ( currentCattleTypeHideBorderAges == 0) {
            switchHideBorderAgesBlock.removeClass('d-none')
        } else {
            switchHideBorderAgesBlock.addClass('d-none')
        }

        switchIncreaseAgeBorderBlock.removeClass('d-none')
    } else {
        switchHideBorderAgesBlock.addClass('d-none')
        switchIncreaseAgeBorderBlock.addClass('d-none')
    }


    $('.js-form-edit-cattle-type select[name="cattle_return_affect"]').val(currentCattleTypeCattleReturnAffect).trigger('change');
    $('.js-form-edit-cattle-type select[name="cattle_return_affect_reclass"]').val(currentCattleTypeCattleReturnAffectReclass);

    $('.js-form-edit-cattle-type select[name="splitType"]').val(currentCattleTypeSplitType);
    if ( currentCattleTypeGender === 'Mixed' ) {
        $('.js-form-edit-cattle-type select[name="splitType"]').closest('div').removeClass('d-none');
    } else {
        $('.js-form-edit-cattle-type select[name="splitType"]').closest('div').addClass('d-none');
    }


    $('#editCattleTypeMaxAge').val(currentCattleTypeMinYearOfBirth);

    if ( currentCattleTypeHideYearsOfBirth == 1 ) {
        $('.js-form-edit-cattle-type').find('input[name="hide_years_of_birth"]').prop('checked', true).trigger('change')
        $('.js-form-edit-cattle-type').find('input[name="hide_years_of_birth"]').attr('disabled', 'disabled')
    }

    $('#editCattleType').modal();
    new bootstrap.Modal('#editCattleType').show();
});

$('#editCattleType').on('hide.bs.modal', function (e) {
    if ( $('.js-form-edit-cattle-type').find('input[name="hide_years_of_birth"]').prop('checked') ) {
        $('.js-form-edit-cattle-type').find('input[name="hide_years_of_birth"]').removeAttr('disabled')
        $('.js-form-edit-cattle-type').find('input[name="hide_years_of_birth"]').prop('checked', false).trigger('change')
    }
})


$('.js-update-cattle-type').on('click', function(e) {
    e.preventDefault();

    if ( $('.js-form-edit-cattle-type .js-age-border-switch').length !== 0 ) {
        let switchAges = $('.js-form-edit-cattle-type .js-age-border-switch input').prop('checked')

        if ( switchAges && !confirm(`Ages within this age border will merge. You will not be able to undo this, are you sure?`) ) {
            return
        }
    }

    let validated = valid();
    if (validated === false) {
        return;
    }
    $('.js-form-edit-cattle-type').trigger('submit');
});

function valid() {
    let isExistsCattleInACurrentProperty = false;
    let validated = true;

    $('[data-cattle]').each(function(index) {
        if ($('#editCattleTypeName').val() !== $('#currentCattleTypeName').val()
                && $('#editCattleTypeName').val() === $(this).attr('data-cattle')) {
            isExistsCattleInACurrentProperty = true;
        }
    });

    if($('#editCattleTypeName').val() === '') {
        $('.js-small-text:eq(2)').html('Enter a name');
        $('.js-small-text:eq(2)').removeClass('d-none');
        $('#editCattleTypeName').addClass('is-invalid');
        validated = false;
    } else if ($('#editCattleTypeName').val().length > 20) {
        $('.js-small-text:eq(2)').html('This field must not be greater than 20 characters');
        $('.js-small-text:eq(2)').removeClass('d-none');
        $('#editCattleTypeName').addClass('is-invalid');
        validated = false;
    } else if (isExistsCattleInACurrentProperty === true) {
        $('.js-small-text:eq(2)').html('Cattle type already exists');
        $('.js-small-text:eq(2)').removeClass('d-none');
        $('#editCattleTypeName').addClass('is-invalid');
        validated = false;
    } else {
        $('.js-small-text:eq(2)').html('');
        $('.js-small-text:eq(2)').addClass('d-none');
        $('#editCattleTypeName').removeClass('is-invalid');
    }

    const yearOfBirth = $('#editCattleTypeMaxAge').val();
    const minYearOfBirth = (new Date().getFullYear()) - 50;
    if(minYearOfBirth > yearOfBirth) {
        $('.js-small-text:last').html(`Please enter the full year not earlier than ${minYearOfBirth}, only 2 last digits will be displayed`);
        $('.js-small-text:last').removeClass('d-none');
        $('#editCattleTypeMaxAge').addClass('is-invalid');
        validated = false;
    } else if((new Date().getFullYear()) < yearOfBirth) {
        $('.js-small-text:last').html(`Please enter the full year not later than ${new Date().getFullYear()}, only 2 last digits will be displayed`);
        $('.js-small-text:last').removeClass('d-none');
        $('#editCattleTypeMaxAge').addClass('is-invalid');
        validated = false;
    } else {
        $('.js-small-text:last').html('');
        $('.js-small-text:last').addClass('d-none');
        $('#editCattleTypeMaxAge').removeClass('is-invalid');
    }

    return validated;
}
