
require('./transactions/add-new-transaction-row')
require('./transactions/add-existing-transaction-row')
require('./transactions/remove-new-transaction-row')
require('./transactions/init-transaction-data')
require('./transactions/init-transaction-rows')
require('./transactions/fill-transaction-data')

require('./transactions/transaction-animals-handle')
require('./transactions/delete-transaction')
require('./transactions/save-transactions')
require('./transactions/mob-save-from-modal')


require('./transactions/change-action-handle')
require('./transactions/change-cattle-type-handle')
require('./transactions/change-target-cattle-type-handle')
require('./transactions/change-female-status-handle')
require('./transactions/change-year-of-birth-handle')
require('./transactions/change-purchase-note-handle')
require('./transactions/change-transfer-in-property-handle')
require('./transactions/change-quantity-handle')
