import {initialAutocomplete} from '../cattle/autocomplete';
import axios from "axios";
import bytesToSize from '../../helpers/bytesToSize';

updateModalMode(false, 'view-edit-cattle');

/*=============================================
=            Edit mode processing             =
=============================================*/


let editAnimalPopupDataTransfer = new DataTransfer();
let editAnimalPopupDataAnimalTransfer = new DataTransfer();
let editAnimalPopupDataMeatTransfer = new DataTransfer();

initEditAnimalPopupUploadImages('animal', 10);
initEditAnimalPopupUploadImages('meat', 3);

$(document.body).on('click', '.js-form-edit-cattle .uploaded-image-actions__delete', function () {
    let $btnParent = $(this).closest('.uploaded-image');
    const maxCountImages = $(this).closest('#animal-uploaded-images').length !== 0 ? 10 : 3
    const imageType = $(this).closest('#animal-uploaded-images').length !== 0 ? 'animal' : 'meat'
    let parentBlock = $(this).closest(`#${imageType}-uploaded-images`)
    let fileName = $btnParent.closest('.uploaded-image').find('.uploaded-image-description__title h6').text()


    $btnParent.remove();
    removeEditAnimalPopupFileFromFileList(fileName, imageType);

    if ( $(parentBlock).closest('.tab-pane').find('.uploaded-image').length < maxCountImages ) {
        $(parentBlock).closest('.tab-pane').find(`#${imageType}-image-upload-label, .image-upload`).show()
    }

    if ( $(parentBlock).closest('.tab-pane').find('.uploaded-image').length < maxCountImages ) {
        $(parentBlock).closest('.tab-pane').find(`#${imageType}-image-upload-label`).css('display', '');
        $(parentBlock).closest('.tab-pane').find(`.image-upload`).removeAttr('disabled');
    }
});

function removeEditAnimalPopupFileFromFileList(name, type) {

    editAnimalPopupDataTransfer = new DataTransfer();
    let editAnimalPopupInput = document.getElementById(`${type}-image-upload`);
    let editAnimalPopupCurrentInput = document.getElementById(`${type}-current-image-upload`);
    let {files} = editAnimalPopupInput;

    let isDeleted = false
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if ( name !== file.name || isDeleted ) {
        editAnimalPopupDataTransfer.items.add(file);
      } else {
          isDeleted = true;
      }
    }

    editAnimalPopupInput.files = editAnimalPopupDataTransfer.files;
    editAnimalPopupCurrentInput.files = editAnimalPopupDataTransfer.files;

}

$('.js-edit-cattle').on('click', async function() {

    $('#view-edit-cattle ul:first li:first button').trigger('click')
    resetEditModeButtonVisibility();

    let visualId = $(this).closest('tr').find('[data-visual-id]').attr('data-visual-id'),
        animalId = $(this).closest('tr').find('[data-animal-id]').attr('data-animal-id'),
        cattleTypeId = $(this).closest('tr').find('[data-cattle-type-id]').attr('data-cattle-type-id'),
        weight = $(this).closest('tr').find('[data-weight]').attr('data-weight'),
        plant_name = $(this).closest('tr').find('[data-plant_name]').attr('data-plant_name'),
        plant_location = $(this).closest('tr').find('[data-plant_location]').attr('data-plant_location'),
        mom = $(this).closest('tr').find('[data-mom]').attr('data-mom'),
        dad = $(this).closest('tr').find('[data-dad]').attr('data-dad'),

        entry_weight = $(this).closest('tr').find('[data-entry_weight]').attr('data-entry_weight'),
        exit_weight = $(this).closest('tr').find('[data-exit_weight]').attr('data-exit_weight'),
        feed = $(this).closest('tr').find('[data-feed_days]').attr('data-feed_days'),
        barcode = $(this).closest('tr').find('[data-barcode]').attr('data-barcode'),
        time_taken = $(this).closest('tr').find('[data-time_taken]').attr('data-time_taken'),
        body = $(this).closest('tr').find('[data-body]').attr('data-body'),
        hscw = $(this).closest('tr').find('[data-hscw]').attr('data-hscw'),
        marbling_percent = $(this).closest('tr').find('[data-marbling_percent]').attr('data-marbling_percent'),
        mb_marble = $(this).closest('tr').find('[data-mb_marble]').attr('data-mb_marble'),
        ausmeat_marble = $(this).closest('tr').find('[data-ausmeat_marble]').attr('data-ausmeat_marble'),
        msa_marble = $(this).closest('tr').find('[data-msa_marble]').attr('data-msa_marble'),
        fineness = $(this).closest('tr').find('[data-fineness]').attr('data-fineness'),
        distribution = $(this).closest('tr').find('[data-distribution]').attr('data-distribution'),
        meat_colour = $(this).closest('tr').find('[data-meat_colour]').attr('data-meat_colour'),
        fat_colour = $(this).closest('tr').find('[data-fat_colour]').attr('data-fat_colour'),
        eye_muscle_area = $(this).closest('tr').find('[data-eye_muscle_area]').attr('data-eye_muscle_area'),
        marbling_count = $(this).closest('tr').find('[data-marbling_count]').attr('data-marbling_count'),
        cut_location = $(this).closest('tr').find('[data-cut_location]').attr('data-cut_location'),
        pre_feedlot = $(this).closest('tr').find('[data-pre_feedlot]').attr('data-pre_feedlot'),
        feedlot = $(this).closest('tr').find('[data-feedlot]').attr('data-feedlot'),
        kill = $(this).closest('tr').find('[data-kill]').attr('data-kill'),
        sales = $(this).closest('tr').find('[data-sales]').attr('data-sales'),
        marketing = $(this).closest('tr').find('[data-marketing]').attr('data-marketing'),
        lwt = $(this).closest('tr').find('[data-lwt]').attr('data-lwt'),
        hscw2 = $(this).closest('tr').find('[data-hscw2]').attr('data-hscw2'),
        cattleId = $(this).closest('tr').find('[name="cattleId"]').val(),
        userId = visualId.split('/')[0];

    visualId = visualId.replace(userId + '/', '');
    animalId = animalId.replace(userId + '/', '');

    $('.js-user-id-part').text(userId + '/');

    $('#editVisualId').val(visualId);
    $('#editAnimalId').val(animalId);
    $('#editCattleType').val(cattleTypeId);
    $('#editWeight').val(weight);
    $('#editPlantName').val(plant_name);
    $('#editPlantLocation').val(plant_location);

    await putParentsAndChildren(cattleId);

    $('#editEntryWeight').val(entry_weight);
    $('#editExitWeight').val(exit_weight);
    $('#editFeed').val(feed);

    $('#editBarcode').val(barcode);
    $('#editTimetaken').val(time_taken);
    $('#editBody').val(body);
    $('#editHSCW').val(hscw);
    $('#editMarblingpercent').val(marbling_percent);
    $('#editMbmarble').val(mb_marble);
    $('#editAusmeatmarble').val(ausmeat_marble);
    $('#editMsamarble').val(msa_marble);
    $('#editFineness').val(fineness);
    $('#editDistribution').val(distribution);
    $('#editMeatcolour').val(meat_colour);
    $('#editFatcolour').val(fat_colour);
    $('#editEyemusclearea').val(eye_muscle_area);
    $('#editMarblingcount').val(marbling_count);
    $('#editCutlocation').val(cut_location);

    $('#editPreFeedlot').val(pre_feedlot);
    $('#editFeedlot').val(feedlot);
    $('#editKill').val(kill);
    $('#editSales').val(sales);
    $('#editMarketing').val(marketing);
    $('#editLWT').val(lwt);
    $('#editHSCW2').val(hscw2);

    await getAndPutAnimalsImages(cattleId)

    let isEditMode = $('#editModeCheckbox').is(':checked');

    updateModalMode(isEditMode, 'view-edit-cattle')

    $('#editModeCheckbox').on('change', function() {
        isEditMode = $('#editModeCheckbox').is(':checked');

        if(isEditMode) {
            $('#datetimepicker01').show();

        } else {
            $('#datetimepicker01').hide();
        }

        updateModalMode(isEditMode, 'view-edit-cattle');
    })

    let formAttrAction = $('.js-form-edit-cattle').attr('action'),
        formRegEx = /\/(\d+)$/g,
        endOfAction = '/' + cattleId;

    let url = formRegEx.test(formAttrAction)
        ? formAttrAction.replace(formRegEx, endOfAction)
        : formAttrAction + endOfAction;

    $('.js-form-edit-cattle').attr('action', url)
});

async function getAndPutAnimalsImages(cattleId) {
    $('#view-edit-cattle #animal-uploaded-images').empty()
    $('#view-edit-cattle #meat-uploaded-images').empty()

    let animalsImages = new DataTransfer();
    let meatImages = new DataTransfer();

    await axios.get(`/get-animal-images?cattle_id=${cattleId}`)
        .then(async response => {
            response.data.images.forEach((element, key) => {

                // Create a new File object
                const image = new File([`${key}`], `${element.url.split('/')[2]}`);
                let imagesContainer = ''

                if ( element.type === 'animal') {
                    animalsImages.items.add(image);
                    imagesContainer = $('#view-edit-cattle .animals-uploaded-images')
                } else {
                    meatImages.items.add(image);
                    imagesContainer = $('#view-edit-cattle .meat-uploaded-images')
                }


                $(imagesContainer).append(`
                    <div class="uploaded-image" id="uploaded-image-${key}">
                        <div class="uploaded-image-info">
                            <a href="/storage${element.url}" target="_blank" rel="noopener noreferrer" class="uploaded-image-preview">
                                <div class="uploaded-image-preview--image" style="background-image: url(/storage${element.url});"></div>
                            </a>
                            <div class="uploaded-image-description">
                                <a href="/storage${element.url}" target="_blank" rel="noopener noreferrer" class="uploaded-image-description__title">
                                    <h6 style="margin: 0;">${element.url.split('/')[2]}</h6>
                                </a>
                                <p class="uploaded-image-description__text">
                                    <span class="uploaded-image-size">${element.size} MB</span>
                                </p>
                            </div>
                        </div>
                        <div class="uploaded-image-actions">
                            <a href="/storage${element.url}" target="_blank" rel="noopener noreferrer" class="uploaded-image-actions__button uploaded-image-actions__view">
                                <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.00033 8.5C8.78921 8.5 9.45866 8.225 10.0087 7.675C10.5587 7.125 10.8337 6.45556 10.8337 5.66667C10.8337 4.87778 10.5587 4.20833 10.0087 3.65833C9.45866 3.10833 8.78921 2.83333 8.00033 2.83333C7.21144 2.83333 6.54199 3.10833 5.99199 3.65833C5.44199 4.20833 5.16699 4.87778 5.16699 5.66667C5.16699 6.45556 5.44199 7.125 5.99199 7.675C6.54199 8.225 7.21144 8.5 8.00033 8.5ZM8.00033 7.53333C7.4781 7.53333 7.03644 7.35278 6.67532 6.99167C6.31421 6.63056 6.13366 6.18889 6.13366 5.66667C6.13366 5.14445 6.31421 4.70278 6.67532 4.34167C7.03644 3.98056 7.4781 3.8 8.00033 3.8C8.52255 3.8 8.96421 3.98056 9.32533 4.34167C9.68644 4.70278 9.86699 5.14445 9.86699 5.66667C9.86699 6.18889 9.68644 6.63056 9.32533 6.99167C8.96421 7.35278 8.52255 7.53333 8.00033 7.53333ZM8.00033 10.6667C6.3781 10.6667 4.91144 10.2056 3.60033 9.28333C2.28921 8.36111 1.31144 7.15556 0.666992 5.66667C1.31144 4.17778 2.28921 2.97222 3.60033 2.05C4.91144 1.12778 6.3781 0.666668 8.00033 0.666668C9.62255 0.666668 11.0892 1.12778 12.4003 2.05C13.7114 2.97222 14.6892 4.17778 15.3337 5.66667C14.6892 7.15556 13.7114 8.36111 12.4003 9.28333C11.0892 10.2056 9.62255 10.6667 8.00033 10.6667Z" fill="#38C172" />
                                </svg>
                            </a>
                            <a href="/storage${element.url}" download class="uploaded-image-actions__button uploaded-image-actions__download">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.66699 11.3333C1.40033 11.3333 1.16699 11.2333 0.966992 11.0333C0.766992 10.8333 0.666992 10.6 0.666992 10.3333V7.95H1.66699V10.3333H10.3337V7.95H11.3337V10.3333C11.3337 10.6 11.2337 10.8333 11.0337 11.0333C10.8337 11.2333 10.6003 11.3333 10.3337 11.3333H1.66699ZM6.00033 8.78333L2.78366 5.56667L3.50033 4.85L5.50033 6.85V0.666668H6.50033V6.85L8.50033 4.85L9.21699 5.56667L6.00033 8.78333Z" fill="#3490DC" />
                                </svg>
                            </a>
                            <button type="button" class="uploaded-image-actions__button uploaded-image-actions__delete">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.01699 9.01667L6.00032 7L8.00032 9.01667L8.78366 8.21667L6.80032 6.2L8.78366 4.18333L8.00032 3.38333L6.00032 5.4L4.01699 3.38333L3.21699 4.18333L5.21699 6.2L3.21699 8.21667L4.01699 9.01667ZM2.35033 12C2.08366 12 1.85033 11.9 1.65033 11.7C1.45033 11.5 1.35033 11.2667 1.35033 11V1.5H0.666992V0.5H3.80032V0H8.20032V0.5H11.3337V1.5H10.6503V11C10.6503 11.2667 10.5503 11.5 10.3503 11.7C10.1503 11.9 9.91699 12 9.65032 12H2.35033Z" fill="#E3342F"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                `)
            })
        })


    $('#view-edit-cattle #animal-current-image-upload')[0].files = animalsImages.files
    $('#view-edit-cattle #animal-image-upload')[0].files = animalsImages.files

    $('#view-edit-cattle #meat-current-image-upload')[0].files = meatImages.files
    $('#view-edit-cattle #meat-image-upload')[0].files = meatImages.files

}

async function putParentsAndChildren(cattleId) {
    //BREEDING & PROGENY TAB

    $('#editDad').val('');
    $('#editMom').val('');

    $('#editProgeny').empty();
    await axios.get(`/get-parents-children?cattle_id=${cattleId}`)
        .then(async response => {
            // View mode processing

            if(response.data.dad !== null) {
                $('#showDad').append(`
                    <a class="js-info-cattle-from-modal"
                        data-id="${response.data.dad.id}"
                        href="#"
                    >
                        VID: ${response.data.dad.visual_id}, EID: ${response.data.dad.animal_id}
                    </a>
                `);
            }

            if(response.data.mom !== null) {
                $('#showMom').append(`
                    <a class="js-info-cattle-from-modal"
                        data-id="${response.data.mom.id}"
                        href="#"
                    >
                        VID: ${response.data.mom.visual_id}, EID: ${response.data.mom.animal_id}
                    </a>
                `)
            }

            if(response.data.children !== null) {
                response.data.children.forEach((element, key) => {
                    $('#showProgeny').append(`<div class="row mb-2">
                    <div class="col-md-2">
                        #${key + 1}
                    </div>
                    <div id="showVisualId" class="col-md-8">
                        <a class="js-info-cattle-from-modal" data-id="${element.id}" href="#">VID: ${element.visual_id}, EID: ${element.animal_id}</div></a>
                    </div>`)
                });
            }

            // Edit mode processing


            let cattleData = await axios.get(`/get-cattle-data?search=yes`);

            if(response.data.dad !== null) {
                $('#editDad').val(response.data.dad.visual_id);
            }


            if(response.data.mom !== null) {
                $('#editMom').val(response.data.mom.visual_id);
            }


            if(response.data.children !== null && response.data.children.length > 0) {

                response.data.children.forEach((element, key) => {
                    if(key === 0) {
                        $('#progeny .row .col-md-8 #editProgeny').append(`<input id="children-${Math.floor(Math.random() * 1000)}" class="form-control cattle-search w-auto" placeholder="" autocomplete="off" name="children[]" type="text" value="${element.visual_id}">
                    <span class="me-auto">
                        <i class="fas fa-minus h5 p-1 js-remove-progeny-edit" style="cursor: pointer"></i>
                        <i class="fas fa-plus h5 p-1 js-add-progeny-edit" style="cursor: pointer"></i>
                    </span>`);
                    initialAutocomplete('#progeny input:first', cattleData.data.cattle);
                    } else if(key === 1) {
                        $('#progeny .row .col-md-8 #editProgeny .me-auto').after(`<input id="children-${Math.floor(Math.random() * 1000)}" class="form-control cattle-search w-auto" placeholder="" autocomplete="off" name="children[]" type="text" value="${element.visual_id}">`);
                        initialAutocomplete('#progeny input:last', cattleData.data.cattle);
                    } else {
                        $('#progeny input:last').after(`<input id="children-${Math.floor(Math.random() * 1000)}" class="form-control cattle-search w-auto" placeholder="" autocomplete="off" name="children[]" type="text" value="${element.visual_id}">`);
                        initialAutocomplete('#progeny input:last', cattleData.data.cattle);
                    }

                });
            } else {
                $('#progeny .row .col-md-8 #editProgeny').append(`<input id="children-${Math.floor(Math.random() * 1000)}" class="form-control cattle-search w-auto" placeholder="" autocomplete="off" name="children[]" type="text">
                    <span class="me-auto">
                        <i class="fas fa-minus h5 p-1 js-remove-progeny-edit" style="cursor: pointer"></i>
                        <i class="fas fa-plus h5 p-1 js-add-progeny-edit" style="cursor: pointer"></i>
                    </span>`);
                initialAutocomplete('#progeny input:last', cattleData.data.cattle);
            }
        });
}

/**
 *
 * Progeny selects processing
 *
 */

$(document).on('click', '.js-add-progeny-edit', async function(e) {
    let target = e.target;

    let inputCount = $(target).closest('.tab-pane').find(`input`).length;
    let inputHtml = $(`#progeny input:first`).prop('outerHTML');

    if(inputCount === 10) {
        return;
    }

    if(inputCount === 1) {
        $(`#progeny .autoComplete_wrapper:first+span`).after(inputHtml);
        $(`#progeny input:last`).attr('id', 'children' + Math.floor(Math.random() * 1000))
    } else {
        $(`#progeny .autoComplete_wrapper:last`).after(inputHtml);
        $(`#progeny input:last`).attr('id', 'children-' + + Math.floor(Math.random() * 1000))
    }

    $(`#progeny input:last`).val('')

    let cattleData = await axios.get(`/get-cattle-data?search=yes`);
    initialAutocomplete(`#progeny input:last`, cattleData.data.cattle);
    $('#progeny input:last').removeClass('is-invalid')
});

$(document).on('click', '.js-remove-progeny-edit', function(e) {
    let target = e.target;

    let inputCount = $(target).closest('.tab-pane').find(`input`).length;

    if(inputCount === 1) {
        return;
    }

    $(`#progeny .autoComplete_wrapper:last`).remove();
});

/*=====  End of Edit mode processing  =======*/



/*=============================================
=            View mode processing             =
=============================================*/

$(document).on('click', '.js-info-cattle-from-modal, .js-edit-cattle', async function(e) {
    e.preventDefault;
    let target = e.target;
    let cattleId = null;
    let cattleData = null;

    editAnimalPopupDataTransfer.clearData();
    document.getElementById('animal-image-upload').files = editAnimalPopupDataTransfer.files;
    document.getElementById('meat-image-upload').files = editAnimalPopupDataTransfer.files;

    if($(target).hasClass('js-info-cattle-from-modal')) {
        $('#view-edit-cattle').modal('hide');
        cattleId = Number($(target).data('id'));

        setTimeout(async function() {
            cattleData = await axios.get(`/get-cattle-data?cattle_id=${cattleId}`);
            cattleData = cattleData.data.cattle;

            openModalInViewOnlyMode();

            fillingModalData(cattleData, cattleId, true);
          }, 1000);
    } else {
        cattleId = $(target).closest('tr').find('[name="cattleId"]').val()
        cattleData = getCattleDataFromThePage(cattleId);
        fillingModalData(cattleData, cattleId);
    }
});

function putValue(value, selector) {
    $(selector).text(value);
}

function fillingModalData(cattleData, cattleId, fromModal = false) {

    let visualId = cattleData.visual_id,
        animalId = cattleData.animal_id,
        cattleTypeId = cattleData.cattle_type_id,
        cattleTypeName = cattleData.cattle_type_name,
        weight = cattleData.weight,
        plant_name = cattleData.plant_name,
        plant_location = cattleData.plant_location,
        entry_weight = cattleData.entry_weight,
        exit_weight = cattleData.exit_weight,
        feed = cattleData.feed_days ?? cattleData.feed,
        barcode = cattleData.barcode,
        time_taken = cattleData.time_taken,
        body = cattleData.body,
        hscw = cattleData.hscw,
        marbling_percent = cattleData.marbling_percent,
        mb_marble = cattleData.mb_marble,
        ausmeat_marble = cattleData.ausmeat_marble,
        msa_marble = cattleData.msa_marble,
        fineness = cattleData.fineness,
        distribution = cattleData.distribution,
        meat_colour = cattleData.meat_colour,
        fat_colour = cattleData.fat_colour,
        eye_muscle_area = cattleData.eye_muscle_area,
        marbling_count = cattleData.marbling_count,
        cut_location = cattleData.cut_location,
        pre_feedlot = cattleData.pre_feedlot,
        feedlot = cattleData.feedlot,
        kill = cattleData.kill,
        sales = cattleData.sales,
        marketing = cattleData.marketing,
        lwt = cattleData.lwt,
        hscw2 = cattleData.hscw2;

    if(time_taken !== null) {
        time_taken = time_taken.replace('T', ' ');
    }


    $('#showVisualId').text(visualId.split('/')[1]);
    $('#showAnimalId').text(animalId.split('/')[1]);
    $('#showCattleType').text(cattleTypeName);

    //INFO TAB
    putValue(weight, '#showWeight');
    putValue(plant_name, '#showPlantName');
    putValue(plant_location, '#showPlantLocation');
    putValue(entry_weight, '#entry-weight');
    putValue(exit_weight, '#exit-weight');
    putValue(feed, '#feed_days');
    putValue(barcode, '#showBarcode');
    putValue(time_taken, '#showTimetaken')
    putValue(body, '#showBody')
    putValue(hscw, '#showHSCW')
    putValue(marbling_percent, '#showMarblingpercent')
    putValue(mb_marble, '#showMbmarble')
    putValue(ausmeat_marble, '#showAusmeatmarble')
    putValue(msa_marble, '#showMsamarble')
    putValue(fineness, '#showFineness')
    putValue(distribution, '#showDistribution')
    putValue(meat_colour, '#showMeatcolour')
    putValue(fat_colour, '#showFatcolour')
    putValue(eye_muscle_area, '#showEyemusclearea')
    putValue(marbling_count, '#showMarblingcount')
    putValue(cut_location, '#showCutlocation')
    putValue(pre_feedlot, '#showPreFeedlot')
    putValue(feedlot, '#showFeedlot')
    putValue(kill, '#showKill')
    putValue(sales, '#showSales')
    putValue(marketing, '#showMarketing')
    putValue(lwt, '#showLWT')
    putValue(hscw2, '#showHSCW2')

    //WEIGHT HISTORY TAB
    $('#weight-history .container').empty();
    axios.get(`/get-weight-history?cattle_id=${cattleId}&is_full=false`)
            .then(response => {
                response.data.weight_history.every((element, index) => {

                    if(index === 10) {
                        $('#weight-history .container').append(`<a target="_blank" href="/get-weight-history?cattle_id=${response.data.cattle_id}&page=1"><button class="btn btn-primary text-light pe-none">Show full history</button></a>`);
                        return false;
                    }

                    let date = new Date(element.created_at).toUTCString();
                    if(element['new_weight'] === null) {
                        $('#weight-history .container').append(`
                            <div class="row mb-2">
                                <div class="col">
                                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                                        <div class="col-md-4">
                                            ${element.old_weight}
                                        </div>
                                        <div class="col-md-8">
                                            <div id="showVisualId" class="cattle-database__show-data-container">
                                                ${date}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        `)
                    } else {
                        $('#weight-history .container').append(`
                            <div class="row mb-2">
                                <div class="col">
                                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                                        <div class="col-md-4">
                                            ${element.new_weight}
                                        </div>
                                        <div class="col-md-8">
                                            <div id="showVisualId" class="cattle-database__show-data-container">
                                                ${date}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        `)
                    }
                    return true;
                });
            });

    //BREEDING & PROGENY TAB
    $('#showDad, #showMom').empty();
    $('#showProgeny').empty();

    if(fromModal) {
        putParentsAndChildren(cattleId);
    }


    //FEEDLOT TAB
    let adg = (parseInt(exit_weight) - parseInt(entry_weight)) / parseInt(feed) || '';
    $('#adg').text(adg);


}

/*=====  End of View mode processing  =======*/


/**
 *
 * @param {Number} cattleId
 * @returns {Object}
 */
function getCattleDataFromThePage(cattleId = null) {
    if (!cattleId) {
        return;
    }

    const cattleTableRow = $(`input[name="cattleId"][value="${cattleId}"]`).parent();

    let cattleData = {};

    cattleData.visual_id = cattleTableRow.find('[data-visual-id]').attr('data-visual-id');
    cattleData.animal_id = cattleTableRow.find('[data-animal-id]').attr('data-animal-id');
    cattleData.cattle_type_id = cattleTableRow.find('[data-cattle-type-id]').attr('data-cattle-type-id');
    cattleData.cattle_type_name = cattleTableRow.find('[data-cattle-type-id]').text();
    cattleData.weight = cattleTableRow.find('[data-weight]').attr('data-weight');
    cattleData.plant_name = cattleTableRow.find('[data-plant_name]').attr('data-plant_name');
    cattleData.plant_location = cattleTableRow.find('[data-plant_location]').attr('data-plant_location');
    cattleData.mom = cattleTableRow.find('[data-mom]').attr('data-mom');
    cattleData.dad = cattleTableRow.find('[data-dad]').attr('data-dad');

    cattleData.entry_weight = cattleTableRow.find('[data-entry_weight]').attr('data-entry_weight');
    cattleData.exit_weight = cattleTableRow.find('[data-exit_weight]').attr('data-exit_weight');
    cattleData.feed = cattleTableRow.find('[data-feed_days]').attr('data-feed_days');
    cattleData.barcode = cattleTableRow.find('[data-barcode]').attr('data-barcode');
    cattleData.time_taken = cattleTableRow.find('[data-time_taken]').attr('data-time_taken');
    cattleData.body = cattleTableRow.find('[data-body]').attr('data-body');
    cattleData.hscw = cattleTableRow.find('[data-hscw]').attr('data-hscw');
    cattleData.marbling_percent = cattleTableRow.find('[data-marbling_percent]').attr('data-marbling_percent');
    cattleData.mb_marble = cattleTableRow.find('[data-mb_marble]').attr('data-mb_marble');
    cattleData.ausmeat_marble = cattleTableRow.find('[data-ausmeat_marble]').attr('data-ausmeat_marble');
    cattleData.msa_marble = cattleTableRow.find('[data-msa_marble]').attr('data-msa_marble');
    cattleData.fineness = cattleTableRow.find('[data-fineness]').attr('data-fineness');
    cattleData.distribution = cattleTableRow.find('[data-distribution]').attr('data-distribution');
    cattleData.meat_colour = cattleTableRow.find('[data-meat_colour]').attr('data-meat_colour');
    cattleData.fat_colour = cattleTableRow.find('[data-fat_colour]').attr('data-fat_colour');
    cattleData.eye_muscle_area = cattleTableRow.find('[data-eye_muscle_area]').attr('data-eye_muscle_area');
    cattleData.marbling_count = cattleTableRow.find('[data-marbling_count]').attr('data-marbling_count');
    cattleData.cut_location = cattleTableRow.find('[data-cut_location]').attr('data-cut_location');

    cattleData.pre_feedlot = cattleTableRow.find('[data-pre_feedlot]').attr('data-pre_feedlot');
    cattleData.feedlot = cattleTableRow.find('[data-feedlot]').attr('data-feedlot');
    cattleData.kill = cattleTableRow.find('[data-kill]').attr('data-kill');
    cattleData.sales = cattleTableRow.find('[data-sales]').attr('data-sales');
    cattleData.marketing = cattleTableRow.find('[data-marketing]').attr('data-marketing');
    cattleData.lwt = cattleTableRow.find('[data-lwt]').attr('data-lwt');
    cattleData.hscw2 = cattleTableRow.find('[data-hscw2]').attr('data-hscw2');

    cattleData.cattle_id = cattleTableRow.find('[name="cattleId"]').val();

    return cattleData;
}

function openModalInViewOnlyMode () {
    const modal = $('#view-edit-cattle');
    const editModeButton = $('label[for="editModeCheckbox"]');

    editModeButton.hide();

    modal.modal('show');
}

function resetEditModeButtonVisibility() {
    const editModeButton = $('label[for="editModeCheckbox"]');

    editModeButton.show();
}

function updateModalMode(isEditMode, modalId) {
    if (!modalId) {
        return
    }

    const modal = $(`#${modalId}`);
    const submitBtn = $('.modal#view-edit-cattle button[type="submit"]');

    if (isEditMode) {
        submitBtn.show();
        $('#weight-history-tab').hide();

        if ($('#weight-history-tab').hasClass('active')){
            $('#weight-history-tab').parent().prev().find('.nav-link').trigger('click');
        }

        modal.find('input:not(#editModeCheckbox), select, textarea').each((index, control) => {
            const hasAutocomplete = $(control).parent().hasClass('autoComplete_wrapper');
            const isProgeny = $(control).parents('#editProgeny').length;

            $(control).show();

            if (isProgeny) {
                $(control).parents('#editProgeny').show();
                $(control).parents('#editProgeny').next().hide();

                return;
            }

            if (hasAutocomplete) {
                $(control).parent().show();
                $(control).parent().next().hide();
            } else {
                $(control).next(':not(span)').hide();
            }
        });

        $('#showTimetaken').hide();

        modal.find('[data-only-in-view-mode]').hide();

        modal.find('.uploaded-image-actions__delete').css('display', 'flex')

        if (modal.find('#animal-image .uploaded-image').length < 10) {
            modal.find('#animal-image .image-upload').show();
            modal.find('#animal-image #image-upload-label').show();
            modal.find('#animal-image #image-upload').removeAttr('disabled');
        }
        if (modal.find('#meat-image .uploaded-image').length < 3) {
            modal.find('#meat-image .image-upload').show();
            modal.find('#meat-image #image-upload-label').show();
            modal.find('#meat-image #image-upload').removeAttr('disabled');
        }
    } else {
        submitBtn.hide();
        $('#weight-history-tab').show();

        modal.find('input:not(#editModeCheckbox), select, textarea').each((index, control) => {
            const hasAutocomplete = $(control).parent().hasClass('autoComplete_wrapper');
            const isProgeny = $(control).parents('#editProgeny').length;

            $(control).hide();

            if (isProgeny) {
                $(control).parents('#editProgeny').hide();
                $(control).parents('#editProgeny').next().show();

                return;
            }

            if (hasAutocomplete) {
                $(control).parent().hide();
                $(control).parent().next().show();
            } else {
                $(control).next().show();
            }
        });

        $('#showTimetaken').show();
        modal.find('[data-only-in-view-mode]').show();
        modal.find('.image-upload').hide();
        modal.find('.uploaded-image-actions__delete').css('display', 'none')
    }
}


function initEditAnimalPopupUploadImages(imageType, maxCountImages) {
    const editAnimalPopupImageUploadLabel = document.getElementById(`${imageType}-image-upload-label`);
    const editAnimalPopupImageUploadInput = document.getElementById(`${imageType}-image-upload`);
    const editAnimalPopupImageUploadCurrentInput = document.getElementById(`${imageType}-current-image-upload`);
    const editAnimalPopupUploadedImagesWrapper = document.getElementById(`${imageType}-uploaded-images`);
    const editAnimalPopupUploadingErrors = document.getElementById(`${imageType}-uploading-errors`);


    if (editAnimalPopupImageUploadLabel) {
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            editAnimalPopupImageUploadLabel.addEventListener(eventName, event => {
                event.preventDefault();
                event.stopPropagation();
            });
        });

        ['dragenter', 'dragover'].forEach(eventName => {
            editAnimalPopupImageUploadLabel.addEventListener(eventName, () => {
                editAnimalPopupImageUploadLabel.classList.add('highlight');
            });
        });

        ['dragleave', 'drop'].forEach(eventName => {
            editAnimalPopupImageUploadLabel.addEventListener(eventName, () => {
                editAnimalPopupImageUploadLabel.classList.remove('highlight');
            });
        });

        editAnimalPopupImageUploadLabel.addEventListener('drop', event => {
            const currentImages = $(`#${imageType}-current-image-upload`)[0].files
            let newCount = $(event.target).closest('.tab-pane').find('.uploaded-image').length + event.dataTransfer.files.length

            uploadFiles(event.dataTransfer.files, currentImages, newCount);
        });

        editAnimalPopupImageUploadInput.addEventListener('change', event => {
            const currentImages = $(`#${imageType}-current-image-upload`)[0].files
            let newCount = $(event.target).closest('.tab-pane').find('.uploaded-image').length + event.target.files.length

            uploadFiles(event.target.files, currentImages, newCount);
        });

        function uploadFiles(files, currentImages = [], newCount = 0) {


            editAnimalPopupDataTransfer = new DataTransfer();

            if ( newCount > maxCountImages
                || files.length > maxCountImages
            ) {

                editAnimalPopupImageUploadInput.files = editAnimalPopupImageUploadCurrentInput.files;
                editAnimalPopupUploadingErrors.innerHTML = `Maximum allowed number of images is ${maxCountImages}`;
                return;
            }

            for (let i = 0; i < currentImages.length; i++) {
                editAnimalPopupDataTransfer.items.add(currentImages[i]);
            }

            let currentLength = $('.uploaded-image').length ?? 0

            for (let i = 0; i < files.length; i++) {
                if( files[i].type.split('/')[0] === 'image' ) {
                    editAnimalPopupDataTransfer.items.add(files[i]);
                    uploadFilesToArray(files[i], currentLength);

                    currentLength++;
                } else {
                    editAnimalPopupUploadingErrors.innerHTML = 'Invalid file format';
                }
            }

            editAnimalPopupImageUploadInput.files = editAnimalPopupDataTransfer.files;
            editAnimalPopupImageUploadCurrentInput.files = editAnimalPopupDataTransfer.files;

        }

        function uploadedImagePreview(image, id) {
            let reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onloadend = function () {
                let uploadedImage = document.getElementById(`uploaded-image-${id}`);
                let uploadedImagePreviewImage = uploadedImage.querySelector('.uploaded-image-preview--image');
                uploadedImagePreviewImage.style.backgroundImage = `url(${reader.result})`;
            }

            return `
                <div class="uploaded-image" id="uploaded-image-${id}" data-image-name="${image.name}">
                    <div class="uploaded-image-info">
                        <div class="uploaded-image-preview">
                            <div class="uploaded-image-preview--image" style="display: none; background-image: url('');"></div>
                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30 37.75H24C18.57 37.75 16.25 35.43 16.25 30V24C16.25 18.57 18.57 16.25 24 16.25H29C29.41 16.25 29.75 16.59 29.75 17C29.75 17.41 29.41 17.75 29 17.75H24C19.39 17.75 17.75 19.39 17.75 24V30C17.75 34.61 19.39 36.25 24 36.25H30C34.61 36.25 36.25 34.61 36.25 30V25C36.25 24.59 36.59 24.25 37 24.25C37.41 24.25 37.75 24.59 37.75 25V30C37.75 35.43 35.43 37.75 30 37.75Z" fill="#7F8A94" />
                                <path d="M37 25.748H33C29.58 25.748 28.25 24.418 28.25 20.998V16.998C28.25 16.698 28.43 16.418 28.71 16.308C28.99 16.188 29.31 16.258 29.53 16.468L37.53 24.468C37.6343 24.5731 37.7052 24.7066 37.7338 24.8519C37.7624 24.9971 37.7474 25.1475 37.6908 25.2843C37.6342 25.4211 37.5384 25.5381 37.4155 25.6206C37.2926 25.7031 37.148 25.7474 37 25.748ZM29.75 18.808V20.998C29.75 23.578 30.42 24.248 33 24.248H35.19L29.75 18.808Z" fill="#7F8A94" />
                            </svg>
                        </div>
                        <div class="uploaded-image-description">
                            <div class="uploaded-image-description__title">
                                <h6 style="margin: 0;">${image.name}</h6>
                            </div>
                            <div class="uploaded-image-progress-bar">
                                <div class="uploaded-image-progress-bar--state" style="width: 0%;"></div>
                            </div>
                            <p class="uploaded-image-description__text">
                                <span class="uploaded-image-progress-bar--percent">0%</span>
                                <span class="uploaded-image-size">${bytesToSize(image.size)}</span>
                            </p>
                        </div>
                    </div>
                    <div class="uploaded-image-actions">
                        <button type="button" class="uploaded-image-actions__button uploaded-image-actions__delete">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.01699 9.01667L6.00032 7L8.00032 9.01667L8.78366 8.21667L6.80032 6.2L8.78366 4.18333L8.00032 3.38333L6.00032 5.4L4.01699 3.38333L3.21699 4.18333L5.21699 6.2L3.21699 8.21667L4.01699 9.01667ZM2.35033 12C2.08366 12 1.85033 11.9 1.65033 11.7C1.45033 11.5 1.35033 11.2667 1.35033 11V1.5H0.666992V0.5H3.80032V0H8.20032V0.5H11.3337V1.5H10.6503V11C10.6503 11.2667 10.5503 11.5 10.3503 11.7C10.1503 11.9 9.91699 12 9.65032 12H2.35033Z" fill="#E3342F" />
                            </svg>
                        </button>
                    </div>
                </div>
            `;
        }

        function animateProgressBar(id) {
            let uploadedImage = document.querySelector(`#uploaded-image-${id}`);
            let uploadedImagePreview = uploadedImage.querySelector('.uploaded-image-preview');
            let uploadedImageProgressBar = uploadedImage.querySelector('.uploaded-image-progress-bar');
            let uploadedImageProgressBarState = uploadedImage.querySelector('.uploaded-image-progress-bar--state');
            let uploadedImageProgressBarPercent = uploadedImage.querySelector('.uploaded-image-progress-bar--percent');
            let i = 0;

            if (i === 0) {
                i = 1;
                let width = 1;
                let id = setInterval(frame, 10);
                function frame() {
                    if (width >= 100) {
                        clearInterval(id);
                        i = 0;
                        uploadedImagePreview.querySelector('svg').style.display = 'none';
                        uploadedImagePreview.querySelector('.uploaded-image-preview--image').style.display = '';
                        uploadedImageProgressBar.style.display = 'none';
                        uploadedImageProgressBarPercent.style.display = 'none';
                    } else {
                        width++;
                        uploadedImageProgressBarState.style.width = width + '%';
                        uploadedImageProgressBarPercent.innerHTML = width + '%';
                    }
                }
            }
        }

        function uploadFilesToArray(file, currentLength = 0) {
            editAnimalPopupUploadingErrors.innerHTML = '';

            if (file.size > 10000000) {
                editAnimalPopupUploadingErrors.innerHTML = 'Maximum allowed file size is 10MB';
                return;
            }

            let imageID = $(editAnimalPopupImageUploadLabel).closest('.tab-pane').find('.uploaded-image').length !== 0
                ? parseInt($(editAnimalPopupImageUploadLabel).closest('.tab-pane').find('.uploaded-image:last-child').attr('id').split('-')[2]) + 1
                : 0;

            editAnimalPopupUploadedImagesWrapper.insertAdjacentHTML('beforeend', uploadedImagePreview(file, imageID));
            animateProgressBar(imageID);

            if (editAnimalPopupUploadedImagesWrapper.childElementCount === maxCountImages) {
                editAnimalPopupImageUploadLabel.style.display = 'none';
            }
        }
    }
}
