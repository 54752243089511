import axios from "axios";

$('.js-import-csv').on('click', function (e) {
    e.preventDefault();

    $(this).addClass('d-none');
    $('.js-select-import').removeClass('d-none')
});

$('.js-select-import').on('click', function (e) {
    e.preventDefault();

    const type = $(this).data('type');

    $('.js-select-import').addClass('d-none');
    $('.js-import-form input[name="file"]').removeClass('d-none');
    $('.js-import-form input:last').val(type);
});

$('.js-import-form input[name="file"]').on('change', function (e) {
    const filename = $(this).val();
    const filesize = e.currentTarget.files[0].size;
    const maxFilesize = 4 * 1024 * 1024;

    if(filesize > maxFilesize) {
        $('.js-import-form b').removeClass('d-none');
        $(this).val('');

        return;
    }

    $('.js-import-form b').addClass('d-none');
    $(this).addClass('d-none');
    $('.js-import-form span').text(filename);
    $('.js-import-form button').removeClass('d-none');
});

if(window.location.pathname === '/cattle-database' || window.location.pathname === '/cattle-database-search') {
    checkImport();
}

function checkImport() {
    axios.get(`/check-import-cattles`)
        .then(function(response) {
            let status = response.data.status;
            if ( status === 'false' ) {
                setTimeout(() => { checkImport(); }, 1000);
            } else {
                if ( status === 'completed' ) {
                    setTimeout(() => { axios.get(`/cattles-imported`); }, 10000);
                } else {
                    window.location = window.location.origin + '/' + 'cattle-database';
                }
            }
        });
}
