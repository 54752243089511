import axios from "axios";
import {initialAutocomplete} from './autocomplete';
import bytesToSize from '../../helpers/bytesToSize';
import tabsHTML from './tabs/tabsHTML';
import infoTab from './tabs/infoTab';
import carcassTab from './tabs/carcassTab';
import breedingTab from './tabs/breedingTab';
import progenyTab from './tabs/progenyTab';
import feedlotTab from './tabs/feedlotTab';
import animalImagesTab from './tabs/animalImagesTab';
import copTab from './tabs/copTab';
import saleTab from './tabs/saleTab';
import {IDsValidation, onlyLengthValidation, timeTakenValidation, weightValidation} from "./validation";
import meatImagesTab from "./tabs/meatImagesTab";
import {initDateTimePicker} from "./init-datetime-picker";

let images = [];

$('.js-add-cattle-instance').on('click', async function() {
    const cattlesOptions = $('#selectCattle').html();
    const paddocksOptions = $('#selectPaddock').html();
    const isFull = $('body').data('is-full')

    const idx = $('.cattle-instance').length;
    const userId = $('[data-user-id]').attr('data-user-id');

    let breedingShow = $('.js-form-add-cattle .nav-tabs:first #breedinga-tab').length;
    let progenyShow = $('.js-form-add-cattle .nav-tabs:first #progenya-tab').length;
    let feedlotShow = $('.js-form-add-cattle .nav-tabs:first #feedlota-tab').length;
    let copShow = $('.js-form-add-cattle .nav-tabs:first #copa-tab').length;
    let saleShow = $('.js-form-add-cattle .nav-tabs:first #salea-tab').length;

    $('.js-form-add-cattle .tab-content:last').after(`${idx !== 0 ? '<hr class="my-4"/>' : ''}` + tabsHTML(idx, breedingShow, progenyShow, feedlotShow, copShow, saleShow));

    $('.js-form-add-cattle .nav-tabs:last').after(`
        <div class="tab-content">
            ${infoTab(isFull, cattlesOptions, paddocksOptions, idx, userId)}
            ${carcassTab(idx)}
            ${breedingTab(idx, breedingShow)}
            ${progenyTab(idx, progenyShow)}
            ${feedlotTab(idx, feedlotShow)}
            ${copTab(idx, copShow)}
            ${saleTab(idx, saleShow)}
            ${animalImagesTab(idx)}
            ${meatImagesTab(idx)}
        </div>
    `);

    if ( isFull ) {
        $('.js-form-add-cattle .cattle-instance:last .js-select-paddock-for-cattle').val('0').trigger('change')
    }

    let cattleData = await axios.get(`/get-cattle-data?search=yes`);

    initUploadImages(idx, 'animal', 10);
    initUploadImages(idx, 'meat', 3);
    initDateTimePicker(`datetimepicker${idx}`);
    initialAutocomplete(`#breedinga${idx} #dad${idx}`, cattleData.data.cattle);
    initialAutocomplete(`#breedinga${idx} #mom${idx}`, cattleData.data.cattle);
    initialAutocomplete(`#progenya${idx} #children${idx}`, cattleData.data.cattle);
});

$('.js-remove-cattle-instance').on('click', function() {
    if ( $('.cattle-instance').length > 1) {
        $('.js-form-add-cattle .nav-tabs:last').remove();
        $('.js-form-add-cattle .tab-content:last').remove();
        $('.my-4').remove();
    }
});

$('.js-add-cattle').on('click', function(e) {
    e.preventDefault();
    let promises = [];
    let promisesFromValidation = [];

    window.animalIDs = []
    window.visualIDs = []
    window.modalCattlesValidated = true;

    $('#addCattle .tab-content').each(function(index) {
        promisesFromValidation = handleValidation($(this), index, `/unique-field?`);
        promises = [...promises, ...promisesFromValidation];
    });

    $.when.apply(null, promises).then(function() {

        if (modalCattlesValidated) {
            $('.js-form-add-cattle').trigger('submit');
        } else {
            $('.js-add-cattle').attr('disabled', '')
        }
    });
});

$('.js-update-cattle').on('click', function(e) {
    e.preventDefault();

    if(e.detail !== 1) return;

    const elInstance = $(this).closest('.js-form-edit-cattle');
    let urlFromAction = $('.js-form-edit-cattle').attr('action'),
        cattleId = urlFromAction.match(/[0-9]+$/)[0],
        promises = [],
        promisesFromValidation = [];

    window.modalCattlesValidated = true;

    promisesFromValidation = handleValidation(elInstance, false, `/unique-field?cattleId=${cattleId}&`);
    promises = [...promisesFromValidation];

    $.when.apply(null, promises).then(function() {
        if (modalCattlesValidated) {
            $('.js-form-edit-cattle').trigger('submit');
        } else {
            $('.js-update-cattle').attr('disabled', '')
        }
    });
});

/**
 * @param  {HTMLDivElement} elInstance
 * @param childIndex
 * @param  {string} requestString
 * @return {array}
 */
export function handleValidation(elInstance, childIndex, requestString = '/unique-field?') {
    const [elVisualId, elAnimalId] = getAnimalAndVisualIds(elInstance);

    let mom = elInstance.find('.row input[name="mom[]"]') ?? null,
        dad = elInstance.find('.row input[name="dad[]"]') ?? null,
        weight = elInstance.find('.row input[name="weight[]"]'),
        entry_weight = elInstance.find('.row input[name="entry_weight[]"]') ?? null,
        exit_weight = elInstance.find('.row input[name="exit_weight[]"]') ?? null,
        feed = elInstance.find('.row input[name="feed[]"]') ?? null

    mom = mom.length > 0 ? mom : elInstance.find('.row input[name="mom"]');
    dad = dad.length > 0 ? dad : elInstance.find('.row input[name="dad"]');

    weight = weight.length > 0 ? weight : elInstance.find('.row input[name="weight"]');
    entry_weight = entry_weight.length > 0 ? entry_weight : elInstance.find('.row input[name="entry_weight"]');
    exit_weight = exit_weight.length > 0 ? exit_weight : elInstance.find('.row input[name="exit_weight"]');
    feed = feed.length > 0 ? feed : elInstance.find('.row input[name="feed"]');

    const promises = [];
    let request = '';

    clearValidation(elInstance);

    let elBarcode = $(elInstance).find('.row input[name="barcode[]"]'),
        elTimeTaken = $(elInstance).find('.row input[name="timetaken[]"]'),
        elBody =  $(elInstance).find('.row input[name="body[]"]'),
        elHSCW =  $(elInstance).find('.row input[name="hscw[]"]'),
        elMarblingPercent = $(elInstance).find('.row input[name="marblingpercent[]"]'),
        elMBMarble = $(elInstance).find('.row input[name="mbmarble[]"]'),
        elAusmeatMarble = $(elInstance).find('.row input[name="ausmeatmarble[]"]'),
        elMSAMarble = $(elInstance).find('.row input[name="msamarble[]"]'),
        elFineness = $(elInstance).find('.row input[name="fineness[]"]'),
        elDistribution = $(elInstance).find('.row input[name="distribution[]"]'),
        elMeatColour = $(elInstance).find('.row input[name="meatcolour[]"]'),
        elFatColour = $(elInstance).find('.row input[name="fatcolour[]"]'),
        elEyeMuscleArea = $(elInstance).find('.row input[name="eyemusclearea[]"]'),
        elMarblingCount = $(elInstance).find('.row input[name="marblingcount[]"]'),
        elCutLocation = $(elInstance).find('.row input[name="cutlocation[]"]'),
        elPreFeedlot = $(elInstance).find('.row input[name="pre_feedlot[]"]'),
        elFeedlot = $(elInstance).find('.row input[name="feedlot[]"]'),
        elKill = $(elInstance).find('.row input[name="kill[]"]'),
        elSales = $(elInstance).find('.row input[name="sales[]"]'),
        elMarketing = $(elInstance).find('.row input[name="marketing[]"]'),
        elLWT = $(elInstance).find('.row input[name="lwt[]"]'),
        elHSCW2 = $(elInstance).find('.row input[name="hscw2[]"]');

        elBarcode = elBarcode.length > 0 ? elBarcode : $(elInstance).find('.row input[name="barcode"]'),
        elTimeTaken = elTimeTaken.length > 0 ? elTimeTaken : $(elInstance).find('.row input[name="timetaken"]'),
        elBody = elBody.length > 0 ? elBody : $(elInstance).find('.row input[name="body"]'),
        elHSCW = elHSCW.length > 0 ? elHSCW : $(elInstance).find('.row input[name="hscw"]'),
        elMarblingPercent = elMarblingPercent.length > 0 ? elMarblingPercent : $(elInstance).find('.row input[name="marblingpercent"]'),
        elMBMarble = elMBMarble.length > 0 ? elMBMarble : $(elInstance).find('.row input[name="mbmarble"]'),
        elAusmeatMarble = elAusmeatMarble.length > 0 ? elAusmeatMarble : $(elInstance).find('.row input[name="ausmeatmarble"]'),
        elMSAMarble = elMSAMarble.length > 0 ? elMSAMarble : $(elInstance).find('.row input[name="msamarble"]'),
        elFineness = elFineness.length > 0 ? elFineness : $(elInstance).find('.row input[name="fineness"]'),
        elDistribution = elDistribution.length > 0 ? elDistribution : $(elInstance).find('.row input[name="distribution"]'),
        elMeatColour = elMeatColour.length > 0 ? elMeatColour : $(elInstance).find('.row input[name="meatcolour"]'),
        elFatColour = elFatColour.length > 0 ? elFatColour : $(elInstance).find('.row input[name="fatcolour"]'),
        elEyeMuscleArea = elEyeMuscleArea.length > 0 ? elEyeMuscleArea : $(elInstance).find('.row input[name="eyemusclearea"]'),
        elMarblingCount = elMarblingCount.length > 0 ? elMarblingCount : $(elInstance).find('.row input[name="marblingcount"]'),
        elCutLocation = elCutLocation.length > 0 ? elCutLocation : $(elInstance).find('.row input[name="cutlocation"]'),
        elPreFeedlot = elPreFeedlot.length > 0 ? elPreFeedlot : $(elInstance).find('.row input[name="pre_feedlot"]'),
        elFeedlot = elFeedlot.length > 0 ? elFeedlot : $(elInstance).find('.row input[name="feedlot"]'),
        elKill = elKill.length > 0 ? elKill : $(elInstance).find('.row input[name="kill"]'),
        elSales = elSales.length > 0 ? elSales : $(elInstance).find('.row input[name="sales"]'),
        elMarketing = elMarketing.length > 0 ? elMarketing : $(elInstance).find('.row input[name="marketing"]'),
        elLWT = elLWT.length > 0 ? elLWT : $(elInstance).find('.row input[name="lwt"]'),
        elHSCW2 = elHSCW2.length > 0 ? elHSCW2 : $(elInstance).find('.row input[name="hscw2"]');


    if(mom !== null) {
        if(mom.length !== 0) {

            request = axios.get(requestString + `field=visual_id&value=${mom.val()}`)
                .then(response => {
                    if (response.data.status === 'success') {
                        setInvalid(mom, 'There is no cattle with this EID');
                        modalCattlesValidated = false;
                    } else if (response.data.status === 'duplicate') {
                        setInvalid(mom, 'You cannot assign the same animal');
                        modalCattlesValidated = false;
                    }
                });
            promises.push(request);


            request = axios.get(requestString + `field=visual_id&value=${dad.val()}`)
                .then(response => {
                    if (response.data.status === 'success') {
                        setInvalid(dad, 'There is no cattle with this EID');
                        modalCattlesValidated = false;
                    } else if (response.data.status === 'duplicate') {
                        setInvalid(dad, 'You cannot assign the same animal');
                        modalCattlesValidated = false;
                    }
                });
            promises.push(request);

            if(dad.val() !== '' && dad.val() === mom.val()) {
                setInvalid(dad, 'You can not assign the same animals');
                modalCattlesValidated = false;
                setInvalid(mom, 'You can not assign the same animals');
                modalCattlesValidated = false;
            }
        }
    }

    if(childIndex !== false) {
        $(elInstance).find(`.row input[name="children[${childIndex}][]"]`).each(function (i) {
            checkChildExists($(this), i);
        });
    } else {
        $(elInstance).find(`.row input[name="children[]"]`).each(function (i) {
            checkChildExists($(this), i);
        });
    }
    function checkChildExists(child, i) {
        if(i !== 0 && child.val() === '') {
            setInvalid(child);
            modalCattlesValidated = false;
        } else {
            request = axios.get(requestString + `field=visual_id&value=${child.val()}`)
                .then(response => {
                    if (response.data.status === 'success') {
                        setInvalid(child, 'There is no cattle with this EID');
                        modalCattlesValidated = false;
                    } else if (response.data.status === 'duplicate') {
                        setInvalid(child, 'You cannot assign the same animal');
                        modalCattlesValidated = false;
                    }
                });
            promises.push(request);
        }
    }

    if ( elVisualId.val() === '' ) {
        setInvalid(elVisualId);
        modalCattlesValidated = false;
    }
    else if ( elVisualId.val().length > 16 ) {
        setInvalid(elVisualId, 'This field must not be greater than 16 characters');
        modalCattlesValidated = false;
    }
    else if ( elVisualId.val() < 0 ) {
        setInvalid(elVisualId, 'This field must be greater than 0');
        modalCattlesValidated = false;
    } else if ( visualIDs.includes(elVisualId.val()) ) {
        setInvalid(elVisualId, 'This Visual ID is already entered')
        modalCattlesValidated = false;
    } else {
        request = axios.get(requestString + `field=visual_id&value=${elVisualId.val()}`)
            .then(response => {
                if (response.data.status === 'error') {
                    setInvalid(elVisualId, 'This field should be unique');
                    modalCattlesValidated = false;
                }
            });
        promises.push(request);
    }

    if ( !visualIDs.includes(elVisualId.val()) ) {
        visualIDs.push(elVisualId.val())
    }

    if ( elAnimalId.val() === '' ) {
        setInvalid(elAnimalId);
        modalCattlesValidated = false;
    }
    else if ( elAnimalId.val().length > 16 ) {
        setInvalid(elAnimalId, 'This field must not be greater than 16 characters');
        modalCattlesValidated = false;
    }
    else if ( elAnimalId.val() < 0 ) {
        setInvalid(elAnimalId, 'This field must be greater than 0');
        modalCattlesValidated = false;
    } else if ( animalIDs.includes(elAnimalId.val()) ) {
        setInvalid(elAnimalId, 'This Animal ID is already entered')
        modalCattlesValidated = false;
    } else {
        request = axios.get(requestString + `field=animal_id&value=${elAnimalId.val()}`)
                .then(response => {
                    if (response.data.status === 'error') {
                        setInvalid(elAnimalId, 'This field should be unique');
                        modalCattlesValidated = false;
                    }
                });
        promises.push(request);
    }

    if ( !animalIDs.includes(elAnimalId.val()) ) {
        animalIDs.push(elAnimalId.val())
    }



    let validationResults = [
        //IDsValidation(elVisualId, requestString),
        //IDsValidation(elAnimalId, requestString),
        weightValidation(weight),
        timeTakenValidation(elTimeTaken),
        onlyLengthValidation(elBarcode, 30),
        onlyLengthValidation(elBody, 30),
        onlyLengthValidation(elHSCW, 5, true),
        onlyLengthValidation(elMarblingPercent, 30),
        onlyLengthValidation(elMBMarble, 30),
        onlyLengthValidation(elAusmeatMarble, 30),
        onlyLengthValidation(elMSAMarble, 30),
        onlyLengthValidation(elFineness, 30),
        onlyLengthValidation(elDistribution, 30),
        onlyLengthValidation(elMeatColour, 30),
        onlyLengthValidation(elFatColour, 30),
        onlyLengthValidation(elEyeMuscleArea, 30),
        onlyLengthValidation(elMarblingCount, 30),
        onlyLengthValidation(elCutLocation, 30),
        onlyLengthValidation(elPreFeedlot, 6),
        onlyLengthValidation(elFeedlot, 6),
        onlyLengthValidation(elKill, 6),
        onlyLengthValidation(elSales, 6),
        onlyLengthValidation(elMarketing, 6),
        onlyLengthValidation(elLWT, 5),
        onlyLengthValidation(elHSCW2, 5),
        onlyLengthValidation(entry_weight, 7, true),
        onlyLengthValidation(exit_weight, 7, true),
        onlyLengthValidation(feed, 7, true)
    ]

    if ( modalCattlesValidated ) {
        modalCattlesValidated = !validationResults.includes(false)
    }


    return promises;
}

/**
 * @param  {HTMLDivElement} elInstance
 * @return {array}
 */
function getAnimalAndVisualIds(elInstance) {
    let elVisualId = elInstance.find('.row input[name="visual_ids[]"]');
    let elAnimalId = elInstance.find('.row input[name="animal_ids[]"]');

    elVisualId = elVisualId.length > 0 ? elVisualId : elInstance.find('.row input[name="visual_id"]');
    elAnimalId = elAnimalId.length > 0 ? elAnimalId : elInstance.find('.row input[name="animal_id"]');

    return [elVisualId, elAnimalId];
}

/**
 * @param  {HTMLDivElement} elInstance
 */
 function clearValidation(elInstance) {
    elInstance.find('.js-small-text').remove();
    elInstance.find('.is-invalid').removeClass('is-invalid');
}

/**
 * @param  {HTMLElement} el
 * @param  {string} message
 */
export function setInvalid(el, message = 'This field is required', isOutDiv = false) {

    if( $(el).closest('#filter-options').length === 0 ) {
        if (!el.next().hasClass('js-small-text') && !isOutDiv) {
            el.after(`<span class="js-small-text">${message}</span>`);
        } else {

            if ( $(el).closest('.col-md-8').find('.timetaken-error').length === 0 ) {
                $(el).closest('.col-md-8').append(`<span class="js-small-text timetaken-error">${message}</span>`)
            }

        }
    } else {
        $(el).closest('div.js-filter-option').after(`<div class="d-flex mb-1 js-filter-option js-filter-option-error"><span class="js-small-text" style="margin-left: 13.5%">${message}</span></div>`)
    }



    el.addClass('is-invalid');
}


$(document).on('click', '.js-add-progeny', async function(e) {
    let target = e.target;
    let idx = $(target).closest('.tab-pane').attr('id').replace(/[^0-9]/g, '');
    let arrEl = idx !== undefined ? idx : 0;

    let inputCount = $(target).closest('.tab-pane').find(`input`).length;
    let inputHtml = $(`#progenya${idx} input:first`).prop('outerHTML');

    if(inputCount === 10) {
        return;
    }

    if(inputCount === 1) {
        $(`#progenya${idx} .autoComplete_wrapper:first+span`).after(inputHtml);
        $(`#progenya${idx} input:last`).attr('id', 'children' + Math.floor(Math.random() * 1000))
    } else {
        $(`#progenya${idx} .autoComplete_wrapper:last`).after(inputHtml);
        $(`#progenya${idx} input:last`).attr('id', 'children-' + + Math.floor(Math.random() * 1000))
    }

    let cattleData = await axios.get(`/get-cattle-data?search=yes`);
    initialAutocomplete(`#progenya${idx} input:last`, cattleData.data.cattle);
    $(`#progenya${idx} input:last`).removeClass('is-invalid')
});

$(document).on('click', '.js-remove-progeny', function(e) {
    let target = e.target;
    let idx = $(target).closest('.tab-pane').attr('id').replace(/[^0-9]/g, '');
    let arrEl = idx !== undefined ? idx : 0;

    let inputCount = $(target).closest('.tab-pane').find(`input`).length;

    if(inputCount === 1) {
        return;
    }

    $(`#progenya${idx} .autoComplete_wrapper:last`).remove();
});

initUploadImages(0, 'animal', 10);
initUploadImages(0, 'meat', 3);

function initUploadImages(idx = 0, imageType, maxCountImages) {
    const imageUploadLabel = document.getElementById(`${imageType}-imagea-upload-label-${idx}`);
    const imageUploadInput = document.getElementById(`${imageType}-imagea-upload-${idx}`);
    const uploadedImagesWrapper = document.getElementById(`${imageType}-uploadeda-images-${idx}`);
    const uploadingErrors = document.getElementById(`${imageType}-uploadinga-errors-${idx}`);

    if (imageUploadLabel) {
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            imageUploadLabel.addEventListener(eventName, event => {
                event.preventDefault();
                event.stopPropagation();
            });
        });

        ['dragenter', 'dragover'].forEach(eventName => {
            imageUploadLabel.addEventListener(eventName, () => {
                imageUploadLabel.classList.add('highlight');
            });
        });

        ['dragleave', 'drop'].forEach(eventName => {
            imageUploadLabel.addEventListener(eventName, () => {
                imageUploadLabel.classList.remove('highlight');
            });
        });

        let dataTransfer = new DataTransfer();

        imageUploadLabel.addEventListener('drop', event => {
            let newCount = $(event.target).closest('.tab-pane').find('.uploaded-image').length + event.dataTransfer.files.length

            uploadFiles(event.dataTransfer.files, newCount);
        });

        imageUploadInput.addEventListener('change', event => {
            let newCount = $(event.target).closest('.tab-pane').find('.uploaded-image').length + event.target.files.length

            uploadFiles(event.target.files, newCount);
        });

        function uploadFiles(files, newCount = 0) {
            if ( newCount > maxCountImages || files.length > maxCountImages) {
                uploadingErrors.innerHTML = `Maximum allowed number of images is ${maxCountImages}`;

                imageUploadInput.files = dataTransfer.files;
                return;
            }

            for (let i = 0; i < files.length; i++) {
                if( files[i].type.split('/')[0] === 'image' ) {
                    dataTransfer.items.add(files[i]);
                    uploadFilesToArray(files[i], idx);
                } else {
                    uploadingErrors.innerHTML = 'Invalid file format';
                }
            }

            imageUploadInput.files = dataTransfer.files;
        }

        $('.js-form-add-cattle').on('click', '.uploaded-image-actions__delete', function () {
            let $btnParent = $(this).closest('.uploaded-image');
            let fileName = $btnParent.closest('.uploaded-image').find('.uploaded-image-description__title h6').text()
            $btnParent.remove();
            removeFileFromFileList(fileName);

            if ( $(this).closest('#uploaded-images').find('.uploaded-image').length < maxCountImages ) {
                $(this).closest('.tab-pane').find(`#${imageType}-imagea-upload-label-0`).show()
            }

            if ( document.getElementById(`${imageType}-uploadeda-images-${idx}`).childElementCount < maxCountImages ) {
                document.getElementById(`${imageType}-imagea-upload-label-${idx}`).style.display = '';
                document.getElementById(`${imageType}-imagea-upload-${idx}`).removeAttribute('disabled');
            }
        });

        function removeFileFromFileList(name) {
            dataTransfer = new DataTransfer();
            let input = document.getElementById(`${imageType}-imagea-upload-${idx}`);
            let {files} = input;

            let isDeleted = false
            for (let i = 0; i < files.length; i++) {
              let file = files[i];
              if (name !== file.name || isDeleted) {
                dataTransfer.items.add(file);
              } else {
                  isDeleted = true;
              }
            }

            input.files = dataTransfer.files;
        }

        function uploadedImagePreview(image, id) {
            let reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onloadend = function () {
                let uploadedImage = document.getElementById(`uploaded-image-${idx}-${id}`);
                let uploadedImagePreviewImage = uploadedImage.querySelector('.uploaded-image-preview--image');
                uploadedImagePreviewImage.style.backgroundImage = `url(${reader.result})`;
            }

            return `
                <div class="uploaded-image" id="uploaded-image-${idx}-${id}" data-image-name="${image.name}">
                    <div class="uploaded-image-info">
                        <div class="uploaded-image-preview">
                            <div class="uploaded-image-preview--image" style="display: none; background-image: url('');"></div>
                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30 37.75H24C18.57 37.75 16.25 35.43 16.25 30V24C16.25 18.57 18.57 16.25 24 16.25H29C29.41 16.25 29.75 16.59 29.75 17C29.75 17.41 29.41 17.75 29 17.75H24C19.39 17.75 17.75 19.39 17.75 24V30C17.75 34.61 19.39 36.25 24 36.25H30C34.61 36.25 36.25 34.61 36.25 30V25C36.25 24.59 36.59 24.25 37 24.25C37.41 24.25 37.75 24.59 37.75 25V30C37.75 35.43 35.43 37.75 30 37.75Z" fill="#7F8A94" />
                                <path d="M37 25.748H33C29.58 25.748 28.25 24.418 28.25 20.998V16.998C28.25 16.698 28.43 16.418 28.71 16.308C28.99 16.188 29.31 16.258 29.53 16.468L37.53 24.468C37.6343 24.5731 37.7052 24.7066 37.7338 24.8519C37.7624 24.9971 37.7474 25.1475 37.6908 25.2843C37.6342 25.4211 37.5384 25.5381 37.4155 25.6206C37.2926 25.7031 37.148 25.7474 37 25.748ZM29.75 18.808V20.998C29.75 23.578 30.42 24.248 33 24.248H35.19L29.75 18.808Z" fill="#7F8A94" />
                            </svg>
                        </div>
                        <div class="uploaded-image-description">
                            <div class="uploaded-image-description__title">
                                <h6 style="margin: 0;">${image.name}</h6>
                            </div>
                            <div class="uploaded-image-progress-bar">
                                <div class="uploaded-image-progress-bar--state" style="width: 0%;"></div>
                            </div>
                            <p class="uploaded-image-description__text">
                                <span class="uploaded-image-progress-bar--percent">0%</span>
                                <span class="uploaded-image-size">${bytesToSize(image.size)}</span>
                            </p>
                        </div>
                    </div>
                    <div class="uploaded-image-actions">
                        <button type="button" class="uploaded-image-actions__button uploaded-image-actions__delete">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.01699 9.01667L6.00032 7L8.00032 9.01667L8.78366 8.21667L6.80032 6.2L8.78366 4.18333L8.00032 3.38333L6.00032 5.4L4.01699 3.38333L3.21699 4.18333L5.21699 6.2L3.21699 8.21667L4.01699 9.01667ZM2.35033 12C2.08366 12 1.85033 11.9 1.65033 11.7C1.45033 11.5 1.35033 11.2667 1.35033 11V1.5H0.666992V0.5H3.80032V0H8.20032V0.5H11.3337V1.5H10.6503V11C10.6503 11.2667 10.5503 11.5 10.3503 11.7C10.1503 11.9 9.91699 12 9.65032 12H2.35033Z" fill="#E3342F" />
                            </svg>
                        </button>
                    </div>
                </div>
            `;
        }

        function animateProgressBar(id) {
            let uploadedImage = document.querySelector(`#uploaded-image-${idx}-${id}`);
            let uploadedImagePreview = uploadedImage.querySelector('.uploaded-image-preview');
            let uploadedImageProgressBar = uploadedImage.querySelector('.uploaded-image-progress-bar');
            let uploadedImageProgressBarState = uploadedImage.querySelector('.uploaded-image-progress-bar--state');
            let uploadedImageProgressBarPercent = uploadedImage.querySelector('.uploaded-image-progress-bar--percent');
            let i = 0;

            if (i === 0) {
                i = 1;
                let width = 1;
                let id = setInterval(frame, 10);
                function frame() {
                    if (width >= 100) {
                        clearInterval(id);
                        i = 0;
                        uploadedImagePreview.querySelector('svg').style.display = 'none';
                        uploadedImagePreview.querySelector('.uploaded-image-preview--image').style.display = '';
                        uploadedImageProgressBar.style.display = 'none';
                        uploadedImageProgressBarPercent.style.display = 'none';
                    } else {
                        width++;
                        uploadedImageProgressBarState.style.width = width + '%';
                        uploadedImageProgressBarPercent.innerHTML = width + '%';
                    }
                }
            }
        }

        function uploadFilesToArray(file) {
            uploadingErrors.innerHTML = '';

            if (file.size > 10000000) {
                uploadingErrors.innerHTML = 'Maximum allowed file size is 10MB';
                return;
            }

            images.push(file);
            let imageID = idx + images.length;

            uploadedImagesWrapper.insertAdjacentHTML('beforeend', uploadedImagePreview(file, imageID));
            animateProgressBar(imageID);

            if (uploadedImagesWrapper.childElementCount === maxCountImages) {
                imageUploadLabel.style.display = 'none';
            }
        }
    }
}
