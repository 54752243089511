$('.js-report-table').on('change', '#femaleStatuses', function (e) {
    const element = e.target;

    if ( $(element).val() === 'other' ) {
        $(element).closest('tr').addClass('current');

        $('#femaleStatusModal').modal('show');
        $('#femaleStatusModal input').val('');
    } else if ( $(element).data('type') !== 'custom' ) {
        $(element).closest('tr').find('#femaleStatuses option[data-type="custom"]').remove();
    }
});

$('#femaleStatusModal .modal-footer button').on('click', function (e) {
    const statusValue = $('input[name="status_in_modal"]').val();
    const statusesSelect = $('tr.current').find('#femaleStatuses')

    $(statusesSelect).find('option[data-type="custom"]').remove();

    if ( $(this).text() === 'Save' ) {
        $(statusesSelect).append(`<option data-type="custom" value="${statusValue}">${statusValue}</option>`).val(statusValue);
    } else {
        $(statusesSelect).val('0')
    }

    $('tr').removeClass('current');
});
