$('.js-reject-invitation').on('click', function() {

    const elRejectReason = $(this).closest('form').find('#rejectReason');

    if ( elRejectReason.val().length === 0 ) {
        elRejectReason.addClass('is-invalid');
        elRejectReason.after(`<span class="js-small-text">This field is required</span>`);
        return;
    }

    if ( $(this).hasClass('js-friendship') ) {
        $('.js-form-reject-friendship').append(`<input type="hidden" name="reject_reason" value="${elRejectReason.val()}"/>`)
        $('.js-form-reject-friendship').trigger('submit');
    } else if ( $(this).hasClass('js-transfer') ) {
        $('.js-form-reject-transfer.current').append(`<input type="hidden" name="reject_reason" value="${elRejectReason.val()}"/>`)
        $('.js-form-reject-transfer.current').trigger('submit');
    }

});
