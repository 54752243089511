$('.js-export-csv').one('click', function(e) {
    e.preventDefault();

    let href = $(this).attr('href');
    let urlWithQueryString = window.location.search !== ''
        ? href + window.location.search + '&export=export&type=' + $(this).data('type')
        : href + '?export=export&type=' + $(this).data('type');

    $(this).attr('href', urlWithQueryString);
    e.currentTarget.click();
});
