import axios from "axios";

$('.js-resend-btn').on('click', function() {
    const friendshipId = $(this).data('friendship-id');

    axios.get(`/friendship-info?friendship_id=${friendshipId}`)
        .then(response => {
            $('.js-friendship-email').val(response.data.data.friendship.friend.email);

            $('.js-friendship-properties option').each(function() {
                let propertyId = Number( $(this).val() );
                for (const user_property of response.data.data.user_properties) {
                    let accessedPropertyId = user_property.id

                    if (propertyId === accessedPropertyId) {
                        $(this).attr('selected', 'selected');
                    }
                }
            });

            $('.js-friendship-properties').trigger('change');
        });

    new bootstrap.Modal('#inviteToFriendshipModal').show();
});
