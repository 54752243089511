require('./cattle-type/add-cattle-type-by-select');
require('./cattle-type/add-cattle-type-by-modal');
require('./cattle-type/calculate-cattle-type');
require('./cattle-type/edit-cattle-type');
require('./cattle-type/actions-cattle-type');
require('./cattle-type/table-actions-cattle-type');


$('#cattleReturnAffect').on('change', function (e) {

    if ( $(this).val() === '0' ) {

        $('#cattleReturnAffectReclass').removeAttr('disabled');

    } else {

        $('#cattleReturnAffectReclass').val('1').attr('disabled', 'disabled');

    }

});

$('.js-age-border').on('input change', function(e) {

    let year = $(this).val();
    const date = new Date();

    if ( year > parseInt(date.getFullYear()) - 50 ) {
        $('.js-age-border-switch, .js-increase-age-border-switch').removeClass('d-none')
    } else {
        $('.js-age-border-switch, .js-increase-age-border-switch').addClass('d-none')
    }
})
