import {addNewAnimalsAlert} from "./actions-alerts";

const currentLevel = $('.js-switch-account-level').val()
$('.js-switch-account-level').on('change', function (e) {
    const targetLevel = $(this).val()

    if (
        (currentLevel === 'basic' || currentLevel === 'basic_plus' || currentLevel === 'full')
        && (targetLevel === 'basic_plus' || targetLevel === 'basic')
        && confirm('Are you sure?')
    ) {
        window.location = '/switch-level?to=' + targetLevel
    } else if (
        (currentLevel === 'basic' || currentLevel === 'basic_plus')
        && targetLevel === 'full'
        && confirm('Are you sure?')
    ) {
        axios.get('/check-for-animals-on-switching')
            .then((response) => {
                const missingAnimalsCount = response.data.needsToAdd

                if ( missingAnimalsCount.length === 0 ) {
                    window.location = '/switch-level?to=full&replaceAnimals=true'
                } else {
                    addNewAnimalsAlert(targetLevel, missingAnimalsCount)
                }
            })


    } else {
        window.location = '/profile-settings'
    }

})

$('.js-switch-company-type').on('change', function (e) {
    const type = $(this).val()

    window.location = `/switch-company-type?to=${type}`
})

$('#addCattle').on('hide.bs.modal', function () {

    if ( window.location.pathname.includes('profile-settings') ) {
        window.location = '/profile-settings'
    }
})

$('.js-cattle-return-switch').on('change', function (e) {
    const isChecked = this.checked

    axios.get(`/switch-cattle-return?cattle_return=${isChecked}`)

    $('.js-cattle-return-nav').toggleClass('d-none')
})

$('.js-switch-feedbook').on('change', function (e) {
    const enabled = this.checked
    const domain = $('body').data('domain')

    if ( enabled ) {
        window.location.hostname = 'feedbook.' + domain
    } else {
        window.location.hostname = 'portal.' + domain
    }
})
