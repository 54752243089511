$('.js-remove-new-email-for-staff-request').on('click', function(e) {

    let inputCount = $('#inviteModal input[name="email[]"]').length;

    if ( inputCount === 1 ) {
        return;
    }

    $('#inviteModal input[name="email[]"]:last').remove();

});
