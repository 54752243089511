import axios from "axios";

if(window.location.pathname.includes('ledger')) {

    axios.get('/check-for-notification', {
    })
        .then(response => {
            let status = response.data.status;
            let type = response.data.type;

            if(status === 'alert') {

                const titleText = type === 'monthly' ? 'Monthly Notification' : 'Yearly Notification';
                const contentText = type === 'monthly' ? 'Please complete your Cattle Return by the end of the month' : 'Please complete your Cattle Return by 30th June';

                $.confirm({
                    title: titleText,
                    content: contentText,

                    buttons: {
                        'Already done': function () {
                            axios.get(`/handle-notification?action=done&type=${type}`);
                        },
                        'Remind me later': function() {
                            axios.get(`/handle-notification?action=later&type=${type}`);
                        }
                    }
                });
            }
        });
}
