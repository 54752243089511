import {addFutureYearToSelect} from "../cattle-type/table-actions-cattle-type";

$('.js-report-table').on('change', '#cattleToReclass', async function (e) {
    await updateAgesSelector($(this))

});

export async function updateAgesSelector(select)
{
    const closestAction = $(select).closest('.action-in-table').find('.js-action-select-in-table').val();
    const currentUpdatedAge = $(select).closest('.action-in-table').find('select[name="updated_year_of_birth[]"]:first').val();

    if ( closestAction === 'Reclass' || closestAction === 'Weaned' || closestAction === 'Process Weaners' ) {

        const currentAction = $(select).closest('.action-in-table');
        let currentYear = new Date().getFullYear();
        let currentCattleType = $(select).closest('.action-in-table').find('#cattleTypeExecute').val();
        let newCattleType = $(select).val();
        let newPaddock = $(select).closest('.action-in-table').find('#paddockToMove').val();
        let propertyId = $('input[name="property_id"]:last').val();

        await axios.get(`/available-ages?cattle_type_id=${currentCattleType}&new_cattle_type_id=${newCattleType}&paddock_id=${newPaddock}&property_id=${propertyId}`)
            .then(response => {
                $(currentAction).find('[name="updated_year_of_birth[]"]').empty();
                $(currentAction).find('.js-small-text').remove();

                $(currentAction).find('[name="updated_year_of_birth[]"]').removeAttr('disabled');

                let cattleTypeMinYearOfBirth = response.data.data.min_year_of_birth;
                let cattleTypeHidden = response.data.data.hide_years_of_birth

                let selectOptions = '';

                if ( !cattleTypeHidden ) {
                    selectOptions += `<option value="mixed">mixed</option>`;
                    selectOptions += addFutureYearToSelect();
                    for (let i = currentYear; i >= cattleTypeMinYearOfBirth; i--) {
                        selectOptions += `<option value="${i}">${i}</option>`;
                    }
                } else {
                    selectOptions += `<option value="hidden">hidden</option>`;
                }

                $(currentAction).find('select[name="updated_year_of_birth[]"]:first').append(selectOptions);
                if($(currentAction).find(`td select[name="updated_year_of_birth[]"]:first option[value="${currentUpdatedAge}"]`).length !== 0) {
                    $(currentAction).find('td select[name="updated_year_of_birth[]"]:first').val(currentUpdatedAge);
                } else {
                    $(currentAction).find('td select[name="updated_year_of_birth[]"]:first option:eq(1)').attr('selected', 'selected');
                }

                $(currentAction).find('[name="year_of_birth[]"]').trigger('change')

                if (response.data.status !== 'success') {
                    $(currentAction).find('[name="updated_year_of_birth[]"]')
                        .after('<span class="js-small-text"">'
                            + response.data.message +
                            '</span>');
                }
            })
    }
}
