import axios from "axios";

$('#editStaffForm select[name="properties[]"], .js-send-staff-request-form select[name="properties[]"]').on('change', function(e) {

    const properties = $(this).val()
    const selectGroups = $(this).closest('form').find('select[name="paddock_groups[]"]')

    axios.get(`/get-properties-paddock-group?properties=${properties}`)
        .then( response => {
            const groups = response.data.paddockGroups


            $(selectGroups).empty();
            $(selectGroups).selectpicker('destroy')

            $(selectGroups).selectpicker();
            $(selectGroups).selectpicker('render')
            groups.forEach(function (value, index) {
                $(selectGroups).append(`<option value="${value.id}">${value.name}</option>`)
            })

            $(selectGroups).trigger('change')
            $(selectGroups).selectpicker('refresh')

            if ( groups.length > 0 ) {
                $('#paddockGroupsWrap').removeClass('d-none')
            } else {
                $('#paddockGroupsWrap').addClass('d-none')
            }
        })

})
