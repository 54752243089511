const { initSelectpickerCustomization } = require('./scripts/bs-select-custom');


require('./bootstrap');
require('./scripts/modal-handler');
require('./scripts/property');
require('./scripts/cattle-type');
require('./scripts/settings');
require('./scripts/components-settings');
require('./scripts/report');
require('./scripts/cattle-return');
require('./scripts/cattle-forecast')
require('./scripts/cattle-costs')
require('./scripts/ledger');
require('./scripts/property-folder');
require('./scripts/profile');
require('./scripts/calendar-tasks')
require('./scripts/google-handlers')


require ('./scripts/friendship');
require ('./scripts/transfers');
require ('./scripts/cattle-database');
require ('./scripts/group');
require ('./scripts/staff');

require ('./scripts/change-order-cattle-types');
require ('./scripts/drag&drop-order-in-table');

require('./scripts/transactions')

require('./scripts/update-total-of-animals-for-admin')

require ('./scripts/report-generate');


require('./scripts/table-sticky-header');
require('./scripts/elements-binding');
// require('./scripts/table-zoom');

require('./scripts/expand-animals-details');

if(!window.location.pathname.includes('friendship')) {
    window.onload = () => {
        initSelectpickerCustomization()
    }
}
