const Log = require("laravel-mix/src/Log");

$('#addPaddock').on('show.bs.modal', function (e) {
    setTimeout(() => { $(this).find('#setPaddockName').focus(); }, 500);
});

$('#addCattleType').on('show.bs.modal', function (e) {
    setTimeout(() => { $(this).find('#setCattleTypeName').focus(); }, 500);
});

$('#addPaddockGroup').on('show.bs.modal', function (e) {
    setTimeout(() => { $(this).find('#setGroupName').focus(); }, 500);
});

const tables = document.querySelectorAll('.js-table-collapse');
const showMoreBtns = document.querySelectorAll('.js-table-show-more-rows');

if (tables) {
    tables.forEach(function (table) {
        let rows = table.querySelectorAll('table tbody tr');
        if (rows.length > 3) {
            table.classList.add('collapsed');
            table.querySelector('.js-table-show-more-rows').style.display = 'flex';
            rows.forEach(function (row, index) {
                if (index > 2) {
                    row.style.display = 'none';
                }
            });
        }
    });

    showMoreBtns.forEach(function (button) {
        button.addEventListener('click', function () {
            let parentEl = this.parentElement;
            let rows = parentEl.querySelectorAll('table tbody tr');
            if (parentEl.classList.contains('collapsed')) {
                rows.forEach(function (row, index) {
                    row.style.display = '';
                });
                parentEl.classList.remove('collapsed');
                this.querySelector('span').innerText = 'Hide';
                this.querySelector('svg').style.transform = 'rotate(180deg)';
            } else {
                rows.forEach(function (row, index) {
                    if (index > 2) {
                        row.style.display = 'none';
                    }
                });
                parentEl.classList.add('collapsed');
                this.querySelector('span').innerText = 'Show more';
                this.querySelector('svg').style.transform = 'rotate(0deg)';
            }
        });
    });
}


$('select[name="existing_bought_notes"]').on('change', function (e) {
    $(this).closest('form').trigger('submit')
})

$('select[name="existing_sold_notes"]').on('change', function (e) {
    $(this).closest('form').trigger('submit')
})
