import {blockActionsAlert} from "../actions-alerts";
import {initTransactionData} from "./init-transaction-data";

$('.js-update-action-button, .js-copy-transaction').on('click', async function (e) {
    e.preventDefault();

    const propertyId = $(this).closest('tr').find('[data-property-from-id]').data('property-from-id')

    let propertyConfirmed = true
    await axios.get(`/check-property-confirmation?propertyId=${propertyId}`)
        .then( (response) => {
            if ( response.data) {
                propertyConfirmed = false
            }
        })

    if ( !propertyConfirmed ) {
        blockActionsAlert()
        return
    }

    if ( $(this).hasClass('js-copy-transaction') ) {
        initTransactionData($(this).data('transaction'), true)
    } else {
        initTransactionData($(this).data('transaction'))
    }


})
