$('.js-operation-button').on('click', function() {
    elementAttrName = $(this).closest('tr').find('input[type="hidden"]').attr('name');
    cattleQuantity = $(this).closest('tr').find('.cattle-quantity').addClass('activeCattleType');
    $('.js-change-cattle-type-input').attr('name', elementAttrName);
    showModal($(this));
});

function showModal(el) {
    if (el.hasClass('plus')) {
        new bootstrap.Modal('#plusModal').show();
    } else if (el.hasClass('minus')) {
        new bootstrap.Modal('#minusModal').show()
    }
}

$('.js-change-cattle-type').on('click', function(e) {
    e.preventDefault();
    let textActiveElement = $('.activeCattleType'),
        typeOfOperation = $(this).data('type'),
        modalInputVal = $(this).closest('.modal-content').find('.js-change-cattle-type-input').val(),
        newValue = null;

    newValue = calculateValue(typeOfOperation, textActiveElement.text(), modalInputVal);

    if (newValue < 0 || newValue > 2147483647) {
        return;
    }
    $(this).closest('.property-update-form').trigger('submit');
    new bootstrap.Modal(e.target.closest('.modal')).hide();
});

function calculateValue(typeOfOperation, oldValue, additionalValue) {
    switch (typeOfOperation) {
        case "increase":
            return parseInt(oldValue) + parseInt(additionalValue);
        case "decrease":
            return parseInt(oldValue) - parseInt(additionalValue);
    }
}