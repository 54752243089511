window.modalValidation = function(element) {
    let elementValue = $(element).val(),
        smallText = $('.js-small-text');

    if (elementValue.length == 0) {
        smallText.html('This field is required');
        smallText.removeClass('d-none');
        $(element).addClass('is-invalid');
        return false;
    } else if (elementValue.length >= 40) {
        smallText.html('The name is too long');
        smallText.removeClass('d-none');
        $(element).addClass('is-invalid');
        return false;
    }
    smallText.addClass('d-none');
    element.removeClass('is-invalid');

    return true;
}

$('.modal').on('hide.bs.modal', function (e) {
    if(!e.target.classList.contains('datepicker')) {
        clearModal($('.js-modal-input'));
    }
})

function clearModal(inputToClear) {
    $('.js-small-text').addClass('d-none');
    $(inputToClear).removeClass('is-invalid');
    $('.js-modal-input').val('');

    $('.activeCattleType').removeClass('activeCattleType');
}
