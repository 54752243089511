import axios from "axios";
import {authSessionExpiredAlert} from "./actions-alerts";

if (window.location.pathname.includes('admin/info') ) {
    updateTotalOfAnimalsForAdmin()
}


function updateTotalOfAnimalsForAdmin()
{
    axios.get(`/admin/get-total-of-animals` )
        .then(function(response) {
            let total = response.data.total;
            const timeout = 30000

            $('.js-total-container').text(total)

            $('#companyDataTable tbody tr td:last-child').each(function (i) {
                const companyId = $(this).data('company-id')

                $(this).text(response.data.animalsByCompany[companyId].quantity)
            })

            setTimeout(() => { updateTotalOfAnimalsForAdmin(); }, timeout);
        });
}
