import axios from "axios";
import { TempusDominus } from '@eonasdan/tempus-dominus';
import moment from "moment";
import {deleteEmptyFilterOptions} from "./cattle/filter";
import {initDateTimePicker} from "./cattle/init-datetime-picker";
import {addFutureYearToSelect} from "./cattle-type/table-actions-cattle-type";

require('./cattle/add-cattle');
require('./cattle/view-edit-cattle');
require('./cattle/filter');
require('./cattle/validation');
require('./cattle/autocomplete');

require('./cattle/export-csv')
require('./cattle/import-csv')


require('./cattle/init-datetime-picker')




$(window).on('load', function(e) {
    if ( window.location.pathname === '/cattle-database'
        || window.location.pathname === '/cattle-database-search'
    ) {

        $('#filter-options .fa-calendar').each(function (i) {
            let id = $(this).closest('div').attr('id'),
                value = $(this).closest('div').find('input').val()

            initDateTimePicker(id)
            $(this).closest('div').find('input').val(value)
        })

        $('.pagination .page-item').on('click', function (e) {
            e.preventDefault()

            deleteEmptyFilterOptions()

            const qParameter = $(this).find('a').attr('href').split('?')[1]
            const pageType = qParameter.split('=')[0];
            const pageTypeValue = qParameter.split('=')[1];


            $(`#filter-options input[name="${pageType}"]`).val(pageTypeValue)
            $('#filter-options').trigger('submit')
        })

    }

});

$('body').on('change', '.js-select-paddock-for-cattle', function (e) {
    const paddockId = $(this).val()
    const instance = $(this).closest('.cattle-instance')
    const cattleTypesSelector = $(instance).find('.js-select-cattle-type-for-cattle')
    const mobsSelector = $(instance).find('.js-select-mob-for-cattle')
    const YOBSelector = $(instance).find('.js-select-yob-for-cattle')

    axios.get(`/get-data-by-paddock?paddockId=${paddockId}`)
        .then((response) => {

            let cattleTypes = ''
            response.data.cattleTypes.forEach(function (el) {
                cattleTypes += `<option value="${el.id}">${el.name} (${el.gender})</option>`
            })

            $(cattleTypesSelector).empty().append(cattleTypes).trigger('change')
            if ( paddockId === '0' ) {
                $(YOBSelector).empty().append(`<option value="0">Don't bind</option>`)
            }

            let mobs = `<option value="0">Don't bind</option>`
            response.data.mobs.forEach(function (el) {
                mobs += `<option value="${el.id}">${el.name}</option>`
            })
            $(mobsSelector).empty().append(mobs)
        })

})

$('body').on('change', '.js-select-cattle-type-for-cattle', function (e) {
    const instance = $(this).closest('.cattle-instance')
    const paddockId = $(instance).find('.js-select-paddock-for-cattle').val()
    const cattleTypeId = $(this).val()
    const mobsSelector = $(instance).find('.js-select-mob-for-cattle')
    const YOBSelector = $(instance).find('.js-select-yob-for-cattle')

    axios.get(`/get-data-by-cattle-type?paddockId=${paddockId}&cattleTypeId=${cattleTypeId}`)
        .then((response) => {

            let mobs = `<option value="0">Don't bind</option>`
            response.data.mobs.forEach(function (el) {
                mobs += `<option value="${el.id}">${el.name}</option>`
            })
            $(mobsSelector).empty().append(mobs)

            let currentYear = new Date().getFullYear();
            let cattleTypeMinYearOfBirth = response.data.minYearOfBirth;

            let yearsOfBirth = `<option value="mixed">mixed</option>`;
            yearsOfBirth += addFutureYearToSelect();
            for (let i = currentYear; i >= cattleTypeMinYearOfBirth; i--) {
                yearsOfBirth += `<option value="${i}">${i}</option>`;
            }
            $(YOBSelector).empty().append(yearsOfBirth)
        })

})

$('#view-edit-cattle').on('hide.bs.modal', function (e) {
    $('#view-edit-cattle span.js-small-text').remove();
    $('#view-edit-cattle input').removeClass('is-invalid')

})

$('#addCattle').on('click', '.uploaded-image-actions__delete', function (e) {
    let button = e.target;

    $(button).closest('.uploaded-image').remove()
})


$('#animals-per-page').on('change', function(e) {
    let perPage = $(this).val()

    deleteEmptyFilterOptions()

    $('#filter-options input[name="perPage"]').val(perPage)
    $('#filter-options').trigger('submit')
})

$(document).on('input', '#addCattle input', function(e) {
    $('.js-add-cattle').removeAttr('disabled')
})

$('#view-edit-cattle input').on('input', function(e) {
    $('.js-update-cattle').removeAttr('disabled')
})
