export default function infoTab(isFull, cattlesOptions, paddocksOptions, idx, userId) {
  return `
  <div class="modal-body text-start tab-pane active show" id="infoa${idx}" role="tabpanel" aria-labelledby="infoa${idx}-tab">
      <div class="cattle-instance container">
          <div class="row mb-2">
              <div class="col">
                  <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                      <div class="col-md-4">
                          <label for="visualId${idx}">VID*</label>
                      </div>
                      <div class="col-md-8">
                              <input
                                  id="visualId"
                                  class="form-control w-100 js-create-visual-id col-md-8"
                                  placeholder="VID"
                                  name="visual_ids[]"
                                  type="text"/>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row mb-2">
              <div class="col">
                  <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                      <div class="col-md-4">
                          <label for="animalId${idx}">EID*</label>
                      </div>
                      <div class="col-md-8">
                              <input
                                  id="animalId"
                                  class="form-control w-100 js-create-animal-id col-md-8"
                                  placeholder="EID"
                                  name="animal_ids[]"
                                  type="text"/>
                      </div>
                  </div>
              </div>
          </div>

        <div class="row mb-2 ${isFull ? '' : 'd-none'}">
              <div class="col">
                  <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                      <div class="col-md-4">
                          <label for="selectPaddock${idx}">Select a Paddock</label>
                      </div>
                      <div class="col-md-8">
                          <select name="paddock[]" id="selectPaddock${idx}" class="form-select w-100 js-select-paddock-for-cattle">
                              ${paddocksOptions}
                          </select>
                      </div>
                  </div>
              </div>
          </div>

          <div class="row mb-2 ${isFull ? '' : 'd-none'}">
              <div class="col">
                  <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                      <div class="col-md-4">
                          <label for="selectMOB${idx}">Select a MOB</label>
                      </div>
                      <div class="col-md-8">
                          <select name="cattle_mob[]" id="selectMOB${idx}" class="form-select w-100 js-select-mob-for-cattle">
                          </select>
                      </div>
                  </div>
              </div>
          </div>

          <div class="row mb-2">
              <div class="col">
                  <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                      <div class="col-md-4">
                          <label for="selectCattle${idx}">Select Cattle Type*</label>
                      </div>
                      <div class="col-md-8">
                          <select name="cattle_type[]" id="selectCattle${idx}" class="form-select w-100 js-select-cattle-type-for-cattle">
                              ${cattlesOptions}
                          </select>
                      </div>
                  </div>
              </div>
          </div>

          <div class="row mb-2 ${isFull ? '' : 'd-none'}">
              <div class="col">
                  <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                      <div class="col-md-4">
                          <label for="selectYOB${idx}">Select a Year of birth</label>
                      </div>
                      <div class="col-md-8">
                          <select name="year_of_birth[]" id="selectYOB${idx}" class="form-select w-100 js-select-yob-for-cattle">
                          </select>
                      </div>
                  </div>
              </div>
          </div>

          <div class="row mb-2">
             <div class="col">
                  <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                      <div class="col-md-4">
                          <label for="weight${idx}">Weight*</label>
                      </div>
                      <div class="col-md-8">
                          <input
                              name="weight[]"
                              type="number"
                              id="weight${idx}"
                              class="form-control w-100"
                              placeholder="Weight"
                          />
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  `
}
