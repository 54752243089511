import axios from "axios";
import {addNewEvent} from "./google-handlers";

$(window).on('load', async function (e) {
    let tasksData = (await (axios.get(`/get-users-tasks-dates`))).data.dates

    $('.datepicker-tasks').datepicker({
        assumeNearbyYear: '20',
        format: 'dd-mm-yy',
        autoclose: true,
        todayBtn: true,
        orientation: 'bottom',
        beforeShowDay: function (date) {
            const month = date.getMonth() + 1;
            const formattedMonth = month >= 10 ? month : `0${month}`;
            const formattedDay = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;

            const formattedDate = `${date.getFullYear()}-${formattedMonth}-${formattedDay}`;

            if ( tasksData.includes(formattedDate) ) {
                return 'js-date-with-task'
            }
        }
    }).on('changeDate', async function(event) {
        const date = event.date;
        const month = date.getMonth() + 1;
        const formattedMonth = month >= 10 ? month : `0${month}`;
        const formattedDay = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;

        let formattedDate = `${date.getFullYear()}-${formattedMonth}-${formattedDay}`;

        $('#calendarTasks').offcanvas('show')
        $('#calendarTasks #calendarTasksLabel span').text(formattedDate)
        $('#calendarTasks .js-task-date').val(formattedDate)

        let tasks = (await (axios.get(`/get-choosen-date-tasks?date=${formattedDate}`))).data.tasks

        let tasksHtml = ''
        tasks.forEach(function (el) {
            tasksHtml += `<li class="list-group-item position-relative" data-id="${el.id}">${el.task_text}<span class="js-delete-task-button">x</span></li>`
        })

        $('.js-tasks-list').empty().append(tasksHtml)

    })
})

$('.js-add-new-task-button').on('click', function (e) {
    $(this).addClass('d-none')
    $(this).next('form').removeClass('d-none')
})

$(document).on('click', '.js-delete-task-button', function (e) {
    const taskId = $(this).closest('li').data('id')

    axios.get(`/remove-task?taskId=${taskId}`)
        .then( () => {
            $(this).closest('li').remove()
        })

})

$('#createNewTask').on('click', async function (e) {
    e.preventDefault()

    const form = $(this).closest('form')

    const summary = $(form).find('[name="task_summary"]').val()
    const description = $(form).find('[name="task_text"]').val() + $(form).find('[name="paddock"] option:selected').text()
    const date = $(form).find('[name="task_date"]').val()

    let googleAccessToken = $('body').data('google-token')

    if (googleAccessToken !== null) {

        await axios.get(`/check-google-token-for-expiration?token=${googleAccessToken}`)
            .then(async (response) => {
                googleAccessToken = response.data.newToken

                addNewEvent(summary, description, date, googleAccessToken)
            })


    } else {
        $(form).trigger('submit')
    }


})
