const transactionsRemoved = new Event('transactions-removed');

$('.js-remove-action-instance-in-table').on('click', function (e) {
    e.preventDefault();

    const actions = $('.js-report-table .action-in-table').length;

    if ( actions === 1 ) {
        $('.js-close-add-transaction-table').trigger('click');
    } else {
        $('.js-report-table .action-in-table:last').remove();
    }
});

$('.js-close-add-transaction-table').on('click', function(e) {
    const isReportsPage = window.location.pathname === '/reports'

    $('.js-update-action-button, .js-delete-transaction, .js-copy-transaction').removeClass('d-none')

    if ( isReportsPage ) {
        $(this).closest('div').addClass('d-none')
    }

    closeCreateActionsTable()
    document.dispatchEvent(transactionsRemoved);
})


$('.js-close-add-transaction-table, .js-remove-action-instance-in-table').on('click', () => {

    closeUpdate($(this));
})

function closeUpdate(button)
{
    $('.js-remove-action-instance-in-table, .js-close-add-transaction-table').removeAttr('data-update')

    $('.js-add-action-instance-in-table').removeAttr('disabled');
    $('.js-remove-action-instance-in-table').html('<i class="bi bi-dash me-2"></i>Remove Transaction');
    $('.js-actions-save-in-table').text('Save');
}

function closeCreateActionsTable() {
    $('.js-report-table-controls, .js-add-transaction-first, .js-report-table thead tr:first').removeClass('d-none')
    $('.js-transactions-control').addClass('d-none');

    $('.js-report-table thead tr:last').remove();
    $('.js-report-table .action-in-table').remove();
    $('.js-report-table tbody tr[data-transaction-id] td.shown-hidden').addClass('d-none').removeClass('show-hidden')
}
