import axios from "axios";

$('#groupByProperty').on('change', function() {
    const propertyId = $(this).val(),
        paddockSelector = $('#groupByPaddock')

    if ( $(this).val() === "all" ) {
        $(paddockSelector).html('');
    } else {
        axios.get(`/property/getPropertyDataAjax/${propertyId}`)
            .then(function(response) {
                let optionsTags = '<option value="all">All</option>';
                for (let i = 0; i < response.data.paddocks.length; i++) {
                    optionsTags += `<option value="${response.data.paddocks[i].id}">${response.data.paddocks[i].name}</option>`
                }

                $(paddockSelector).empty().append(optionsTags);
            });
    }

});
