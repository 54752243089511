import {blockActionsAlert} from "../actions-alerts";

$('.js-delete-transaction').on('click', async function(e) {
    e.preventDefault();

    const propertyId = $(this).closest('tr').find('[data-property-from-id]').data('property-from-id')
    let propertyConfirmed = true
    await axios.get(`/check-property-confirmation?propertyId=${propertyId}`)
        .then( (response) => {
            if ( response.data ) {
                propertyConfirmed = false
            }
        })

    if ( !propertyConfirmed ) {
        blockActionsAlert()
        return
    }


    if ( !confirm('Are you sure?') ) {
        return;
    }

    const transactionId = $(this).data('transaction')

    let formData = new FormData()

    formData.append('isDelete', true)
    formData.append('transaction_id', transactionId)

    $.ajax({
        'type': 'POST',
        'url': '/cattle-type/action',
        'data': formData,
        async: false,
        processData: false,
        contentType: false,
        dataType: "json",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        'success': function (data) {

            if ( data.status && data.status === 'success' ) {
                $('#managedViewTable, #inviteModal').before(`<div class="alert alert-success alert-dismissible fade show">\n` +
                    `        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>\n` +
                    `        <strong>${data.message}</strong>\n` +
                    `    </div>`)

                setTimeout(() => {
                    location.reload();
                }, 3000)
            } else {
                $('#managedViewTable, #inviteModal').before(`<div class="alert alert-danger alert-dismissible fade show">\n` +
                    `        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>\n` +
                    `        <strong>${data.message}</strong>\n` +
                    `    </div>`)

                setTimeout(() => {
                    $('.alert-danger').remove()
                }, 3000)
            }

        },
    });

})
