export function initCreateActionsTable() {
    const actionsTableHeader = $('.js-add-transactions-table thead').html();

    $('.js-report-table-controls, .js-add-transaction-first, .js-report-table thead tr').addClass('d-none')
    $('.js-transactions-control').removeClass('d-none');

    $('.js-report-table thead').append(actionsTableHeader);
    $('.js-report-table tbody tr[data-transaction-id] td.d-none').addClass('shown-hidden').removeClass('d-none')

}
