export default function carcassTab(idx) {
  return `
    <div class="modal-body text-start tab-pane" id="carcassa${idx}" role="tabpanel" aria-labelledby="carcassa${idx}-tab">
      <div class="container">
        <div class="row mb-2">
          <div class="col">
            <div class="row align-items-center bg-gray-primary rounded-2 py-2">
              <div class="col-md-4">
                <label for="barcode${idx}">Barcode</label>
              </div>
              <div class="col-md-8">
                <input
                  name="barcode[]"
                  type="text"
                  id="barcode${idx}"
                  class="form-control w-100"
                  placeholder="Barcode"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <div class="row align-items-center bg-gray-primary rounded-2 py-2">
              <div class="col-md-4">
                <label for="timetaken${idx}">Timetaken</label>
              </div>
              <div class="col-md-8">
                <div class="input-group" id="datetimepicker${idx}" data-td-target-input="nearest" data-td-target-toggle="nearest">
                  <input name="timetaken[]" id="timetaken${idx}" type="text" class="form-control" 
                    data-td-target="#datetimepicker${idx}" placeholder="dd-mm-yy hh:mm">
                  <span class="input-group-text" data-td-target="#datetimepicker${idx}" data-td-toggle="datetimepicker">
                    <i class="fas fa-calendar"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <div class="row align-items-center bg-gray-primary rounded-2 py-2">
              <div class="col-md-4">
                <label for="body${idx}">Body</label>
              </div>
              <div class="col-md-8">
                <input
                  name="body[]"
                  type="text"
                  id="body${idx}"
                  class="form-control w-100"
                  placeholder="Body"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <div class="row align-items-center bg-gray-primary rounded-2 py-2">
              <div class="col-md-4">
                <label for="hscw${idx}">HSCW</label>
              </div>
              <div class="col-md-8">
                <input
                  name="hscw[]"
                  type="text"
                  id="hscw${idx}"
                  class="form-control w-100"
                  placeholder="HSCW"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <div class="row align-items-center bg-gray-primary rounded-2 py-2">
              <div class="col-md-4">
                <label for="marblingpercent${idx}">Marbling percent</label>
              </div>
              <div class="col-md-8">
                <input
                  name="marblingpercent[]"
                  type="text"
                  id="marblingpercent${idx}"
                  class="form-control w-100"
                  placeholder="Marbling Percent"
                />
              </div>
            </div>
          </div>
        </div>
            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="mbmarble${idx}">MB marble</label>
                        </div>
                        <div class="col-md-8">
                            <input
                                name="mbmarble[]"
                                type="text"
                                id="mbmarble${idx}"
                                class="form-control w-100"
                                placeholder="MB Marble"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="ausmeatmarble${idx}">Ausmeat marble</label>
                        </div>
                        <div class="col-md-8">
                            <input
                                name="ausmeatmarble[]"
                                type="text"
                                id="ausmeatmarble${idx}"
                                class="form-control w-100"
                                placeholder="Ausmeat Marble"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="msamarble${idx}">MSA marble</label>
                        </div>
                        <div class="col-md-8">
                            <input
                                name="msamarble[]"
                                type="text"
                                id="msamarble${idx}"
                                class="form-control w-100"
                                placeholder="MSA Marble"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="fineness${idx}">Fineness</label>
                        </div>
                        <div class="col-md-8">
                            <input
                                name="fineness[]"
                                type="text"
                                id="fineness${idx}"
                                class="form-control w-100"
                                placeholder="Fineness"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="distribution${idx}">Distribution</label>
                        </div>
                        <div class="col-md-8">
                            <input
                                name="distribution[]"
                                type="text"
                                id="distribution${idx}"
                                class="form-control w-100"
                                placeholder="Distribution"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="meatcolour${idx}">Meat colour</label>
                        </div>
                        <div class="col-md-8">
                            <input
                                name="meatcolour[]"
                                type="text"
                                id="meatcolour${idx}"
                                class="form-control w-100"
                                placeholder="Meat colour"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="fatcolour${idx}">Fat colour</label>
                        </div>
                        <div class="col-md-8">
                            <input
                                name="fatcolour[]"
                                type="text"
                                id="fatcolour${idx}"
                                class="form-control w-100"
                                placeholder="Fat colour"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="eyemusclearea${idx}">Eye muscle area</label>
                        </div>
                        <div class="col-md-8">
                            <input
                                name="eyemusclearea[]"
                                type="text"
                                id="eyemusclearea${idx}"
                                class="form-control w-100"
                                placeholder="Eye muscle area"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="marblingcount${idx}">Marbling count</label>
                        </div>
                        <div class="col-md-8">
                            <input
                                name="marblingcount[]"
                                type="text"
                                id="marblingcount${idx}"
                                class="form-control w-100"
                                placeholder="Marbling count"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="cutlocation${idx}">Cut location</label>
                        </div>
                        <div class="col-md-8">
                            <input
                                name="cutlocation[]"
                                type="text"
                                id="cutlocation${idx}"
                                class="form-control w-100"
                                placeholder="Cut location"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="plant_name${idx}">Plant name</label>
                        </div>
                        <div class="col-md-8">
                            <input
                                name="plant_name[]"
                                type="text"
                                id="plant_name${idx}"
                                class="form-control w-100"
                                placeholder="Plant name"
                                maxlength="20"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                  <div class="col-md-4">
                    <label for="plant_location${idx}">Plant location</label>
                  </div>
                  <div class="col-md-8">
                    <input
                      name="plant_location[]"
                      type="text"
                      id="plant_location${idx}"
                      class="form-control w-100"
                      placeholder="Plant location"
                      maxlength="20"
                    />
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  `;
}