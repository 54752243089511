import {default as axios} from "axios";

$('.js-report-table').on('click', '.js-animals-list-btn', function () {
    const elAction = $(this).closest('.action-in-table');
    const chosenAction = elAction.find('.js-action-select-in-table').val();
    const elList = $('#animals .js-cattles-list');

    elAction.addClass('active');

    if (chosenAction === 'Bought' || chosenAction === 'Brand') {
        insertUnallocatedCattles(elList);
    } else {
        insertAvailableCattles(elList)
            .then(() => {
                return new Promise((resolve, reject) => resolve());
            });
    }
});

/**
 * @param  {HTMLUListElement} el has class .js-cattles-list
 */
export function insertUnallocatedCattles(el) {
    let cattleTypeId = '';
    if ( el.closest('#animals').length > 0 ) {
        const index = $('.action-in-table.active').data('index');
        cattleTypeId = $('.action-in-table.active').find(`select[name="current_cattle_type_id[${index}][]"]:not([disabled])`).val();

    }

    axios.get(`/unallocated-cattles/${cattleTypeId}`)
        .then(response => {
            insertHtmlContent(el, response.data.data);
        })
}

/**
 * @param  {HTMLUListElement} el has class .js-cattles-list
 */
export function insertAvailableCattles(el) {
    let cattleTypeId = '',
        propertyId = '',
        yearOfBirth = '',
        paddockId = '',
        action = '';

    if ( el.closest('#animals').length > 0 ) {
        action = $('.action-in-table.active').find('select[name="action[]"]').val();
        if ( action === 'Transfer In' ) {
            propertyId = $('.action-in-table.active td[data-property-from-id] .js-property-block-for-transfer-in').find('select').val();
            paddockId = 'receive';
        } else {
            propertyId = $('input[name="property_id"]:last').val();
            paddockId = $('.action-in-table.active').find('input[name="current_paddock_id"]').val();
        }

        const index = $('.action-in-table.active').data('index');
        cattleTypeId = $('.action-in-table.active').find(`select[name="current_cattle_type_id[${index}][]`).val();
        yearOfBirth = $('.action-in-table.active').find('.js-select-year-of-birth').val();
        if ( $('.action-in-table.active').find('.js-action-select').val() === 'Transfer In' ) {
            propertyId = $('.action-in-table.active').find('select[name="updated_property_id[]"]').val();
            paddockId = $('.action-in-table.active').find('select[name="updated_paddock_id[]"]').val();
        }

    }

    return new Promise((resolve, reject) => {

        axios.get(`/available-cattles?property_id=${propertyId}&cattle_type_id=${cattleTypeId}&paddock_id=${paddockId}&year_of_birth=${yearOfBirth}`)
            .then(response => {
                insertHtmlContent(el, response.data.data);
                resolve();
            })
            .catch(response => {
                insertHtmlContent(el, []);
                resolve();
            });
    });
}

/**
 * @param  {HTMLUListElement} el has class .js-cattles-list
 */
export function insertHtmlContent(el, items) {
    let htmlCattlesList = '';
    if ( items.length === 0 ) {
        htmlCattlesList = 'Related cattles were not found';
    } else {
        let indexOfAction = el.closest('.select-action-box').attr('data-index') !== undefined
            ? el.closest('.select-action-box').attr('data-index')
            : 0;

        for (let cattle of items) {
            htmlCattlesList += `
                <li class="list-group-item list-group-item d-flex justify-content-between align-items-center">
                    <input type="checkbox" class="js-checked-cattles" name="cattlesIds[${indexOfAction}][]" value="${cattle.id}"/>
                    <i class="text-primary">VID: </i><span>${cattle.visual_id}</span>
                    <i class="text-primary">AID: </i><span>${cattle.animal_id}</span>
                    <i class="text-primary">W: </i><span>${cattle.weight}</span>
                </li>
            `;
        }
    }
    $(el).html(htmlCattlesList);
}

$('#animals').on('hide.bs.modal', function () {
    $('#animals .js-cattles-list').html('');
    $('.action-in-table.active').removeClass('active');
})

const animalsModal = document.getElementById('animals') !== null
    ? new bootstrap.Modal(document.getElementById('animals'))
    : null;

$('.js-save-animals-in-table').on('click', function (e) {
    e.preventDefault();
    clearOldAnimals();

    let inputsString = '';
    let indexOfAction = $('.action-in-table.active').attr('data-index');

    $('#animals .js-cattles-list li input:checked').each(function () {
        let idOfAnimal = $(this).val();

        inputsString += `<input type="hidden" name="cattlesIds[${indexOfAction}][]" value="${idOfAnimal}" form="actionsFormInTable" data-animals-ids/>\n`
    });

    $('.action-in-table.active').append(inputsString);

    if ( inputsString !== '' ) {
        $('.action-in-table.active').find('input[name="mob[]"]').attr('disabled', 'disabled');
    } else {
        $('.action-in-table.active').find('input[name="mob[]"]').removeAttr('disabled');
    }

    animalsModal.hide();
});

function clearOldAnimals() {

    if ( $('.action-in-table.active').find('[data-animals-ids]').length > 0 ) {
        $('.action-in-table.active').find('[data-animals-ids]').remove();
    }

}
