
import {initialAutocomplete} from './autocomplete';
import {setInvalid} from "./add-cattle";
import {
    IDsValidation,
    onlyLengthValidation,
    parentsValidation,
    timeTakenValidation,
    weightValidation
} from "./validation";
import {initDateTimePicker} from "./init-datetime-picker";

$('#js-add-new-filter-option').on('click', function(e) {
    e.preventDefault()


    const selectHtml = $(this).closest('div').find('select').prop('outerHTML')
    const inputHTML = '<input class="form-control me-sm-2 w-auto" name="search_id" type="text" placeholder="Value" value="" disabled="">'
    const deleteButtonHtml = '<button class="btn btn-outline-danger my-2 my-sm-0 js-delete-filter-option" style="margin-left: 5px">Delete</button>'

    const newFilterLineHTML = `<div class="d-flex mb-1 js-filter-option">${selectHtml}${inputHTML}${deleteButtonHtml}</div>`

    $('#filter-options .js-filter-option:last').after(newFilterLineHTML)
    $('.js-filter-options:last').val('0')
})

$('#filter-options').on('click', '.js-delete-filter-option', function(e) {
    $(this).closest('.js-filter-option').next('.js-filter-option-error').remove()
    $(this).closest('div').remove()
})

$('#filter-options').on('change', '.js-filter-options', async function(e) {
    const fieldType = $(this).val()
    let valueFieldHTML = ''

    $(this).next('div, input, select').remove()
    switch(fieldType) {
        case '0':
            valueFieldHTML = '<input class="form-control me-sm-2 w-auto" type="text" placeholder="Value" value="" disabled/>'

            break
        case 'time_taken':
            const dateTimePickerIndex = $('#filter-options select').length + 1
            valueFieldHTML = `<div class="input-group" id="datetimepicker0${dateTimePickerIndex}" data-td-target-input="nearest" data-td-target-toggle="nearest">` +
                                `<input name="option_value[]" type="text" class="form-control" data-td-target="#datetimepicker0${dateTimePickerIndex}" placeholder="dd-mm-yy hh:mm">` +
                                `<span class="input-group-text" data-td-target="#datetimepicker0${dateTimePickerIndex}" data-td-toggle="datetimepicker">` +
                                `<i class="fas fa-calendar"></i>` +
                                `</span>` +
                            `</div>`

            $(this).after(valueFieldHTML)

            initDateTimePicker(`datetimepicker0${dateTimePickerIndex}`);
            break;
        case 'weight':
        case 'entry_weight':
        case 'exit_weight':
        case 'feed_days':
        case 'hscw':
            valueFieldHTML = '<input name="option_value[]" class="form-control w-25" type="number">'
            break;
        case 'cattle_type_id':
            valueFieldHTML = $('#filterByCattleType').prop('outerHTML')
            break;
        case 'dad':
        case 'mom':
        case 'children':
            const optionIndex = $('#filter-options .js-filter-options').length

            valueFieldHTML = `<input id="option_value${optionIndex}" name="option_value[]" class="form-control w-25" type="text">`
            break;
        default:
            valueFieldHTML = '<input name="option_value[]" class="form-control w-25" type="text">'
            break;
    }

    if (fieldType !== 'time_taken') {
        $(this).after(valueFieldHTML)

        if( $('#filter-options #filterByCattleType').length > 0) {
            $('#filter-options #filterByCattleType').removeClass('d-none').removeAttr('disabled')
        }

        if ( fieldType === 'dad' || fieldType === 'mom' || fieldType === 'children') {
            let cattleData = await axios.get(`/get-cattle-data?search=yes`);
            initialAutocomplete(`#filter-options .js-filter-option:last input`, cattleData.data.cattle);
        }
    }


})

$('#filter-options button[type="submit"]').on('click', async function(e) {
    e.preventDefault()

    $('#filter-options').find('.js-filter-option-error').remove();
    $('#filter-options').find('.is-invalid').removeClass('is-invalid');

    await optionsValidate()

    if ( $('#filter-options').find('.js-filter-option-error').length === 0 ) {
        deleteEmptyFilterOptions()

        $('#filter-options').trigger('submit')
    }

})

async function optionsValidate()
{
    $('#filter-options .js-filter-options').each(async function (i) {
        let field = $(this).val()

        switch (field) {
            case 'animal_id':
            case 'visual_id':
                IDsValidation($(this).next('input'), '/unique-field?', true)
                break
            case 'weight':
                weightValidation($(this).next('input'), true)
                break
            case 'time_taken':
                timeTakenValidation($(this).closest('div').find('input'))
                break
            case 'hscw':
                onlyLengthValidation($(this).next('input'), 4, true)
                break
            case 'entry_weight':
            case 'exit_weight':
            case 'feed_days':
                onlyLengthValidation($(this).next('input'), 7, true)
                break
            case 'pre_feedlot':
            case 'feedlot':
            case 'kill':
            case 'sales':
            case 'marketing':
                onlyLengthValidation($(this).next('input'), 6)
                break
            case 'lwt':
            case 'hscw2':
                onlyLengthValidation($(this).next('input'), 5)
                break
            case 'plant_name':
            case 'plant_location':
                onlyLengthValidation($(this).next('input'), 20)
            case 'dad':
            case 'mom':
            case 'children':
                //parentsValidation($(this).closest('div').find('input'))
                let animal = $(this).closest('div').find('input'),
                    requestString = '/unique-field?'
                // await axios.get(requestString + `field=visual_id&value=${animal.val()}`)
                //     .then(response => {
                //         if (response.data.status === 'success') {
                //             setInvalid(animal, 'There is no cattle with this VID');
                //         }
                //     });

                break
            default:
                onlyLengthValidation($(this).next('input'), 30)
        }
    })

}

export function deleteEmptyFilterOptions()
{
    $('#filter-options .js-filter-option').each(function (i) {
        if ( $(this).find('.js-filter-options').val() === '0' ) {
            $(this).find('.js-filter-options').attr('disabled', '')
        }

        if ( $(this).find('input').val() === '' ) {
            $(this).find('.js-filter-options').attr('disabled', '')
            $(this).find('input').attr('disabled', '')
        }

        if ( $(this).find('select:last').val() === '0' ) {
            $(this).find('.js-filter-options').attr('disabled', '')
            $(this).find('select:last').attr('disabled', '')
        }


    })
}
