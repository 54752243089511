export default function progenyTab(idx, isShow) {
  if (isShow !== 0) {
    return `
      <div class="modal-body text-start tab-pane" id="progenya${idx}" role="tabpanel" aria-labelledby="progenya${idx}-tab">
        <div class="container">
          <div class="row mb-2">
            <div class="col">
              <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                <div class="col-md-4">
                  <label for="weight">Select Progeny</label>
                </div>
                <div class="col-md-8">
                  <div>
                    <input id="children${idx}" class="form-control cattle-search w-auto" placeholder="Search for Cattle..." 
                      autocomplete="off" name="children[${idx}][]" type="text" value="" aria-controls="autoComplete_list_3" 
                      aria-autocomplete="both">
                    <span class="me-auto">
                      <i class="fas fa-minus h5 p-1 js-remove-progeny" style="cursor: pointer"></i>
                      <i class="fas fa-plus h5 p-1 js-add-progeny" style="cursor: pointer"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  } else {
    return ''
  }
}