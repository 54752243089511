$('.datepicker-monthly:first').on('changeDate', (e) => {

    let newDateFrom = getFormattedDate(e);
    const dateTo = $('.datepicker-monthly:last').val();


    if( new Date(newDateFrom.split('-')[1], newDateFrom.split('-')[0]) > new Date(dateTo.split('-')[1], dateTo.split('-')[0]) ) {
        $('.datepicker-monthly:last').val(newDateFrom)
    }

    $('.month-select-block button').trigger('click');
    $('#cattleReturnLoader').modal('show')

})

$('.datepicker-monthly:last').on('changeDate', (e) => {
    const dateFrom = $('.datepicker-monthly:first').val();
    const dateTo = getFormattedDate(e);

    $('.datepicker-monthly:first').val(dateTo)

    $('.month-select-block button').trigger('click');
    $('#cattleReturnLoader').modal('show')
});

function getFormattedDate(e)
{
    const month = e.date.getMonth() + 1;
    const formattedMonth = month >= 10 ? month : `0${month}`;
    const year = e.date.getFullYear()

    return formattedMonth + '-' + year;
}
