import axios from "axios";

if ( window.location.pathname.includes('reports') ) {
    let params = (new URL(document.location)).searchParams;

    if ( params.get('companyId') ) {
        axios.get('/check-sma-for-company?companyId=' + params.get('companyId'), )
            .then(response => {
                if ( response.data.company_id ) {
                    window.location = window.location.origin + '/' + 'switch-company/' + response.data.company_id;
                }
            });
    }

}
