$('.js-hide-empty-ages').on('change', function(e) {
    const switchState = this.checked
    //$('#cattleReturnLoader').modal('show')
    if (switchState) {
        $('tr[data-is-empty="1"]').addClass('d-none')
    } else {
        $('tr[data-is-empty="1"]').removeClass('d-none')
    }

    $('tr[data-cattle-type-id]').each(function(i) {
        const cattleTypeId = $(this).data('cattle-type-id')

        const rows = $(`[data-cattle-type-age-row="${cattleTypeId}"]:not(.d-none)`).length
        $(this).find('td').attr('rowspan', rows + 1)
    })

    $('tr[data-cattle-type-group-id]').each(function(i) {
        const cattleTypeGroupId = $(this).data('cattle-type-group-id')

        const rows = $(`[data-cattle-type-group-age-row="${cattleTypeGroupId}"]:not(.d-none)`).length
        $(this).find('th').attr('rowspan', rows + 1)
    })

})
