import axios from "axios";
import {setInvalid} from "./add-cattle";


export function IDsValidation(idField, requestString = '/unique-field?', isSearch = false)
{
    if (!isSearch && idField.val() === '') {
        setInvalid(idField);
        return false;
    }
    else if (idField.val().length > 16) {
        setInvalid(idField, 'This field must not be greater than 16 characters');
        return false;
    }
    else if (!isSearch && idField.val() < 0) {
        setInvalid(idField, 'This field must be greater than 0');
        return false;
    }
    else if (!isSearch) {
        //request =
            axios.get(requestString + `field=animal_id&value=${idField.val()}`)
            .then(response => {
                if (response.data.status === 'error') {
                    setInvalid(idField, 'This field should be unique');
                    return false;
                }
            });
        //promises.push(request);
    }

    return true;
}

export function weightValidation(weight, isSearch = false)
{
    if ( !isSearch && weight.val() === '') {
        setInvalid(weight);
        return false;
    }
    else if (weight.val().length > 7) {
        setInvalid(weight, 'This field must not be greater than 7 characters');
        return false;
    }
    else if (weight.val() < 0) {
        setInvalid(weight, 'This field must be greater than 0');
        return false;
    }

    return true
}

export function onlyLengthValidation(field, maxLength, isNumberField = false)
{
    if ( field.length !== 0 && field.val().length > maxLength ) {
        setInvalid(field, `This field must not be greater than ${maxLength} characters`);
        return false;
    }

    if ( isNumberField ) {
        if ( field.length !== 0 && field.val() < 0) {
            setInvalid(field, 'This field must be greater than 0');
            return false;
        }
    }

    return true
}

export function timeTakenValidation(elTimeTaken)
{
    if ( elTimeTaken.val() !== '' && (!elTimeTaken.val().includes('-') || !elTimeTaken.val().includes(':')) ) {
        setInvalid(elTimeTaken, 'Incorrect date format', true);
        return false;
    }

    return true
}

export function parentsValidation(animal, requestString = '/unique-field?')
{
    let result = true
    axios.get(requestString + `field=visual_id&value=${animal.val()}`)
                .then(response => {
                    if (response.data.status === 'success') {
                        setInvalid(animal, 'There is no cattle with this VID');

                        result = false;
                    }
                });   
                
    return result

}