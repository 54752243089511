import axios from "axios";

$('td[data-female-status] a').on('click', function (e) {
    e.preventDefault()

    const statusContainer = $(this).closest('td').find('span')
    const currentStatus = $(statusContainer).text()

    $(this).addClass('d-none')

    $(statusContainer).addClass('d-none')
    $(statusContainer).after(`<div class="d-flex align-items-center justify-content-center gap-1"><input maxlength="8" style="min-width: 8em; max-width: 8em;" class="form-control" type="text" value="${currentStatus}"> <a><i class="fas fa-check text-success js-save-status"></i></a></div>`)

})

$('body').on('click', '.js-save-status', function (e) {
    e.preventDefault()

    const transactionId = $(this).closest('tr').data('transaction-id')
    const newStatus = $(this).closest('div').find('input').val()

    $(this).closest('td').find('a, .js-change-female-status').removeClass('d-none')

    $(this).closest('td').find('span').text(newStatus).removeClass('d-none')
    $(this).closest('div').remove()

    axios.get(`/update-female-status?transactionId=${transactionId}&status=${newStatus}`)

})

$('.js-switch-female-status').on('change', function (e) {
    const transactionId = $(this).closest('tr').data('transaction-id')
    const switchState = this.checked

    axios.get(`/switch-female-status?transactionId=${transactionId}&state=${switchState}`)
})
