$('.js-add-new-email-for-staff-request').on('click', function(e) {

    let inputCount = $('#inviteModal input[name="email[]"]').length;
    let inputHtml = $('#inviteModal input[name="email[]"]:first').prop('outerHTML');

    if ( inputCount === 10 ) {
        return;
    }

    if ( inputCount === 1 ) {
        $(this).after(inputHtml);
    } else {
        $('#inviteModal input[name="email[]"]:last').after(inputHtml);
    }

});
