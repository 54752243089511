
import { updateReportsAlert } from "../actions-alerts";

let confirmStatus = $('.table.js-ledger-table.mb-1.table-head-sticky').attr('data-confirm-status');
let updateStatus = $('.table.js-ledger-table.mb-1.table-head-sticky').attr('data-update-status');
let actionIsDone = $('.alert.alert-success.alert-dismissible.fade.show').length != 0;
const lastTransactionCattleType = $('.js-report-table tbody tr:first [data-cattle-type-from]').data('cattle-type-from')
const lastAction = $('.js-report-table tbody tr:first [data-action]').data('action')
const isLastCattleTypeAffect = $(`.js-ledger-table thead tr:first th[data-current-cattle-type-id=${lastTransactionCattleType}]`).data('cattle-return-affect');
const isLastCattleTypeAffectReclass = $(`.js-ledger-table thead tr:first th[data-current-cattle-type-id=${lastTransactionCattleType}]`).data('cattle-return-affect-reclass');

if ( confirmStatus == 1 && updateStatus == 1 && actionIsDone && (isLastCattleTypeAffect || (lastAction === 'Reclass' && isLastCattleTypeAffectReclass) ) ) {
    updateReportsAlert()
}




$('.js-report-table').on('mousedown', '.js-action-select-cattle-type option[value="all"]', function (e) {
    const option = e.target;
    const $select = $(option).parent();
    const isOptionSelected = $(option).parent().val()[0] === $(option).attr('value');

    if ( isOptionSelected ) {
        e.preventDefault();
        $select.val('');
        $select.find('option').removeAttr('disabled');
        $select.find('option:not(:first-child)').css('background', '');
    }
})




export function addFutureYearToSelect()
{
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    if ( currentMonth >= 9 && currentMonth <= 12 ) {
        return `<option value="${currentYear + 1}">${currentYear + 1}</option>`;
    }
}



$('.modal#ms, .modal#sma, .modal#note, .modal#mob, .modal#purchase_note').on('mousedown', function (e) {
    const target = e.target;
    const input = $(target).closest('.modal').find('input:not([disabled]), select:not([disabled])');

    if (
        $(target).text() === 'Close'
        || $(target).hasClass('modal')
        || $(target).hasClass('btn-close')
    ) {
        $(input).val('');

        $('.modal#ms, .modal#sma, .modal#note').find('input').attr('disabled', 'disabled');
        setTimeout(() => {
            $('.action-in-table').removeClass('current');
        }, 500)
    } else if ( $(target).text() === 'Save' ) {

        switch ($(this).attr('id')) {
            case 'mob':

                $('.action-in-table.current [name="mob[]"]').val($('.modal#mob select').val());

                break;
            case 'ms':
                $('.action-in-table.current [name="ms_number"]').val($(input).val());
                break;
            case 'sma':
                const nvd = $(target).closest('.modal').find('input#nvd').val()
                const decks = $(target).closest('.modal').find('input#decks').val()
                const transport = $(target).closest('.modal').find('select:first').val()
                const agent = $(target).closest('.modal').find('select:last').val()
                const smaNote = $(target).closest('.modal').find('#sma_note').val()

                $('.action-in-table.current [name="sma[]"]').val($(input).val());
                $('.action-in-table.current [name="nvd[]"]').val(nvd);
                $('.action-in-table.current [name="decks[]"]').val(decks);
                $('.action-in-table.current [name="transport[]"]').val(transport).change();
                $('.action-in-table.current [name="agent[]"]').val(agent).change();
                $('.action-in-table.current [name="sma_note[]"]').val(smaNote);
                break;
            case 'note':
                const note = $(target).closest('.modal').find('input[name="note_in_modal"]').val()
                const headPrice = $(target).closest('.modal').find('input[name="price_in_modal"]').val()

                $('.action-in-table.current [name="note[]"]').val(note);
                $('.action-in-table.current [name="head_price[]"]').val(headPrice);
                break;
            case 'purchase_note':
                $('.action-in-table.current [name="purchased_from[]"]').val($(input).first().val());
                break;

        }

        if ( $(this).attr('id') !== 'mob' ) {
            $(input).val('');
        }

        $('.modal#ms, .modal#sma, .modal#note').find('input').attr('disabled', 'disabled');
        setTimeout(() => {
            $('.action-in-table').removeClass('current');
        }, 500)
    }
});



$('.js-report-table').on('click', '.js-edit-note-in-table, .js-edit-ms-in-table, .js-edit-sma-in-table, .js-edit-mob-in-table, .js-edit-purchase_notes-in-table', async function (e) {
    setTimeout(() => {
        $(this).closest('tr').addClass('current');

        if ( $(this).hasClass('js-edit-note-in-table') ) {
            $('.modal#note input').trigger('focus')
        }
    }, 500);

    const currentAction = $(this).closest('tr').find('.js-action-select-in-table').val();

    const currentMobInput = $(this).closest('tr').find('[name="mob[]"]')


    let currentMobValue = currentMobInput.val() !== '' ? currentMobInput.val() : 0

    const isDisabled = Boolean(currentMobInput.attr('disabled'))


    if ( isDisabled ) {
        $('.modal-mob select').attr('disabled', 'disabled')
    } else {
        $('.modal-mob select').removeAttr('disabled');

        const propertyId = currentAction === 'Transfer In' ? $(this).closest('tr').find('.js-property-block-for-transfer-in [name="updated_property_id[]"]').val() : $('input[name="property_id"]:last').val()
        const isUpdate =  $(this).closest('tr').find('input[name="transaction_id"]').length !== 0


        let transactionQuery = '';
        if ( isUpdate ) {
            const transaction = $(this).closest('tr').find('input[name="transaction_id"]').val();
            transactionQuery = `&transaction=${transaction}`
        }

        if (
            currentAction === 'Sold'
            || currentAction === 'Mortality'
            || isUpdate
        ) {
            transactionQuery += '&withTrash=true';
        }

        if ( currentAction === 'Transfer In' && isUpdate ) {
            transactionQuery += '&transferIn=true';
        }

        await axios.get(`/get-property-mobs?property_id=${propertyId}${transactionQuery}`)
            .then(response => {
                $('.modal-mob select option').remove();

                let mobs = response.data.mobs;

                for (const prop in mobs) {
                    $('.modal-mob select').append(`<option value="${prop}">${mobs[prop]}</option>`)
                }

            });

        $('.modal-mob select').val(currentMobValue)

    }


    if ( currentAction !== '0' ) {
        $('.modal#ms, .modal#sma, .modal#note, .modal#purchase_note').find('input, select').removeAttr('disabled');

        if (
            currentAction !== 'Bought'
            && currentAction !== 'Sold'
            && currentAction !== 'Transfer In'
            && currentAction !== 'Transfer Out'
        ) {
            $('.modal#sma').find('input').removeAttr('disabled', '');
        }

        if ( $(this).closest('tr').data('index') !== 0 ) {
            $('.modal#ms').find('input').attr('disabled', '');
        }


        if ( !$(this).hasClass('js-edit-purchase_notes-in-table') ) {

            if ( $(this).hasClass('js-edit-note-in-table') ) {
                const note = $(this).closest('td').find('input[name="note[]"]').val()
                const headPrice = $(this).closest('td').find('input[name="head_price[]"]').val()

                setTimeout(() => {
                    $('.modal.show input[name="note_in_modal"]').val(note);
                    $('.modal.show input[name="price_in_modal"]').val(headPrice);
                }, 200);
            } else {
                const currentValue = $(this).closest('td').find('input').val();
                setTimeout(() => {
                    if ( this.getAttribute('data-bs-target') === '#sma' ) {
                        return
                    }
                    $('.modal.show input').val(currentValue);
                }, 200);

            }
        } else {
            const currentValueFrom = $(this).closest('td').find('input[name="purchased_from[]"]').val();
            setTimeout(() => {
                if ( this.getAttribute('data-bs-target') === '#sma' ) {
                    return
                }


                let isSelect = false
                $('.modal.show select option').each(function (i) {
                    if ( $(this).text() === currentValueFrom || $(this).val() === currentValueFrom ) {
                        $('.modal.show select').val($(this).val()).change()

                        isSelect = true
                    }
                })

                if ( !isSelect ) {
                    $('.modal.show input').val(currentValueFrom).trigger('input');
                }

            }, 200);
        }


        if ( $(this).hasClass('js-edit-sma-in-table') ) {
            if ( currentAction === 'Sold' || currentAction === 'Bought' ) {
                $('.modal#sma').find('select[name="agent[]"]').removeAttr('disabled')
            } else {
                $('.modal#sma').find('select[name="agent[]"]').val('').attr('disabled', '')
            }
        }

    }

});







$('.js-report-table').on('change', '.datepicker--format_short', function (e) {

    const date = $(this).datepicker('getDate');
    const month = date.getMonth() + 1;
    const formattedMonth = month >= 10 ? month : `0${month}`;
    const formattedDay = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;

    let formattedDate = `${date.getFullYear()}-${formattedMonth}-${formattedDay}`;
    $(this).next().val(formattedDate);
})


export function propertyConfirmed()
{
    let blockActions = $('.js-ledger-table').attr('data-block-actions');

    return blockActions != 1

}

