export default function expandAnimalsDetails() {
    $(".js-toggle-animals-details").on("click", function () {
        const button = $(this);
        const card = button.closest(".js-animals-details-card");
        const cardBody = card.children('.card-body')
        const height = button.hasClass('opened') ? 150 : cardBody.innerHeight()
        card.css('height', `${height}px`)
        button.children('span').text(`${button.hasClass('opened') ? 'Expand' : 'Collapse'}`)
        button.find('i').toggleClass('opened');
        button.toggleClass('opened')
    });
}
