export default function copTab(idx) {

    return `
      <div class="modal-body text-start tab-pane" id="copa${idx}" role="tabpanel" aria-labelledby="copa-tab${idx}">
        <div class="container">

            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="weight">Pre Feedlot</label>
                        </div>
                        <div class="col-md-8">
                            <input class="form-control w-100" placeholder="" name="pre_feedlot[]" type="text" value="">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="weight">Feedlot</label>
                        </div>
                        <div class="col-md-8">
                            <input class="form-control w-100" placeholder="" name="feedlot[]" type="text" value="">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="weight">Kill $</label>
                        </div>
                        <div class="col-md-8">
                            <input class="form-control w-100" placeholder="" name="kill[]" type="text" value="">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="weight">Sales</label>
                        </div>
                        <div class="col-md-8">
                            <input class="form-control w-100" placeholder="" name="sales[]" type="text" value="">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col">
                    <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                        <div class="col-md-4">
                            <label for="weight">Marketing</label>
                        </div>
                        <div class="col-md-8">
                            <input class="form-control w-100" placeholder="" name="marketing[]" type="text" value="">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    `;
}