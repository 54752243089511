import axios from "axios";
import { initSelectpickerCustomization } from '../scripts/bs-select-custom';
import expandAnimalsDetails from './expand-animals-details'

$('.js-reject-transfer').on('click', function() {
   $(this).closest('form').addClass('current');
});

$('#rejectTransferReasonModal').on('hide.bs.modal', function () {
    $('.js-form-reject-transfer').removeClass('current');
})

require('./friendship/reject-invitation')
require('./friendship/resend-invitation')
require('./friendship/add-email-row')
require('./friendship/remove-email-row')
require('./friendship/toggle-invitation-details')



$('.js-toggle-transfer-to-user-details').on('click', function(e) {
    const button = $(this);
    const isLoaded = button.hasClass('isLoaded');
    const parentTableRow = button.parents('tr');
    const transferId = button.attr('data-transfer-id');

    if (isLoaded){
        $(`.js-transfer-to-user-details-${transferId}`).fadeToggle();
        button.find('i').toggleClass('opened');
    } else {
        axios.get(`/get-transfer-to-user-details?transferId=${transferId}`)
            .then((response) => {
                $(response.data.html).insertAfter(parentTableRow);
                expandAnimalsDetails()

                button
                    .addClass('isLoaded')
                    .find('i').toggleClass('opened');
            })
            .catch((error) => {
                console.error(error);
                alert('Something went wrong. Please try again or come back later');
            })
    }

})




if(window.location.pathname.includes('friendship')) {
    const params = (new URL(document.location)).searchParams;
    const id = params.get('id');

    if(params.get('details') !== null && params.get('details') === 'friendship') {
        const outgoingTab = $(`#nav-outgoing table tbody tr[data-id="${id}"]`);

        if(outgoingTab.length !== 0) {
            $(outgoingTab).find('.js-toggle-user-friendship-invite-details').trigger('click');
        } else {
            $('.nav-tabs:first a:last').tab('show').css('outline', 'none');

            $(`#nav-incoming table tbody tr[data-id="${id}"] .js-toggle-user-friendship-invite-details`).trigger('click');
        }
    } else if(params.get('details') !== null && params.get('details') === 'transfer') {
        const outgoingTab = $(`#nav-outgoing-transfer table tbody tr[data-id="${id}"]`);

        if(outgoingTab.length !== 0) {
            $(outgoingTab).find('td:last button:last').trigger('click');
        } else {
            const incomingTab = $(`#nav-incoming-transfer table tbody tr[data-id="${id}"]`);

            if(incomingTab.length !== 0) {
                $('.nav-tabs:last a:eq(1)').tab('show').css('outline', 'none');
                $(incomingTab).find(`td:last button:last`).trigger('click');
            } else {
                $('.nav-tabs:last a:last').tab('show').css('outline', 'none');
                $(`#nav-updating-transfer table tbody tr[data-id="${id}"] td:last button:last`).trigger('click');
            }
        }
    }

}



