
require('./staff/send-request')
require('./staff/add-email-row')
require('./staff/remove-email-row')
require('./staff/strict-email-field')

require('./staff/hide-paddocks-table-on-switch-tab')
require('./staff/show-paddocks-groups')

require('./staff/cattle-return-notification')








