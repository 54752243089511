import {updateAgesSelectsInAModal} from "../cattle-type/actions-cattle-type";

$('.js-report-table').on('change', '.js-action-select-cattle-type, #propertyToMove, #propertyFromMove', async function (e) {
    await onChangeCattleType($(this));
});

export async function onChangeCattleType(el) {

    let select = el;
    if ( $(select).attr('multiple') && $($(select)).hasClass('js-action-select-cattle-type') ) {

        let selectAction = $(select).closest('tr');

        if ($(select).value === 'all') {
            $(select).find('option:not(:first-child)').removeAttr('selected');
            $(select).find('option:not(:first-child)').css('background', 'none');
            $(select).find('option:not(:first-child)').attr('disabled', '');
        } else {
            $(select).find('option').removeAttr('disabled');
            $(select).find('option:not(:first-child)').css('background', '');
        }

        if ( $(select).val().length > 1 ) {
            $(selectAction).find('select[name="year_of_birth[]"]').attr('disabled', '');

            let quantitySum = 0;

            if ($(select).closest('tr').find('td[data-action] select').val() === 'Transfer In') {
                let propertyId = $(select).closest('tr').find('select[name="updated_property_id[]"]').val();
                let cattleTypes = $(select).val();

                axios.get(`/get-paddock-cattles-quantity?property=${propertyId}&cattle_types=${cattleTypes}`)
                    .then(recievePaddockCattlesQuantity => {
                        quantitySum = recievePaddockCattlesQuantity.data.quantity;

                        $(selectAction).find('input[name="quantity[]"]').val(quantitySum);
                        $(selectAction).find('input[name="quantity[]"]').attr('readonly', '');
                    })


            } else {
                $(select).val().forEach(function (element) {
                    $(`.js-ledger-table tbody tr td[data-current-cattle-type-id="${element}"]`).each(function (i) {
                        quantitySum += parseInt($(this).find('a').text());

                        $(selectAction).find('input[name="quantity[]"]').val(quantitySum);
                        $(selectAction).find('input[name="quantity[]"]').attr('readonly', '');
                    });
                });
            }


            return;
        } else if ( $(select).value === 'all' ) {
            $(selectAction).find('select[name="year_of_birth[]"]').attr('disabled', '');

            let quantitySum = 0;

            if ( $(select).closest('tr').find('td[data-action] select').val() === 'Transfer In' ) {
                let propertyId = $(select).closest('tr').find('select[name="updated_property_id[]"]').val();
                let cattleTypes = $(select).val();

                axios.get(`/get-paddock-cattles-quantity?property=${propertyId}&cattle_types=${cattleTypes}`)
                    .then(recievePaddockCattlesQuantity => {
                        quantitySum = recievePaddockCattlesQuantity.data.quantity;

                        $(selectAction).find('input[name="quantity[]"]').val(quantitySum);
                        $(selectAction).find('input[name="quantity[]"]').attr('readonly', '');
                    })


            } else {
                $(`.js-ledger-table tbody tr td[data-current-cattle-type-id]`).each(function (i) {
                    quantitySum += parseInt($(this).find('a').text());

                    $(selectAction).find('input[name="quantity[]"]').val(quantitySum);
                    $(selectAction).find('input[name="quantity[]"]').attr('readonly', '');
                });
            }





        } else {
            $(selectAction).find('select[name="year_of_birth[]"]').removeAttr('disabled');
            $(selectAction).find('input[name="quantity[]"]').removeAttr('readonly');
        }
    }

    if (
        $(el).is('.js-action-select-cattle-type')
        && $(el).closest('.action-in-table').find('.js-action-select-in-table').val() !== 'Reclass'
        && $(el).closest('.action-in-table').find('.js-action-select-in-table').val() !== 'Weaned'
        && $(el).closest('.action-in-table').find('.js-action-select-in-table').val() !== 'Process Weaners'
    ) {
        const chosenCattleTypeFrom = $(el).val();
        if ($(el).closest('.action-in-table').find('select[name="updated_cattle_type_id[]"]').attr('disabled') !== 'disabled') {
            $(el).closest('.action-in-table').find('select[name="updated_cattle_type_id[]"]').val(chosenCattleTypeFrom);
        }

    } else {
        $(el).closest('.action-in-table').find('.js-small-text').remove();
    }


    if ( $(el).is('#propertyFromMove') || $(el).is('#propertyToMove') ) {
        let propertyId = $('input[name="property_id"]:last').val();
        if ( propertyId === '0' ) {
            $(el).closest('.action-in-table').find('#paddockToMove').html('');
            return;
        }

    } else {
        const closestAction = $(el).closest('.action-in-table');

        await updateAgesSelectsInAModal(closestAction);
    }


}
