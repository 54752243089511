function bindInputs() {
    const inputs = document.querySelectorAll("[data-bind]");

    inputs.forEach((input) => {
        const bindingName = input.dataset.bind;
        const target = {};

        const proxy = new Proxy(target, {
            set(obj, key, value) {
                obj[key] = value;

                inputs.forEach((input) => {
                    if (
                        input.dataset.bind === bindingName &&
                        input !== event.target
                    ) {
                        if (
                            input.parentNode.parentNode.classList.contains(
                                "current"
                            )
                        ) {
                            input.value = value;
                        }
                    }
                });

                return true;
            },
        });

        if (target[input.id]) {
            input.value = target[input.id];
        }

        // input.addEventListener("change", (event) => {
        //     proxy[event.target.id] = event.target.value;
        // });
        // input.addEventListener("input", (event) => {
        //     proxy[event.target.id] = event.target.value;
        // });
    });
}

let previousValues = {};

document.addEventListener("transaction-changed", () => {
    const openBtns = document.querySelectorAll(".js-edit-sma-in-table");
    for (let btn of openBtns) {
        btn.addEventListener("click", function () {
            bindInputs();
            const formInputs = btn.parentNode.querySelectorAll("[data-bind]");
            formInputs.forEach((input) => {
                previousValues[input.dataset.bind] = input.value;
                document.querySelector(`.modal#sma [data-bind='${input.dataset.bind}']`).value = input.value;
            });
        });
    }
    window.previousValues = previousValues;
});

let needToSave = false;

$(".modal#sma").on("click", (e) => {
    e.stopPropagation();
    if (e.target.textContent === "Save") {
        needToSave = true;
        setTimeout(() => {
            needToSave = false;
        }, 400);
    }
});

$(".modal#sma").on("hide.bs.modal", function (e) {
    setTimeout(() => {
        if (!needToSave) {
            document.querySelectorAll("tr.current [data-bind]").forEach((input) => {
                input.value = previousValues[`${input.dataset.bind}`];
                });
        }

        $('.modal#sma [data-hide="sma"]').attr('hidden', '')
        // this.querySelector('label[for="sma"]').setAttribute('hidden', '');
        // this.querySelector('input#sma').setAttribute('hidden', '');
        document.querySelector('tr.current')?.classList.remove('current')
        previousValues = {};
    }, 200);
});
