$('.js-report-table').on('click', '.expand-transactions, .collapse-transactions', function (e) {
    const date = $(this).closest('td').data('date')
    const columnsCount = $(this).hasClass('collapse-transactions') ? $('#js-reports-table th:not(.d-none)').length : 1

    $(this).closest('td').attr('colspan', columnsCount)

    $(this).closest('td').find('.collapse-transactions, .expand-transactions').toggleClass('d-none')

    if ( $(this).hasClass('collapse-transactions') ) {
        $(this).closest('td').nextAll('td:not(.column-hidden)').addClass('d-none')
    } else {
        $(this).closest('td').nextAll('td:not(.column-hidden)').removeClass('d-none')
    }

    $(`td[data-date="${date}"]:not(:first)`).closest('tr').toggleClass('d-none')
})
