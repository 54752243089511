import axios from "axios";
import {refreshMultipleSelector} from "../property";

$('#addMOB, #editMOB').on('hide.bs.modal', function (e) {
    $(this).find('select[name="cattle_type_mob"]').val('').trigger('change')
})

$('.js-set-mob-cattle-type').on('change', function (e) {
    const cattleTypeId = $(this).val()
    const animalsSelector = $(this).closest('form').find('[name="cattles[]"]')

    updateAnimalsSelector(animalsSelector, cattleTypeId)
})

$('.js-edit-mob-cattle-type').on('change', function (e) {
    const cattleTypeId = $(this).val()
    const animalsSelector = $(this).closest('form').find('[name="cattles[]"]')
    const mobID = $(this).data('mob-id')

    updateAnimalsSelector(animalsSelector, cattleTypeId, mobID)
})

function updateAnimalsSelector(animalsSelector, cattleTypeId, mobId = null)
{
    axios.get(`/get-cattle-type-animals-ajax?&mobId=${mobId}&cattleTypeId=${cattleTypeId}`)
        .then(response => {
            refreshMultipleSelector(animalsSelector)

            $(animalsSelector).selectpicker();
            $(animalsSelector).selectpicker('render')

            response.data.animals.forEach(function (value, index) {
                let isSelected = mobId !== null && value.mob_id === mobId.toString() ? 'selected' : ''

                $(animalsSelector).append(`<option value="${value.id}" ${isSelected}>${value.animal_id}</option>`)

            })

            $(animalsSelector).trigger('change')
            $(animalsSelector).selectpicker('refresh')

        })
}

$('.js-edit-mob').on('click', function (e) {
    let currentMOBName = $(this).closest('tr').find('input[name="name"]').val(),
        currentCattleTypeId = $(this).closest('tr').find('input[name="cattle_type"]').val(),
        mobId = $(this).data('id'),
        formAction = $('#editMOB form')
            .attr('action')
            .replace(/(\/)?[0-9]+$/, '') + '/' + mobId

    $('#editMOB form').attr('action', formAction);

    $('#editMOBName').val(currentMOBName);
    $('#editCattleTypeMOB').val(currentCattleTypeId).data('mob-id', mobId)

    updateAnimalsSelector($('#editCattles'), currentCattleTypeId, mobId)

    $('#editMOB').modal();
    new bootstrap.Modal('#editMOB').show();
})
