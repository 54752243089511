$('body').on('input', '#email', function(e) {

    let target = e.target;
    $(target).val($(target).val().replace('(', ''));
    $(target).val($(target).val().replace(')', ''));

    let email = $(target).val();
    $(target).val(email);

});
