import {initCustomDragAndDrop,
    colorizeIcon,
    colorizeHeaderColumn,
    colorizeBodyColumn,
    colorizeFooterColumn,
    } from './ledger'
import {initPaddockDragging} from './drag&drop-order-in-table'
import {changeWidthOfPaddockGroupsTable} from "./ledger/change-width-of-paddocks-groups";

const {default: axios} = require('axios')
const {last} = require('lodash')

$('body').on('click', 'th .fa-arrow-right, th .fa-arrow-left', function (event) {
    event.preventDefault()



    let target = event.target
    let currentTable = $(target).closest('table')
    let selectedElement = $(target).closest('th')
    let sideElement = ''
    let orderFrom = $(selectedElement).data('order')
    let orderTo = ''
    let side = ''

    let cattleTypeId = $(selectedElement).data('cattle-type-id')
    let cattleTypeIdTo = ''

    let leftCattleTypeId = ''
    let rightCattleTypeId = ''

    const headingElements = $(currentTable).find('thead:first tr:first')

    if ($(target).hasClass('fa-arrow-left')) {
        side = 'left'
        cattleTypeIdTo = $(selectedElement).prevAll(`th:not([data-cattle-type-id="${cattleTypeId}"]):first`).data('cattle-type-id')

        leftCattleTypeId = $(selectedElement).prevAll(`th:not([data-cattle-type-id="${cattleTypeIdTo}"]):first`).data('cattle-type-id')
        rightCattleTypeId = cattleTypeIdTo

        orderTo = $(selectedElement).prevAll(`th:not([data-cattle-type-id="${cattleTypeId}"]):first`).data('order')
        sideElement = $(selectedElement).prevAll('th:not(".d-none"):first')
    } else if ($(target).hasClass('fa-arrow-right')) {
        side = 'right'
        cattleTypeIdTo = $(selectedElement).nextAll(`th:not([data-cattle-type-id="${cattleTypeId}"]):first`).data('cattle-type-id')

        leftCattleTypeId = cattleTypeIdTo
        rightCattleTypeId = $(selectedElement).nextAll(`th:not([data-cattle-type-id="${cattleTypeId}"]):not([data-cattle-type-id="${cattleTypeIdTo}"]):first`).data('cattle-type-id')

        orderTo = $(selectedElement).nextAll(`th:not([data-cattle-type-id="${cattleTypeId}"]):first`).data('order')
        sideElement = $(selectedElement).nextAll('th:not(".d-none"):first')
    }

    // reflesh order arrows

    $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]`).find('.fa-arrow-left, .fa-arrow-right').closest('a').remove()
    $(headingElements).find(`th[data-cattle-type-id="${cattleTypeIdTo}"]`).find('.fa-arrow-left, .fa-arrow-right').closest('a').remove()

    let cattleTypesFrom = $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]`)
    let cattleTypesTo = $(headingElements).find(`th[data-cattle-type-id="${cattleTypeIdTo}"]`)

    const borderStyles = '1px solid #858181'
    const selectedTypeColor = $(selectedElement).css('background-color') !== 'rgba(0, 0, 0, 0)' ? $(selectedElement).css('background-color') : 'white'
    const sideTypeColor = $(sideElement).css('background-color') !== 'rgba(0, 0, 0, 0)' ? $(sideElement).css('background-color') : 'white'

    if (orderTo === 0) {
        if (cattleTypesFrom.length > 1) {
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:first div a:last`).after(`<a class="js-arrow-right"><i class="fas fa-arrow-right" title="Move right"></i></a>`)
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:first`).css('border-right', borderStyles)

            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:last div a:last`).after(`<a class="js-arrow-right"><i style="color: ${selectedTypeColor}" class="fas fa-arrow-right" title="Move right"></i></a>`)
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:last`).css('border-right', borderStyles)
        } else {
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:first div a:last`).after(`<a class="js-arrow-right"><i style="color: ${selectedTypeColor}" class="fas fa-arrow-right" title="Move right"></i></a>`)
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:first`).css('border-right', borderStyles)
        }
    } else if (orderTo === 'last') {
        if (cattleTypesFrom.length > 1) {
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:first div`).prepend(`<a class="js-arrow-left"><i class="fas fa-arrow-left" title="Move left"></i></a>`)
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:first`).css('border-left', borderStyles)

            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:eq(1) div`).prepend(`<a class="js-arrow-left"><i style="color: ${selectedTypeColor}" class="fas fa-arrow-left" title="Move left"></i></a>`)
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:eq(1)`).css('border-left', borderStyles)
        } else {
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:first div`).prepend(`<a class="js-arrow-left"><i style="color: ${selectedTypeColor}" class="fas fa-arrow-left" title="Move left"></i></a>`)
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:first`).css('border-left', borderStyles)
        }
    } else {
        if (cattleTypesFrom.length > 1) {
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:first div`).prepend(`<a class="js-arrow-left"><i  class="fas fa-arrow-left" title="Move left"></i></a>`)
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:first div a:last`).after(`<a class="js-arrow-right"><i class="fas fa-arrow-right" title="Move right"></i></a>`)

            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:eq(1) div`).prepend(`<a class="js-arrow-left"><i style="color: ${selectedTypeColor}" class="fas fa-arrow-left" title="Move left"></i></a>`)
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:last div a:last`).after(`<a class="js-arrow-right"><i style="color: ${selectedTypeColor}" class="fas fa-arrow-right" title="Move right"></i></a>`)
        } else {
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:first div`).prepend(`<a class="js-arrow-left"><i style="color: ${selectedTypeColor}" class="fas fa-arrow-left" title="Move left"></i></a>`)
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeId}"]:first div a:last`).after(`<a class="js-arrow-right"><i style="color: ${selectedTypeColor}" class="fas fa-arrow-right" title="Move right"></i></a>`)
        }
    }

    if (orderFrom === 0) {
        if (cattleTypesTo.length > 1) {
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeIdTo}"]:first div a:last`).after(`<a class="js-arrow-right"><i class="fas fa-arrow-right" title="Move right"></i></a>`)
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeIdTo}"]:last div a:last`).after(`<a class="js-arrow-right"><i style="color: ${sideTypeColor}" class="fas fa-arrow-right" title="Move right"></i></a>`)
        } else {
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeIdTo}"]:first div a:last`).after(`<a class="js-arrow-right"><i style="color: ${sideTypeColor}" class="fas fa-arrow-right" title="Move right"></i></a>`)
        }
    } else if (orderFrom === 'last') {
        if (cattleTypesTo.length > 1) {
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeIdTo}"]:first div`).prepend(`<a class="js-arrow-left"><i class="fas fa-arrow-left" title="Move left"></i></a>`)
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeIdTo}"]:eq(1) div`).prepend(`<a class="js-arrow-left"><i style="color: ${sideTypeColor}" class="fas fa-arrow-left" title="Move left"></i></a>`)
        } else {
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeIdTo}"]:first div`).prepend(`<a class="js-arrow-left"><i style="color: ${sideTypeColor}" class="fas fa-arrow-left" title="Move left"></i></a>`)
        }
    } else {
        if (cattleTypesTo.length > 1) {
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeIdTo}"]:first div`).prepend(`<a class="js-arrow-left"><i class="fas fa-arrow-left" title="Move left"></i></a>`)
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeIdTo}"]:first div a:last`).after(`<a class="js-arrow-right"><i class="fas fa-arrow-right" title="Move right"></i></a>`)

            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeIdTo}"]:eq(1) div`).prepend(`<a class="js-arrow-left"><i style="color: ${sideTypeColor}" class="fas fa-arrow-left" title="Move left"></i></a>`)
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeIdTo}"]:last div a:last`).after(`<a class="js-arrow-right"><i style="color: ${sideTypeColor}" class="fas fa-arrow-right" title="Move right"></i></a>`)
        } else {
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeIdTo}"]:first div`).prepend(`<a class="js-arrow-left"><i style="color: ${sideTypeColor}" class="fas fa-arrow-left" title="Move left"></i></a>`)
            $(headingElements).find(`th[data-cattle-type-id="${cattleTypeIdTo}"]:first div a:last`).after(`<a class="js-arrow-right"><i style="color: ${sideTypeColor}" class="fas fa-arrow-right" title="Move right"></i></a>`)
        }
    }

    // swap data order
    $(currentTable).find('thead:first tr:first th[data-cattle-type-id="' + cattleTypeId + '"]').attr('data-order', orderTo)
    $(currentTable).find('thead:first tr:first th[data-cattle-type-id="' + cattleTypeIdTo + '"]').attr('data-order', orderFrom)

    // collection of necessary html data
    let cattleTypeHeadingHtmlFrom = ''

    $(currentTable).find('thead:first tr:first th[data-cattle-type-id="' + cattleTypeId + '"]').each(function (i) {
        cattleTypeHeadingHtmlFrom += $(this).prop('outerHTML')
    })

    let cattleTypeHeadingHtmlTo = ''
    $(currentTable).find('thead:first tr:first th[data-cattle-type-id="' + cattleTypeIdTo + '"]').each(function (i) {
        cattleTypeHeadingHtmlTo += $(this).prop('outerHTML')
    })

    let cattleTypeAgesOfBirthFrom = ''
    $(currentTable).find('thead:first tr:last td[data-cattle-type-id="' + cattleTypeId + '"]').each(function (i) {
        cattleTypeAgesOfBirthFrom += $(this).prop('outerHTML')
    })

    let cattleTypeAgesOfBirthTo = ''
    $(currentTable).find('thead:first tr:last td[data-cattle-type-id="' + cattleTypeIdTo + '"]').each(function (i) {
        cattleTypeAgesOfBirthTo += $(this).prop('outerHTML')
    })

    let cattleTypeTotalQuntityInAgesOfBirthFrom = ''
    $(currentTable).find('tfoot tr td[data-cattle-type-id="' + cattleTypeId + '"]').each(function (i) {
        cattleTypeTotalQuntityInAgesOfBirthFrom += $(this).prop('outerHTML')
    })

    let cattleTypeTotalQuntityInAgesOfBirthTo = ''
    $(currentTable).find('tfoot tr td[data-cattle-type-id="' + cattleTypeIdTo + '"]').each(function (i) {
        cattleTypeTotalQuntityInAgesOfBirthTo += $(this).prop('outerHTML')
    })

    //executing

    //Replace heading

    $(currentTable).find('thead:first tr:first th[data-cattle-type-id="' + cattleTypeId + '"]:not(:first)').remove()
    $(currentTable).find('thead:first tr:first th[data-cattle-type-id="' + cattleTypeIdTo + '"]:not(:first)').remove()
    $(currentTable).find('thead:first tr:first th[data-cattle-type-id="' + cattleTypeId + '"]').replaceWith(cattleTypeHeadingHtmlTo)

    if (side === 'right') {
        $(currentTable).find('thead:first tr:first th[data-cattle-type-id="' + cattleTypeIdTo + '"]:last')
            .replaceWith(cattleTypeHeadingHtmlFrom)
    } else {
        $(currentTable).find('thead:first tr:first th[data-cattle-type-id="' + cattleTypeIdTo + '"]:first')
            .replaceWith(cattleTypeHeadingHtmlFrom)
    }

    //Replace Ages of Birth
    $(currentTable).find('thead:first tr:last td[data-cattle-type-id="' + cattleTypeId + '"]').not(':first').remove()
    $(currentTable).find('thead:first tr:last td[data-cattle-type-id="' + cattleTypeIdTo + '"]').not(':first').remove()
    $(currentTable).find('thead:first tr:last td[data-cattle-type-id="' + cattleTypeId + '"]').replaceWith(cattleTypeAgesOfBirthTo)

    if (side === 'right') {
        $(currentTable).find('thead:first tr:last td[data-cattle-type-id="' + cattleTypeIdTo + '"]').last()
            .replaceWith(cattleTypeAgesOfBirthFrom)
    } else {
        $(currentTable).find('thead:first tr:last td[data-cattle-type-id="' + cattleTypeIdTo + '"]').first()
            .replaceWith(cattleTypeAgesOfBirthFrom)
    }

    //Replace every Quantity
    $(currentTable).find(`tbody tr[data-paddock-id]`).each(function (i) {
        replaceQuantity(i, $(this), cattleTypeId, cattleTypeIdTo)
    })
    $(currentTable).find(`.group-table thead tr`).each(function (i) {
        replaceQuantity(i, $(this), cattleTypeId, cattleTypeIdTo)
    })

    function replaceQuantity(i, element, cattleTypeId, cattleTypeIdTo) {
        let cattleTypeQuntityInAgesOfBirthFrom = ''
        $(element).find('td[data-cattle-type-id="' + cattleTypeId + '"]').each(function (i) {
            cattleTypeQuntityInAgesOfBirthFrom += $(this).prop('outerHTML')
        })

        let cattleTypeQuntityInAgesOfBirthTo = ''
        $(element).find('td[data-cattle-type-id="' + cattleTypeIdTo + '"]').each(function (i) {
            cattleTypeQuntityInAgesOfBirthTo += $(this).prop('outerHTML')
        })


        $(element).find('td[data-cattle-type-id="' + cattleTypeId + '"]').not(':first').remove()
        $(element).find('td[data-cattle-type-id="' + cattleTypeIdTo + '"]').not(':first').remove()
        $(element).find('td[data-cattle-type-id="' + cattleTypeId + '"]').replaceWith(cattleTypeQuntityInAgesOfBirthTo)
        if (side === 'right') {
            $(element).find('td[data-cattle-type-id="' + cattleTypeIdTo + '"]').last()
                .replaceWith(cattleTypeQuntityInAgesOfBirthFrom)
        } else {
            $(element).find('td[data-cattle-type-id="' + cattleTypeIdTo + '"]').first()
                .replaceWith(cattleTypeQuntityInAgesOfBirthFrom)
        }
    }

    // Replace Total
    $(currentTable).find('tfoot tr td[data-cattle-type-id="' + cattleTypeId + '"]').not(':first').remove()
    $(currentTable).find('tfoot tr td[data-cattle-type-id="' + cattleTypeIdTo + '"]').not(':first').remove()
    $(currentTable).find('tfoot tr td[data-cattle-type-id="' + cattleTypeId + '"]').replaceWith(cattleTypeTotalQuntityInAgesOfBirthTo)
    if (side === 'right') {
        $(currentTable).find('tfoot tr td[data-cattle-type-id="' + cattleTypeIdTo + '"]').last()
            .replaceWith(cattleTypeTotalQuntityInAgesOfBirthFrom)
    } else {
        $(currentTable).find('tfoot tr td[data-cattle-type-id="' + cattleTypeIdTo + '"]').first()
            .replaceWith(cattleTypeTotalQuntityInAgesOfBirthFrom)
    }


    if ($('.group-table').length !== 0) {
        $('.group-table').each(function (i) {
            changeWidthOfPaddockGroupsTable($(this)[0])
        })
    }

    $('.fa-arrow-right, .fa-arrow-left').addClass('d-none')

    const propertyId = $(currentTable).data('id')
    axios.get(`/change-cattle-type-order?leftCattleTypeId=${leftCattleTypeId}&cattleTypeId=${cattleTypeId}&rightCattleTypeId=${rightCattleTypeId}&propertyId=${propertyId}`)
        .then(response => {
            $('.fa-arrow-right, .fa-arrow-left').removeClass('d-none')

        })

    setTimeout(function () {
        initPaddockDragging(currentTable[0], false)
    }, 300)

    initCustomDragAndDrop(`[data-cattle-type-id="${cattleTypeId}"]`)
    initCustomDragAndDrop(`[data-cattle-type-id="${cattleTypeIdTo}"]`)

    $('.color-picker').spectrum({
        type: 'component',
        showAlpha: false,
        cancelText: 'Reset',
        chooseText: '',
        containerClassName: 'cpicker-container'
    })

    $('.sp-cancel').on('click', function(event) {
                    event.preventDefault()

                    const cattleTypeId = $('.active-picker').closest('th').data('current-cattle-type-id')

                    colorizeIcon($('.active-picker'), '')
                    colorizeHeaderColumn(cattleTypeId, '', true)
                    colorizeBodyColumn(cattleTypeId, '')
                    colorizeFooterColumn(cattleTypeId, '')

                    axios.post('/color', {
                        'color': null,
                        'cattle_type_id': cattleTypeId
                    })

                    $(this).closest('.sp-container').spectrum("destroy");
                })

    $('[data-toggle="popover"]').popover({
        trigger: 'hover focus',
        html: true,
    });
})
