import confirm from "jquery-confirm";
import { forceReclass, insertPaddocks } from "./cattle-type/actions-cattle-type"

export function updateReportsAlert() {
    $.confirm({
        title: 'Warning',
        content: 'You have created an action that will affect a confirmed Cattle Return. Please confirm the Cattle Return again.',

        buttons: {
            'OK': function () {
            },
        }
    });
}

export function blockActionsAlert() {
    $.confirm({
        title: 'Error',
        content: 'The Cattle Return isn\'t confirmed. Do you want to confirm it now?',

        buttons: {
            'Yes': function () {
                const propertyId = $('[data-property-id]').data('property-id')

                $.ajax({
                    'type': "get",
                    'url': '/update-monthly-confirm-status/' + propertyId,
                    'success': function () {
                        location.reload()
                    }
                });
            },
            'Go to Cattle Return': function () {
                const propertyId = $('[data-property-id]').data('property-id')

                window.open(`/cattle-return/${propertyId}`, '_blank');
            },
            'No': function () {
            },
        }
    });
}

export function confirmMontlyAlert(propertyId) {
    $.confirm({
        title: 'Confirmation',
        content: 'Are you sure you want to confirm?',

        buttons: {
            'YES': function () {
                $.ajax({
                    'type': "get",
                    'url': '/update-monthly-confirm-status/' + propertyId,
                    'success': function () {
                        location.reload()
                    }
                });
            },
            'NO': function () {
            },
        }
    });
}

export async function extraAnimalsAlert(elAction, paddockId, cattleTypeId, yearOfBirth, extraQuantity)
{
    let behaviorType = ''

    $.confirm({
        title: 'Confirmation',
        content: 'You want to apply this action to more animals that you currently have in this paddock. Are you sure?',

        buttons: {
            'YES': function () {
                $(elAction).append('<input type="hidden" form="actionsFormInTable" id="extra_animals_action[]">')
                $('.js-actions-form-in-table').trigger('submit');
            },
            'Reclass': function () {
                forceReclass(elAction, paddockId, cattleTypeId, yearOfBirth);
            },
            'Move': async function () {

                let moveToPaddockId = ''
                if ( $(elAction).find('.js-action-select-in-table').val() === 'Moved' ) {
                    const paddockToId = $(elAction).find('#paddockToMove').val()
                    moveToPaddockId = `&except_paddock_to_id=${paddockToId}`
                }

                //$('#paddocks-selector').show()
                await axios.get(`/get-paddocks-quantity-by-year?except_id=${paddockId}${moveToPaddockId}&cattle_type_id=${cattleTypeId}&year_of_birth=${yearOfBirth}`).then((response) => {
                    let paddocksQuantityPairs = response.data.paddocks
                    const actionIndex = $(elAction).data('index')

                    insertPaddocks(paddocksQuantityPairs, extraQuantity, actionIndex)
                    $('#paddocks-selector').modal('show')
                })
            },
            'NO': function () {
                behaviorType = 'no'
            },
        }
    });

    return behaviorType
}

export async function alertOnNegateExtraAnimals(elAction)
{
    $.confirm({
        title: 'Confirmation',
        content: 'Do you want to negate the extra animals from this paddock?',

        buttons: {
            'YES': function () {
                $(elAction).append('<input type="hidden" value="1" form="actionsFormInTable" name="negate_extra_animals_action">')
                $('.js-actions-form-in-table').trigger('submit');
            },
            'NO': function () {
                if ( $(elAction).find('.is-invalid').length === 0 ) {
                    $('.js-actions-form-in-table').trigger('submit');
                }
            }
        }

    })
}

export async function addNewAnimalsAlert(targetLevel, missingAnimalsCount)
{
    let missingAnimalsList = '<br>'
    missingAnimalsCount = Object.values(missingAnimalsCount)

    missingAnimalsCount.forEach((el) => {
        missingAnimalsList += el.cattle_type_name + ' - ' + el.quantity + '<br>'
    })


    $.confirm({
        title: 'Confirmation',
        content: `There are not enough animals in Animal Database. If you want to keep your <b>animals</b> and <b>transactions</b>, please add the following ones: ${missingAnimalsList} <br>Otherwise, they will be deleted.`,

        buttons: {
            'Add': function () {
                $('#addCattle').modal('show')

                    let afterFirst = false
                    missingAnimalsCount.forEach((el, index) => {
                        for (let i = 0; i < el.quantity; i++) {

                            if ( afterFirst ) {
                                $('.js-add-cattle-instance').trigger('click')
                            }
                            afterFirst = true

                            $('#addCattle [name="cattle_type[]"]:last')
                                .val(el.cattle_type_id)
                                .attr('disabled', '')
                                .after(`<input class="d-none" name="cattle_type[]" value="${el.cattle_type_id}">`)

                        }
                    })

                $('.js-remove-cattle-instance, .js-add-cattle-instance').addClass('d-none')
                $('.js-form-add-cattle').append(`<input type="hidden" name="to" value="full">`)
                $('.js-form-add-cattle').append(`<input type="hidden" name="replaceAnimals" value="true">`)

            },
            'Delete': function () {
                window.location = '/switch-level?to=' + targetLevel
            },
            'Cancel': function () {
                window.location = '/profile-settings'
            }
        }
    })
}

export function authSessionExpiredAlert()
{
    $.confirm({
        title: 'Warning',
        content: `Session is over. You will be redirected to login page!`,

        buttons: {
            'OK': function () {
                window.location = ''
            },
        }
    })
}
