import axios from "axios";

$(window).on('load', async function (e) {
    await getGoogleAPIData()
    addGoogleAccessToUser()
})

$('#authorize_button').on('click', function (e) {
    handleAuthClick()
})

$('#signout_button').on('click', function (e) {
    handleSignoutClick()
})


let CLIENT_ID = ''
let API_KEY = ''

let accessToken = $('body').data('google-token');

function addGoogleAccessToUser()
{
    let params = (new URL(document.location)).searchParams;

    if ( params.get('code') !== null ) {
        axios(`/store-google-token?code=${params.get('code')}`)
            .then( () => {
                window.location = '/profile-settings'
            })
    }
}

async function getGoogleAPIData()
{
    await axios.get('/get-google-api-data')
        .then( (response) => {
            CLIENT_ID = response.data.client_id
            API_KEY = response.data.api_key
        })
}

export function handleAuthClick() {

    const authData = new URLSearchParams({
        'scope': 'https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/calendar',
        'access_type': 'offline',
        'include_granted_scopes': true,
        'response_type': 'code',
        'state': window.location.hostname,
        'redirect_uri': window.location,
        'client_id': CLIENT_ID
    })

    window.location = `https://accounts.google.com/o/oauth2/v2/auth?${authData}`
}

function handleSignoutClick() {

    if (accessToken !== null) {
        axios.post(`https://oauth2.googleapis.com/revoke?token=${accessToken}`, {},{
            'Content-type': 'application/x-www-form-urlencoded'
        })

        axios.get(`/delete-google-token?token=${accessToken}`)
            .then( () => {
                window.location.reload()
            })
    }
}

export async function addNewEvent(summary = '', description = '', date = '2024-09-17', googleAccessToken) {

    const event = {
        start: {
            date: date,
            timeZone: 'America/Los_Angeles'
        },
        end: {
            date: date,
            timeZone: "America/Los_Angeles"
        },
        summary: summary,
        location: 'Paddock',
        description: description
    };

    await axios.post(`https://www.googleapis.com/calendar/v3/calendars/primary/events?key=${API_KEY}`, event, {
        headers: {
            'Authorization': 'Bearer ' + googleAccessToken,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }).then( () => {
        $('#createNewTask').closest('form').trigger('submit')
    })



}

