import {blockActionsAlert} from "../actions-alerts";
import {propertyConfirmed} from "../cattle-type/table-actions-cattle-type";
import {fillTransactionData} from "./fill-transaction-data";

export function initTransactionData(transactionId, isCopy = false)
{
    if ( !propertyConfirmed() ) {
        blockActionsAlert()
        return
    }

    const isReportsPage = window.location.pathname === '/reports'

    $('.js-add-transaction-first').trigger('click');

    if ( !isCopy ) {
        $('.js-add-action-instance-in-table').attr('disabled', 'disabled');

        $('.js-remove-action-instance-in-table').html('<i class="bi bi-dash me-2"></i>Cancel update').attr('data-update', 'true');
        $('.js-close-add-transaction-table').attr('data-update', 'true');
        $('.js-actions-save-in-table').text('Update');

        if ( isReportsPage ) {
            $('.js-actions-save-in-table').closest('div').removeClass('d-none')
        }

    }


    axios.get(`/get-transaction-data?transactionId=${transactionId}`)
        .then(response => {
            const data = response.data.transactionData;

            fillTransactionData(data, transactionId, isCopy);
        })

}
