import axios from "axios";


$(window).on('load', function(e) {
    if(window.location.pathname === '/reports') {
        if($('#report-filename').length !== 0) {
            const fileName = $('#report-filename').text();

            checkReportGenerate(fileName)

            function checkReportGenerate(fileName) {
                axios.get(`/check-report-generate?filename=${fileName}`)
                .then(function(response) {
                    let status = response.data.status;
                    if(status === 'false') {
                        setTimeout(() => { checkReportGenerate(fileName); }, 1000);

                    } else {

                        $('.js-generate-report-loader').closest('.alert').hide();
                        $('.js-report-generated-alert').removeClass('d-none');

                        let url = window.location.origin + '/' + 'download-report?filename=' + fileName;
                        window.location = url;

                    }
                });
            }
        }
    }

});
