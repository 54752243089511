$('.js-add-new-email-for-friendship-request').on('click', function(e) {
    let inputCount = $('#inviteToFriendshipModal input[name="email[]"]').length;
    let inputHtml = $('#inviteToFriendshipModal input[name="email[]"]:first').prop('outerHTML');

    if(inputCount === 10) {
        return;
    }

    if(inputCount === 1) {
        $(this).after(inputHtml);
    } else {
        $('#inviteToFriendshipModal input[name="email[]"]:last').after(inputHtml);
    }
});
