import {addAgesToSelect} from "../cattle-return";

$('#cattleReturnActions select[name="action[]"]').on('change', function(e) {

    $('#cattleReturnActions .container div.mb-2')
        .addClass('d-none')
        .find('input, select')
        .attr('disabled', 'disabled')

    if ( $(this).val() === 'Mortality' ) {
        $('.container[data-action="mortality"]')
            .removeClass('d-none')
            .find('div.mb-2')
            .removeClass('d-none')
            .find('input')
            .removeAttr('disabled')

        $('.container[data-action="notMortality"]').addClass('d-none')
    } else if ( $(this).val() === '0' ) {
        $('.container[data-action="mortality"]').addClass('d-none')
        $('.container[data-action="notMortality"]').addClass('d-none')
    } else {
        $('.container[data-action="mortality"]').addClass('d-none')
        $('.container[data-action="notMortality"]').removeClass('d-none')

        showFieldsByAction($(this).val(), $('.container[data-action="notMortality"]'))
    }

    $('#cattleReturnActions input[name="quantity"]').val('')
    $('#cattleReturnActions .js-small-text').addClass('d-none')
    $('#cattleReturnActions button[type="submit"]').attr('disabled', 'disabled')
})

export function showFieldsByAction(action, container)
{
    const propertyId = window.location.pathname.split('/')[2]

    $(container).find('div.mb-2').addClass('d-none')
    $(container).find('input, select').attr('disabled', 'disabled')

    enableField('input[name="quantity[]"]')
    enableField('input[name="ms_number"]')

    switch (action) {
        case 'Brand':
            enableField('select[name="current_paddock_id"]')

            break
        case 'Bought':
            enableField('select[name="current_paddock_id"]')
            enableField('input[name="sma[]"]')
            enableField('input[name="nvd[]"]')
            enableField('input[name="decks[]"]')

            break
        case 'Sold':
            $('input.js-chosen-paddock').removeAttr('disabled')
            enableField('input[name="sma[]"]')
            enableField('input[name="nvd[]"]')
            enableField('input[name="decks[]"]')

            break;
        case 'Reclass':
        case 'Weaned':
            $('input.js-chosen-paddock').removeAttr('disabled')

            enableField('select[name="updated_cattle_type_id[]"]')
            enableField('select[name="updated_year_of_birth[]"]')

            $(container).find('select[name="updated_cattle_type_id[]"]').trigger('change')

            break;
        case 'Process Weaners':
            $('input.js-chosen-paddock').removeAttr('disabled')

            enableField('select[name="updated_paddock_id[]"]')
            enableField('select[name="updated_cattle_type_id[]"]')
            enableField('select[name="updated_year_of_birth[]"]')

            addAgesToSelect(propertyId, $('select[name="updated_cattle_type_id[]"]').val())

            break
        case 'Change Age':
            $('input.js-chosen-paddock').removeAttr('disabled')
            enableField('select[name="updated_year_of_birth[]"]')

            addAgesToSelect(propertyId, $('input[name="current_cattle_type_id[0][]"]').val())
            break
        case 'Transfer Out':
            $('input.js-chosen-paddock').removeAttr('disabled')
            enableField('select[name="updated_property_id[]"]')
            enableField('input[name="sma[]"]')
            enableField('input[name="nvd[]"]')
            enableField('input[name="decks[]"]')

            $('select[name="updated_property_id[]"]').closest('div.mb-2').find('label').text('New Property')

            break
        case 'Transfer In':
            $('input.js-chosen-paddock').removeAttr('disabled')
            enableField('select[name="updated_property_id[]"]')
            enableField('input[name="sma[]"]')
            enableField('input[name="nvd[]"]')
            enableField('input[name="decks[]"]')

            $('select[name="updated_property_id[]"]').closest('div.mb-2').find('label').text('From Property')

            break
    }

    if ( action === 'Brand' || action === 'Bought' || action === 'Transfer In') {
        $('div[data-action="notMortality"]').find('input[name="quantity[]"]')
            .attr('max', 9999)
            .data('total', 9999)
    } else {
        $('div[data-action="notMortality"]').find('input[name="quantity[]"]')
            .attr('max', $('div[data-action="notMortality"]').data('total'))
            .data('total', $('div[data-action="notMortality"]').data('total'))
    }
}

function enableField(field)
{
    const container = $('.container[data-action="notMortality"]')

    $(container).find(field)
        .removeAttr('disabled')
        .closest('div.mb-2')
        .removeClass('d-none')
}
