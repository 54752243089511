import {addFutureYearToSelect} from "../cattle-type/table-actions-cattle-type";

$('.modal-mob').on('click', '.save-btn', async function (e) {
    let target = $('.modal-mob .js-select-mob');
    let action = $('.action-in-table.current')
    let mobId = $(action).find('input[name="mob[]"]').val() === '' ? $('.modal-mob .js-select-mob').val() : $(action).find('input[name="mob[]"]').val();



    if ( $('.modal-mob .js-select-mob').val() === null ) {
        mobId = ''
    }


    if ( mobId !== '0' && mobId !== '' ) {
        $(action).find('.js-action-select-cattle-type').attr('disabled', '');
        $(action).find('td[data-current-year-of-birth] select:first').attr('disabled', '');

        $(action).find('td[data-quantity] input#quantity').attr('disabled', '');


    } else {
        $(action).find('.js-action-select-cattle-type').removeAttr('disabled', '');
        $(action).find('td[data-current-year-of-birth] select:first').removeAttr('disabled', '');

        $(action).find('td[data-quantity] input#quantity').removeAttr('disabled', '');

    }

    $('.action-in-table.current [name="mob[]"]').val(mobId)

    let response = await axios.get(`/get-mob-cattle-list-ajax/${mobId}`)

    $(action).find(`.js-paddock-block-for-transfer-out select option[value=${response.data.upId}]`).addClass('d-none')
    $(action).find(`.js-paddock-block-for-transfer-out select option`).not(`[value=${response.data.upId}]`).removeClass('d-none')





    let MOBcattleTypeData = await axios.get(`/get-mob-cattle-type-ages-ajax/${mobId}`);

    if ( $(action).find('.js-action-select-in-table').val() === 'Change Age'
        || $(action).find('.js-action-select-in-table').val() === 'Reclass'
        || $(action).find('.js-action-select-in-table').val() === 'Weaned'
        || $(action).find('.js-action-select-in-table').val() === 'Process Weaners'
    ) {

        //let currentUpdatedAge = $(action).find('select[name="updated_year_of_birth[]"]:not([disabled])').val()

        let cattleTypeMinYearOfBirth = MOBcattleTypeData.data.data.min_year_of_birth;
        let currentYear = new Date().getFullYear();

        let selectOptions = '';
        selectOptions += `<option value="mixed">mixed</option>`;
        selectOptions += addFutureYearToSelect();
        for (let i = currentYear; i >= cattleTypeMinYearOfBirth; i--) {
            selectOptions += `<option value="${i}">${ i}</option>`;
        }


        $(action).find('select[name="updated_year_of_birth[]"]').empty().append(selectOptions).val('mixed');
    }


});
