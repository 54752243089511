import axios from "axios";

$('.js-send-staff-request-btn').on('click', function(e) {
    e.preventDefault();

    collectData()
    $('#properties').closest('td').find('.js-small-text').remove();
    const groupsIds = [];
    const propertiesIds = [];

    $('#groups option:selected').each(function(index, el) {
        groupsIds.push($(this).val());
    });
    $('#properties option:selected').each(function(index, el) {
        propertiesIds.push($(this).val());
    });

    if ( groupsIds.length === 0 || propertiesIds.length === 0 ) {
        $('.js-send-staff-request-form').trigger('submit');
        return;
    }

    axios.get('/check-properties-in-groups', {
        params: {
            groups_ids: groupsIds,
            properties_ids: propertiesIds
        }
    })
        .then(response => {
            if ( response.data.extra_properties.length === 0 ) {
                $('.js-send-staff-request-form').trigger('submit');
                return;
            }

            let propertiesNames = '';
            response.data.extra_properties.forEach(function(el, index) {
                propertiesNames = index !== (response.data.extra_properties.length - 1)
                    ? propertiesNames + el.name + ', '
                    : propertiesNames + el.name;
            })
            let toBeVerb = response.data.extra_properties.length > 1 ? 'are' : 'is';
            $('#properties').closest('td').append(`<span class="js-small-text d-block">${propertiesNames} ${toBeVerb} already shared as a part of the Group</span>`);
        });
});

function collectData()
{

}
