import {blockActionsAlert} from "../actions-alerts";
import {handleValidation, setInvalid} from "../cattle-type/actions-cattle-type";

$('.js-actions-save-in-table').on('click', async function (e) {
    $(this).attr('disabled', 'disabled')
    setTimeout(() => {
        $(this).removeAttr('disabled')
    }, 1000);


    let blockActions = $('.table.js-ledger-table.mb-1.table-head-sticky').attr('data-block-actions');
    if (blockActions != 1) {
        if (e.detail === 1) {

            let makeActions = true;
            $('.js-report-table .action-in-table').each(function () {
                const action = $(this).find('.js-action-select-in-table').val();
                const cattleTypeId = $(this).find('td[data-cattle-type-from] select:not(.d-none)').val();
                const cattleTypeCell = $(`.js-ledger-table thead tr:first th[data-current-cattle-type-id="${cattleTypeId}"]:last`);
                const cattleTypeName = $(cattleTypeCell).find('span').text().trim();

                const cattleReturnAffect = $(cattleTypeCell).data('cattle-return-affect')
                const cattleReturnAffectReclass = $(cattleTypeCell).data('cattle-return-affect-reclass');

                if (!cattleReturnAffect && cattleReturnAffectReclass && action === 'Reclass') {

                    let reclassConfirm = confirm(`You are about to reclass a non branded cattle type (${cattleTypeName}), this will cause a branding to occur and the cattle return to be affected. Are you sure?`);

                    if (!reclassConfirm) {
                        makeActions = false;
                    }
                }

            });

            if (!makeActions) return;


            await addMissingFieldsToTransactions()
            let [promises, subPromises] = await validateTransactions()

            $.when.apply(null, promises).then(function () {
                $.when.apply(null, subPromises).then(function () {
                    if (modalActionsValidated) {

                        $('.js-add-transactions-table [name="custom_created_at"]').attr('disabled', 'disabled');

                        $('.js-actions-form-in-table').trigger('submit');
                    }
                });
            });
        }
    } else {
        blockActionsAlert()
    }
    return false;
});


async function addMissingFieldsToTransactions()
{
    $('.js-report-table .action-in-table').each(function () {
        let propertyToId = getIdByNameAttributeInTable($(this), 'updated_property_id[]'),
            paddockToId = getIdByNameAttributeInTable($(this), 'updated_paddock_id[]'),
            cattleTypeToId = getIdByNameAttributeInTable($(this), 'updated_cattle_type_id[]'),
            yearOfBirth = getIdByNameAttributeInTable($(this), 'year_of_birth[]'),
            quantity = getIdByNameAttributeInTable($(this), 'quantity[]'),
            mob = getIdByNameAttributeInTable($(this), 'mob[]'),
            femaleStatus = getIdByNameAttributeInTable($(this), 'female_status[]'),
            note = getIdByNameAttributeInTable($(this), 'note[]'),
            head_price = getIdByNameAttributeInTable($(this), 'head_price[]'),
            sold_note = getIdByNameAttributeInTable($(this), 'sold_note[]'),
            purchaseFrom = getIdByNameAttributeInTable($(this), 'purchased_from[]'),
            treatment = getIdByNameAttributeInTable($(this), 'treatment[]'),
            sma = getIdByNameAttributeInTable($(this), 'sma[]'),
            nvd = getIdByNameAttributeInTable($(this), 'nvd[]'),
            decks = getIdByNameAttributeInTable($(this), 'decks[]'),
            agent = getIdByNameAttributeInTable($(this), 'agent[]');


        if (propertyToId === undefined) {
            $(this).append(`<input type="hidden" name="updated_property_id[]" value="" form="actionsFormInTable"/>`);
        }
        if (paddockToId === undefined || paddockToId === null) {
            $(this).append(`<input type="hidden" name="updated_paddock_id[]" value="" form="actionsFormInTable"/>`);
        }

        if (cattleTypeToId === undefined || cattleTypeToId === null) {
            $(this).append(`<input type="hidden" name="updated_cattle_type_id[]" value="" form="actionsFormInTable"/>`);
        }

        if (yearOfBirth === undefined) {
            $(this).append(`<input type="hidden" name="year_of_birth[]" value="" form="actionsFormInTable"/>`);
        }

        if (quantity === undefined) {
            $(this).append(`<input type="hidden" name="quantity[]" value="" form="actionsFormInTable"/>`);
        }
        if (mob === undefined) {
            $(this).append(`<input type="hidden" name="mob[]" value="" form="actionsFormInTable"/>`);
        }

        if (femaleStatus === undefined) {
            $(this).append(`<input type="hidden" name="female_status[]" value="" form="actionsFormInTable"/>`);
        }
        if (note === undefined) {
            $(this).append(`<input type="hidden" name="note[]" value="" form="actionsFormInTable"/>`);
        }
        if (head_price === undefined) {
            $(this).append(`<input type="hidden" name="head_price[]" value="" form="actionsFormInTable"/>`);
        }
        if (sold_note === undefined) {
            $(this).append(`<input type="hidden" name="sold_note[]" value="" form="actionsFormInTable"/>`);
        }
        if (purchaseFrom === undefined) {
            $(this).append(`<input type="hidden" name="purchased_from[]" value="" form="actionsFormInTable"/>`);
        }
        if (treatment === undefined) {
            $(this).append(`<input type="hidden" name="treatment[]" value="" form="actionsFormInTable"/>`);
        }
        if (sma === undefined) {
            $(this).append(`<input type="hidden" name="sma[]" value="" form="actionsFormInTable"/>`);
        }
        if (nvd === undefined) {
            $(this).append(`<input type="hidden" name="nvd[]" value="" form="actionsFormInTable"/>`);
        }
        if (decks === undefined) {
            $(this).append(`<input type="hidden" name="decks[]" value="" form="actionsFormInTable"/>`);
        }
        if (agent === undefined) {
            $(this).append(`<input type="hidden" name="agent[]" value="" form="actionsFormInTable"/>`);
        }
        if ($(this).find('[data-animals-ids]').length === 0) {
            $(this).append(`<input type="hidden" name="cattlesIds[]" value="" form="actionsFormInTable" data-animals-ids="" class="js-stub"`);
        }
    });
}

async function validateTransactions()
{
    window.modalActionsValidated = true;

    let promises = [],
        subPromises = [],
        promisesFromValidation = [],
        actualQuantity = 0;

    $('.action-in-table').not('.d-none').each(async function (index) {
        let selectedAge = $(this).find('select[name="year_of_birth[]"]').val();
        let cattleTypeId = $(this).find('td[data-cattle-type-from] select:not([disabled])').attr('multiple')
            ? $(this).find(`select[name="current_cattle_type_id[${index}][]"]:not([disabled])`).val()
            : ($(this).find(`select[name="current_cattle_type_id[${index}][]"]:not([disabled])`).val() !== undefined ? $(this).find(`select[name="current_cattle_type_id[${index}][]"]:not([disabled])`).val() : $(this).find(`select[name="current_cattle_type_id[${index}][]"]:not([disabled])`).val());

        const transactionId = $(this).find('input[name="transaction_id"]').val() ?? null

        if (Array.isArray(cattleTypeId) && cattleTypeId.length === 1 && cattleTypeId[0] !== 'all') {
            cattleTypeId = cattleTypeId[0];
        } else if (Array.isArray(cattleTypeId) && (cattleTypeId.length > 1 || cattleTypeId[0] === 'all')) {
            return;
        } else if (Array.isArray(cattleTypeId) && cattleTypeId.length === 0) {
            const cattleType = $(this).find(`select[name="current_cattle_type_id[${index}][]"]:not(.d-none)`);

            setInvalid(cattleType, 'Select cattle type');
            modalActionsValidated = false;
        }


        let paddockId = $(this).find('input[name="current_paddock_id"]').val();
        let chosenAction = $(this).find('select[name="action[]"]').val();


        let request = '';
        if (chosenAction === 'Bought' || chosenAction === 'Brand') {
            request = axios.get(`/unallocated-cattles/${cattleTypeId}`)
                .then(async (response) => {
                    actualQuantity = $('.js-report-table').data('is-full-level') !== true ? 999999 : response.data.data.length;

                    promisesFromValidation = await handleValidation($(this), actualQuantity)
                    subPromises = [...subPromises, ...promisesFromValidation];
                })
        } else {
            let GetString = '/paddock-cattle-type?cattle_type_id=' + cattleTypeId + '&paddock_id=' + paddockId + '&year_of_birth=' + selectedAge + '&transactionId=' + transactionId;
            const currentAge = $(this).find('select[name="year_of_birth[]"]').val();

            if (cattleTypeId !== undefined && currentAge !== 'all' && !currentAge.includes('+')) {

                request = axios.get(GetString)
                    .then(async (response) => {
                        actualQuantity = $(this).find('#extra_animals_action').length !== 0 ? 999999 : response.data.data.quantity;

                        promisesFromValidation = await handleValidation($(this), actualQuantity)
                        subPromises = [...subPromises, ...promisesFromValidation];
                    })
                    .catch((response) => {
                        $('#actionsModal .modal-footer .js-small-text')
                            .removeClass('d-none')
                            .html(response.response.data.message)
                    });
            } else {
                actualQuantity = 2147483647;

                promisesFromValidation = await handleValidation($(this), actualQuantity)
                subPromises = [...subPromises, ...promisesFromValidation];
            }
        }


        promises = [...promises, request];
    });


    return [promises, subPromises]
}

function getIdByNameAttributeInTable(actionInstance, nameAttribute) {
    return actionInstance.find(`[name="${nameAttribute}"]:not([disabled])`).val();
}
