
import {TempusDominus} from "@eonasdan/tempus-dominus";
import moment from "moment";

if ( window.location.pathname === '/cattle-database'
    || window.location.pathname === '/cattle-database-search'
) {
    initDateTimePicker('datetimepicker0');
    initDateTimePicker('datetimepicker01');
}

export function initDateTimePicker(el) {

    const dateTimePicker = new TempusDominus(document.getElementById(el), {
        container: document.getElementById(el),
        useCurrent: false,
        display: {
            viewMode: 'years',
            icons: {
                type: 'icons',
                time: 'fas fa-clock',
                date: 'fas fa-calendar',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-check',
                clear: 'fas fa-trash',
                close: 'fas fa-xmark'
            },
            theme: 'light',
            buttons: {
                today: true,
                clear: true,
                close: true
            },
        },
    });

    dateTimePicker.dates.formatInput = date => moment(date).format('DD-MM-YY HH:mm');
}
