import axios from 'axios'
import {blockActionsAlert, updateReportsAlert} from './actions-alerts'
import {initPaddockDragging} from './drag&drop-order-in-table'
import {datepickerInit} from './components-settings'
import {changeWidthOfPaddockGroupsTable} from "./ledger/change-width-of-paddocks-groups";


require('./ledger/check-auth-session')

$(window).on('load', function () {
    if ( $('#ledger-table').length !== 0 ) {

        changeWidthOfPaddockGroupsTable($('#ledger-table')[0])
        initPaddockDragging($('#ledger-table')[0])

        if ( $('.js-ledger-table').data('receive-notification') ) {
            $('.js-ledger-table').before(`
                <div class="alert alert-danger alert-dismissible">
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    <strong>
                        The cattle will now show up at the top of the Paddock Book in the Receive Paddock,
                        you will need to use the “Moved” Action to move them to the appropriate paddock.
                    </strong>
                </div>
            `)
        }
    }



    if ( $('.bi-calendar-check').length !== 0 ) {
        $('.bi-calendar-check').each(function(e) {
            let el = $(this).closest('div').find('a:last')

            if ( $(el)[0].offsetWidth == $(el)[0].scrollWidth ) {
                $(el).removeAttr('title')
            }
        })
    }

    $('.js-ledger-table-display').css('visibility', '')
})





export function colorizeIconsAndColumns() {
    $('[data-cattle-type-color]').each(function () {
        let color = $(this).data('cattle-type-color')
        let elPicker = $(this).find('.color-picker')
        let cattleTypeId = $(this).data('current-cattle-type-id')

        colorizeIcon(elPicker, color)
        colorizeHeaderColumn(cattleTypeId, color)

        if ( cattleTypeId != '' ) {
            colorizeBodyColumn(cattleTypeId, color)
        }

        colorizeFooterColumn(cattleTypeId, color)
    })
}

colorizeIconsAndColumns()

$('body').on('move.spectrum', '.js-ledger-table .color-picker', (event, tinycolor) => {
    let target = event.target
    const color = tinycolor?.toHexString()
    const cattleTypeId = $(target).closest('th').data('current-cattle-type-id')
    $(target).closest('th').attr('data-cattle-type-color', `${color}`)

    colorizeIcon($(target), color)
    colorizeHeaderColumn(cattleTypeId, color, true)
    colorizeBodyColumn(cattleTypeId, color)
    colorizeFooterColumn(cattleTypeId, color)
})

/**
 * @param  {HTMLElement} icon
 * @param  {string} color
 */
export function colorizeIcon(icon, color) {
    color = color === '' ? 'white' : color

    $(icon)
        .closest('th')
        .find('.fa-arrow-right, .fa-arrow-left, .fa-expand-alt, .fa-compress-alt, .fa-palette, span')
        .css('color', color)

    return icon.css('color', color)
}

export function colorizeHeaderColumn(cattleTypeId, color, manual = false) {
    color = color === '' ? '' : color

    $(`.js-ledger-table thead th[data-current-cattle-type-id="${cattleTypeId}"][data-group-id=""]`).css('background-color', color)
    $(`.js-ledger-table thead td[data-current-cattle-type-id="${cattleTypeId}"]:not([data-group-id])`).css('background-color', color)

    if (manual) {
        $(`.js-ledger-table thead th[data-current-cattle-type-id="${cattleTypeId}"][data-group-id]`).css('background-color', color)
        $(`.js-ledger-table thead td[data-current-cattle-type-id="${cattleTypeId}"][data-group-id]`).css('background-color', color)
    } else {
        $(`.js-ledger-table thead th[data-current-cattle-type-id="${cattleTypeId}"][data-group-id].d-none`).css('background-color', color)
        $(`.js-ledger-table thead td[data-current-cattle-type-id="${cattleTypeId}"][data-group-id].d-none`).css('background-color', color)
    }
}

export function colorizeBodyColumn(cattleTypeId, color) {
    color = color === '' || color === null ? 'white' : color

    $(`.js-ledger-table tbody td[data-current-cattle-type-id="${cattleTypeId}"]`).css('background-color', color)


    $(`.js-ledger-table tbody td[data-cattle-type-id="${cattleTypeId}"], .js-ledger-table tbody td[data-current-cattle-type-id="${cattleTypeId}"]`).each(function(i) {

        if ( $(this).data('current-cattle-type-id') != undefined ) {

            if ( $(this).find('a').length != 0 ) {
                if( $(this).find('a').text().trim() == 0 ) {
                    $(this).find('a').css('color', color)
                } else {
                    $(this).find('a').css('color', '')
                }
            } else {
                if (color != 'white') {
                }
                if( $(this).text().trim() == 0 ) {
                    $(this).css('color', color)
                } else {
                    $(this).css('color', '')

                }

            }

        } else {
            if( $(this).text().trim() == 0 ) {
                $(this).css('color', 'white')
            } else {
                $(this).css('color', '')
            }
        }

    })

}

export function colorizeFooterColumn(cattleTypeId, color) {
    color = color === '' ? '' : color
    $(`.js-ledger-table tfoot tr td[data-current-cattle-type-id="${cattleTypeId}"]`).css('background-color', color)
}

$('body').on('hide.spectrum', '.js-ledger-table .color-picker', (event, tinycolor) => {
    if (tinycolor === null) return

    const color = tinycolor.toHexString()
    const cattleTypeId = $(event.target).closest('th').data('current-cattle-type-id')

    axios.post('/color', {
        'color': color,
        'cattle_type_id': cattleTypeId
    })
})

$('body').on('show.spectrum', '.js-ledger-table .color-picker', event => {
    $('.active-picker').removeClass('active-picker')
    $(event.target).addClass('active-picker')
})

$('.sp-cancel').on('click', function(event) {
    event.preventDefault()

    const cattleTypeId = $('.active-picker').closest('th').data('current-cattle-type-id')

    colorizeIcon($('.active-picker'), '')
    colorizeHeaderColumn(cattleTypeId, '', true)
    colorizeBodyColumn(cattleTypeId, '')
    colorizeFooterColumn(cattleTypeId, '')

    axios.post('/color', {
        'color': null,
        'cattle_type_id': cattleTypeId
    })

    $(this).closest('.sp-container').spectrum("destroy");
})

require('./ledger/switch-cattle-type-groups')
require('./ledger/switch-age-borders')


function checkForMovingAbility(sourceElement, targetElement) {
    const sourcePaddockName = sourceElement.closest('tr').find('td:first a').text()
    const targetPaddockName = targetElement.closest('tr').find('td:first a').text()

    $('.alert-danger, .alert-success').remove()

    if (sourcePaddockName === targetPaddockName) {
        $('#ledger-table').before(`
            <div class="alert alert-danger alert-dismissible">
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                <strong>It’s impossible to Move animals to the initial paddock</strong>
            </div>
        `)

        return false
    }

    return true
}

export async function onMovedActionByDragging(sourceElement, targetElement, isTotal) {
    let blockActions = $('#ledger-table').attr('data-block-actions')

    if (blockActions != 1) {
        if (!checkForMovingAbility(sourceElement, targetElement)) return

        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear().toString().substr(-2)

        let currentDate = `${day}-${month}-${year}`


        let negateExtraAnimalsSwitchHTML = ''


        const targetPaddockId = Number(targetElement.parents('tr').attr('data-paddock-id'))
        const cattleTypeId = Number(sourceElement.attr('data-current-cattle-type-id'))
        const age = sourceElement.data('age') === '' ? 'mixed' : parseInt(sourceElement.data('age'))

        if ( $('.js-report-table').data('is-full-level') !== true ) {
            await axios.get(`/check-for-extra-animals?paddockId=${targetPaddockId}&cattleTypeId=${cattleTypeId}&yearOfBirth=${age}`)
                .then(async (response) => {
                    if (response.data.result) {
                        negateExtraAnimalsSwitchHTML = '<div class="form-check form-switch" style="margin-top: 15px;">\n' +
                            '                    <span class="form-check-label fw-600">Negate the extra animals from the target paddock?</span>' +
                            '                    <input name="negate_extra_animals_action" class="form-check-input" type="checkbox">' +
                            '                </div>'
                    }
                })
        }



        $.confirm({
            title: 'Set the Date & MS#',
            content: '' +
                `<div><span>Date </span><input class="form-control datepicker datepicker--format_short" style="max-width: 150px" placeholder="dd-mm-yy" autoComplete="off" size="8" name="custom_created_at_datepicker" type="text" value="${currentDate}"></div>` +
                `<div class="mt-3"><span>MS# </span><input class="form-control" style="max-width: 150px" placeholder="MS#" maxlength="6" size="8" name="ms" type="text" value=""></div>` + negateExtraAnimalsSwitchHTML,
            buttons: {
                confirm: async function () {
                    const date = this.$content.find('input[name="custom_created_at_datepicker"]').val()

                    const day = date.split('-')[0]
                    const month = date.split('-')[1]
                    const year = date.split('-')[2]

                    const formattedDate = `${year}-${month}-${day}`
                    const ms = this.$content.find('input[name="ms"]').val()
                    const extraAnimalsSwitch = this.$content.find('input[name="negate_extra_animals_action"]').prop('checked')

                    const sourcePropertyId = Number($(sourceElement).closest('table').data('id'))
                    const sourcePaddockId = Number(sourceElement.parents('tr').attr('data-paddock-id'))

                    let sourceQuantity = parseInt(sourceElement.find('a').text())
                    let targetQuantity = parseInt(targetElement.find('a').text())

                    if (sourceQuantity === 0) return

                    let formData = new FormData()

                    if (isTotal) {
                        formData.append('current_cattle_type_id[0][]', 'all')
                        formData.append('year_of_birth[]', 'all')
                    } else {
                        formData.append('current_cattle_type_id[]', cattleTypeId)
                        formData.append('year_of_birth[]', age)
                        formData.append('quantity[]', sourceQuantity)

                        if (extraAnimalsSwitch) {
                            formData.append('negate_extra_animals_action', extraAnimalsSwitch)
                        }

                    }

                    formData.append('custom_created_at', formattedDate)
                    formData.append('ms_number', ms)
                    formData.append('action[]', ['Moved'])
                    formData.append('property_id', sourcePropertyId)
                    formData.append('current_paddock_id', sourcePaddockId)
                    formData.append('updated_paddock_id[]', targetPaddockId)
                    formData.append('mob[]', 0)
                    formData.append('drag_and_drop', true)

                    let error = false

                    $.ajax({
                        'type': 'POST',
                        'url': '/cattle-type/action',
                        'data': formData,
                        async: false,
                        processData: false,
                        contentType: false,
                        dataType: "json",
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                        statusCode: {
                            500: function () {
                                error = true
                            }
                        }
                    })

                    if (error) {
                        $('#ledger-table').before(`
                            <div class="alert alert-danger alert-dismissible">
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                <strong>An error occurred during the move</strong>
                            </div>
                        `)

                        return
                    }

                    sourceElement.attr('draggable', 'false')
                    $(sourceElement).find('a').attr('draggable', 'false')

                    targetElement.attr('draggable', 'true')
                    $(targetElement).find('a').attr('draggable', 'true')

                    if (isTotal) {
                        sourceQuantity = parseInt(sourceElement.text())
                        targetQuantity = parseInt(targetElement.text())

                        sourceElement.text('0')
                        targetElement.text(targetQuantity + sourceQuantity)

                        if ($(sourceElement).closest('table').hasClass('group-table') && !$(targetElement).closest('table').hasClass('group-table')) {
                            let currentTotalQuantity = parseInt((sourceElement).closest('table').find('thead tr td:eq(1)').text())

                            $(sourceElement).closest('table').find('thead tr td:eq(1)').text(currentTotalQuantity - sourceQuantity)
                        } else if ($(targetElement).closest('table').hasClass('group-table') && !$(sourceElement).closest('table').hasClass('group-table')) {
                            let currentTotalQuantity = parseInt((targetElement).closest('table').find('thead tr td:eq(1)').text())

                            $(targetElement).closest('table').find('thead tr td:eq(1)').text(currentTotalQuantity + sourceQuantity)
                        } else if ($(sourceElement).closest('table').hasClass('group-table')
                            && $(targetElement).closest('table').hasClass('group-table')
                            && $(sourceElement).closest('table').closest('tr').data('group-id') !== $(targetElement).closest('table').closest('tr').data('group-id')
                        ) {
                            let currentSourceTotalQuantity = parseInt((sourceElement).closest('table').find('thead tr td:eq(1)').text())
                            let currentTargetTotalQuantity = parseInt((targetElement).closest('table').find('thead tr td:eq(1)').text())

                            $(sourceElement).closest('table').find('thead tr td:eq(1)').text(currentSourceTotalQuantity - sourceQuantity)
                            $(targetElement).closest('table').find('thead tr td:eq(1)').text(currentTargetTotalQuantity + sourceQuantity)
                        }


                        let usedCattleTypeIds = []
                        sourceElement.nextAll().each(function (i) {
                            let index = 2 + parseInt(i)
                            let isGroup = $(this).find('a').length !== 1

                            targetElement = targetElement.closest('tr').find(`td:eq(${index})`)

                            if (isGroup) {
                                const sourceQuantity = parseInt($(this).text())
                                const targetQuantity = parseInt(targetElement.text())

                                $(this).text('0')
                                targetElement.text(targetQuantity + sourceQuantity)

                                if ($(sourceElement).closest('table').hasClass('group-table') && !$(targetElement).closest('table').hasClass('group-table')) {
                                    let currentTotalQuantity = parseInt((sourceElement).closest('table').find(`thead tr td:eq(${index})`).text())

                                    $(sourceElement).closest('table').find(`thead tr td:eq(${index})`).text(currentTotalQuantity - sourceQuantity)
                                } else if ($(targetElement).closest('table').hasClass('group-table') && !$(sourceElement).closest('table').hasClass('group-table')) {
                                    let currentTotalQuantity = parseInt((targetElement).closest('table').find(`thead tr td:eq(${index})`).text())

                                    $(targetElement).closest('table').find(`thead tr td:eq(${index})`).text(currentTotalQuantity + sourceQuantity)
                                } else if ($(sourceElement).closest('table').hasClass('group-table')
                                    && $(targetElement).closest('table').hasClass('group-table')
                                    && $(sourceElement).closest('table').closest('tr').data('group-id') !== $(targetElement).closest('table').closest('tr').data('group-id')
                                ) {
                                    let currentSourceTotalQuantity = parseInt((sourceElement).closest('table').find(`thead tr td:eq(${index})`).text())
                                    let currentTargetTotalQuantity = parseInt((targetElement).closest('table').find(`thead tr td:eq(${index})`).text())

                                    $(sourceElement).closest('table').find(`thead tr td:eq(${index})`).text(currentSourceTotalQuantity - sourceQuantity)
                                    $(targetElement).closest('table').find(`thead tr td:eq(${index})`).text(currentTargetTotalQuantity + sourceQuantity)
                                }

                            } else {
                                const sourceQuantity = parseInt($(this).find('a').text())
                                const targetQuantity = parseInt(targetElement.find('a').text())

                                $(this).find('a').text('0')
                                $(this).find('a').css('color', $(this).css('background-color'))

                                let sourceElementParams = new URLSearchParams(new URL(window.location.origin + $(this).find('a').attr('href')).search)
                                sourceElementParams.set('quantity', '0')
                                $(this).find('a').attr('href', '/ledger/' + sourcePropertyId + `/paddock-select/${sourcePaddockId}?` + sourceElementParams.toString())

                                targetElement.find('a').text(targetQuantity + sourceQuantity)
                                if (targetQuantity == 0) {
                                    targetElement.find('a').css('color', '')
                                }

                                if ($(sourceElement).closest('table').hasClass('group-table') && !$(targetElement).closest('table').hasClass('group-table')) {
                                    let currentTotalQuantity = parseInt((sourceElement).closest('table').find(`thead tr td:eq(${index})`).text())

                                    $(sourceElement).closest('table').find(`thead tr td:eq(${index})`).text(currentTotalQuantity - sourceQuantity)
                                } else if ($(targetElement).closest('table').hasClass('group-table') && !$(sourceElement).closest('table').hasClass('group-table')) {
                                    let currentTotalQuantity = parseInt((targetElement).closest('table').find(`thead tr td:eq(${index})`).text())

                                    $(targetElement).closest('table').find(`thead tr td:eq(${index})`).text(currentTotalQuantity + sourceQuantity)
                                } else if ($(sourceElement).closest('table').hasClass('group-table')
                                    && $(targetElement).closest('table').hasClass('group-table')
                                    && $(sourceElement).closest('table').closest('tr').data('group-id') !== $(targetElement).closest('table').closest('tr').data('group-id')
                                ) {
                                    let currentSourceTotalQuantity = parseInt((sourceElement).closest('table').find(`thead tr td:eq(${index})`).text())
                                    let currentTargetTotalQuantity = parseInt((targetElement).closest('table').find(`thead tr td:eq(${index})`).text())

                                    $(sourceElement).closest('table').find(`thead tr td:eq(${index})`).text(currentSourceTotalQuantity - sourceQuantity)
                                    $(targetElement).closest('table').find(`thead tr td:eq(${index})`).text(currentTargetTotalQuantity + sourceQuantity)
                                }

                                let targetElementParams = new URLSearchParams(new URL(window.location.origin + targetElement.find('a').attr('href')).search)
                                targetElementParams.set('quantity', (targetQuantity + sourceQuantity))
                                targetElement.find('a').attr('href', '/ledger/' + sourcePropertyId + `/paddock-select/${targetPaddockId}?` + targetElementParams.toString())
                            }

                            $(this).attr('draggable', 'false')
                            $(this).find('a').attr('draggable', 'false')

                            targetElement.attr('draggable', 'true')
                            $(targetElement).find('a').attr('draggable', 'true')

                            let innerCattleTypeId = $(this).data('cattle-type-id')
                            if (!usedCattleTypeIds.includes(innerCattleTypeId)) {
                                usedCattleTypeIds.push(innerCattleTypeId)
                            }


                        })

                        let i = 0
                        while (i < usedCattleTypeIds.length) {
                            colorizeBodyColumn(usedCattleTypeIds[i], $(`.js-ledger-table thead:first tr:first th[data-current-cattle-type-id="${usedCattleTypeIds[i]}"]`).css('background-color'))
                            i++;
                        }


                    } else {
                        sourceElement.find('a').text('0')
                        sourceElement.attr('data-quantity', '0')

                        let sourceElementParams = new URLSearchParams(new URL(window.location.origin + sourceElement.find('a').attr('href')).search)
                        sourceElementParams.set('quantity', '0')
                        sourceElement.find('a').attr('href', '/ledger/' + sourcePropertyId + `/paddock-select/${sourcePaddockId}?` + sourceElementParams.toString())
                        sourceElement.find('a').css('color', sourceElement.css('background-color'))

                        targetElement.find('a').text(targetQuantity + sourceQuantity)
                        targetElement.attr('data-quantity', `${targetQuantity + sourceQuantity}`)

                        let ageBorderSumCellSource = $(sourceElement).prevAll(`.age-border-total[data-current-cattle-type-id="${cattleTypeId}"]`)
                        if (ageBorderSumCellSource.length !== 0) {
                            let newAgeBorderSumCellSource = parseInt($(ageBorderSumCellSource).text()) - sourceQuantity
                            $(ageBorderSumCellSource).text(newAgeBorderSumCellSource)
                        }
                        let ageBorderSumCellTarget = $(targetElement).prevAll(`.age-border-total[data-current-cattle-type-id="${cattleTypeId}"]`)
                        if (ageBorderSumCellTarget.length !== 0) {
                            let newAgeBorderSumCellTarget = parseInt($(ageBorderSumCellTarget).text()) + sourceQuantity
                            $(ageBorderSumCellTarget).text(newAgeBorderSumCellTarget)
                        }

                        let targetElementParams = new URLSearchParams(new URL(window.location.origin + targetElement.find('a').attr('href')).search)
                        targetElementParams.set('quantity', (targetQuantity + sourceQuantity))
                        targetElement.find('a').attr('href', '/ledger/' + sourcePropertyId + `/paddock-select/${targetPaddockId}?` + targetElementParams.toString())
                        if (targetQuantity == 0) {
                            targetElement.find('a').css('color', '')
                        }

                        const sourceTotal = sourceElement.closest('tr').find('td:eq(1)')
                        const targetTotal = targetElement.closest('tr').find('td:eq(1)')

                        sourceTotal.text(parseInt(sourceTotal.text()) - sourceQuantity)
                        sourceTotal.attr('data-quantity', `${parseInt(sourceTotal.attr('data-quantity')) - sourceQuantity}`)

                        if (sourceTotal.text() === 0) {
                            sourceTotal.attr('draggable', false)
                        }

                        targetTotal.text(parseInt(targetTotal.text()) + sourceQuantity)
                        targetTotal.attr('data-quantity', `${parseInt(targetTotal.attr('data-quantity')) + sourceQuantity}`)
                        targetTotal.attr('draggable', true)

                        if (sourceElement.data('group-id') !== '') {
                            const groupId = sourceElement.data('group-id')

                            const sourceGroupTd = sourceElement.closest('tr').find(`td[data-group-id="${groupId}"][data-age="${age}"]:first`)
                            const targetGroupTd = targetElement.closest('tr').find(`td[data-group-id="${groupId}"][data-age="${age}"]:first`)

                            sourceGroupTd.text(parseInt(sourceGroupTd.text()) - sourceQuantity)
                            sourceGroupTd.attr('data-quantity', `${parseInt(sourceGroupTd.attr('data-quantity')) - sourceQuantity}`)
                            targetGroupTd.text(parseInt(targetGroupTd.text()) + sourceQuantity)
                        }

                        if ($(sourceElement).closest('table').hasClass('group-table') && !$(targetElement).closest('table').hasClass('group-table')) {
                            let currentTotalQuantity = parseInt((sourceElement).closest('table').find('thead tr td:eq(1)').text())

                            let cattleTypeId = $(sourceElement).data('current-cattle-type-id')
                            let age = $(sourceElement).data('age')

                            let singleCellTotalQuantity = parseInt($(sourceElement).closest('table').find(`thead tr td[data-current-cattle-type-id="${cattleTypeId}"][data-age="${age}"]`).text())

                            $(sourceElement).closest('table').find('thead tr td:eq(1)').text(currentTotalQuantity - sourceQuantity)
                            $(sourceElement).closest('table').find(`thead tr td[data-current-cattle-type-id="${cattleTypeId}"][data-age="${age}"]`).text(singleCellTotalQuantity - sourceQuantity)
                            // if ( singleCellTotalQuantity - sourceQuantity == 0 ) {
                            //     $(sourceElement).closest('table').find(`thead tr td[data-current-cattle-type-id="${cattleTypeId}"][data-age="${age}"]`).css('color', )
                            // }

                            if (ageBorderSumCellSource.length !== 0) {
                                let newAgeBorderSumGroupCellSource = parseInt($(sourceElement).closest('table').find(`thead tr .age-border-total[data-current-cattle-type-id="${cattleTypeId}"]`).text()) - sourceQuantity
                                $(sourceElement).closest('table').find(`thead tr .age-border-total[data-current-cattle-type-id="${cattleTypeId}"]`).text(newAgeBorderSumGroupCellSource)
                            }

                            if (sourceElement.data('group-id') !== '') {
                                let groupId = sourceElement.data('group-id')
                                let singleGroupCellTotalQuantity = parseInt($(sourceElement).closest('table').find(`thead tr td[data-group-id="${groupId}"][data-age="${age}"]:first`).text())

                                $(sourceElement).closest('table').find(`thead tr td[data-group-id="${groupId}"][data-age="${age}"]:first`).text(singleGroupCellTotalQuantity - sourceQuantity)
                            }

                        } else if ($(targetElement).closest('table').hasClass('group-table') && !$(sourceElement).closest('table').hasClass('group-table')) {
                            let currentTotalQuantity = parseInt((targetElement).closest('table').find('thead tr td:eq(1)').text())

                            let cattleTypeId = $(targetElement).data('current-cattle-type-id')
                            let age = $(targetElement).data('age')

                            let singleCellTotalQuantity = parseInt($(targetElement).closest('table').find(`thead tr td[data-current-cattle-type-id="${cattleTypeId}"][data-age="${age}"]`).text())

                            $(targetElement).closest('table').find('thead tr td:eq(1)').text(currentTotalQuantity + sourceQuantity)
                            $(targetElement).closest('table').find(`thead tr td[data-current-cattle-type-id="${cattleTypeId}"][data-age="${age}"]`).text(singleCellTotalQuantity + sourceQuantity)

                            if (ageBorderSumCellTarget.length !== 0) {
                                let newAgeBorderSumGroupCellTarget = parseInt($(targetElement).closest('table').find(`thead tr .age-border-total[data-current-cattle-type-id="${cattleTypeId}"]`).text()) + sourceQuantity
                                $(targetElement).closest('table').find(`thead tr .age-border-total[data-current-cattle-type-id="${cattleTypeId}"]`).text(newAgeBorderSumGroupCellTarget)
                            }

                            if (targetElement.data('group-id') !== '') {
                                let groupId = targetElement.data('group-id')
                                let singleGroupCellTotalQuantity = parseInt($(targetElement).closest('table').find(`thead tr td[data-group-id="${groupId}"][data-age="${age}"]:first`).text())

                                $(targetElement).closest('table').find(`thead tr td[data-group-id="${groupId}"][data-age="${age}"]:first`).text(singleGroupCellTotalQuantity + sourceQuantity)
                            }


                        } else if ($(sourceElement).closest('table').hasClass('group-table')
                            && $(targetElement).closest('table').hasClass('group-table')
                            && $(sourceElement).closest('table').closest('tr').data('group-id') !== $(targetElement).closest('table').closest('tr').data('group-id')
                        ) {
                            let currentSourceTotalQuantity = parseInt((sourceElement).closest('table').find('thead tr td:eq(1)').text())
                            let currentTargetTotalQuantity = parseInt((targetElement).closest('table').find('thead tr td:eq(1)').text())

                            $(sourceElement).closest('table').find('thead tr td:eq(1)').text(currentSourceTotalQuantity - sourceQuantity)
                            $(targetElement).closest('table').find('thead tr td:eq(1)').text(currentTargetTotalQuantity + sourceQuantity)

                            let cattleTypeId = $(sourceElement).data('current-cattle-type-id')
                            let age = $(sourceElement).data('age')

                            let singleSourceCellTotalQuantity = parseInt($(sourceElement).closest('table').find(`thead tr td[data-current-cattle-type-id="${cattleTypeId}"][data-age="${age}"]`).text())
                            let singleTargetCellTotalQuantity = parseInt($(targetElement).closest('table').find(`thead tr td[data-current-cattle-type-id="${cattleTypeId}"][data-age="${age}"]`).text())

                            $(sourceElement).closest('table').find(`thead tr td[data-current-cattle-type-id="${cattleTypeId}"][data-age="${age}"]`).text(singleSourceCellTotalQuantity - sourceQuantity)
                            $(targetElement).closest('table').find(`thead tr td[data-current-cattle-type-id="${cattleTypeId}"][data-age="${age}"]`).text(singleTargetCellTotalQuantity + sourceQuantity)

                            if (sourceElement.data('group-id') !== '') {
                                let groupId = sourceElement.data('group-id')
                                let singleSourceGroupCellTotalQuantity = parseInt($(sourceElement).closest('table').find(`thead tr td[data-group-id="${groupId}"][data-age="${age}"]:first`).text())
                                let singleTargetGroupCellTotalQuantity = parseInt($(targetElement).closest('table').find(`thead tr td[data-group-id="${groupId}"][data-age="${age}"]:first`).text())

                                $(sourceElement).closest('table').find(`thead tr td[data-group-id="${groupId}"][data-age="${age}"]:first`).text(singleSourceGroupCellTotalQuantity - sourceQuantity)
                                $(targetElement).closest('table').find(`thead tr td[data-group-id="${groupId}"][data-age="${age}"]:first`).text(singleTargetGroupCellTotalQuantity + sourceQuantity)
                            }
                        }

                        colorizeBodyColumn(cattleTypeId, sourceElement.css('background-color'))
                    }

                    $(targetElement).closest('tr').attr('data-empty', 0)
                    if ($(targetElement).closest('table').hasClass('group-table')) {
                        $(targetElement).closest('tr[data-group-id]').attr('data-empty', 0)
                    }

                    hideEmptyPaddockRow(sourceElement)

                    $('.alert-success').remove()

                    $('#ledger-table').before(`
                        <div class="alert alert-success alert-dismissible fade show">
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            <strong>Moved action is successfully completed</strong>
                        </div>
                    `)

                    let confirmStatus = $('#ledger-table').attr('data-confirm-status')

                    if (confirmStatus == 1 && $(`#ledger-table thead tr:first th[data-current-cattle-type-id=${cattleTypeId}]`).data('cattle-return-affect')) {
                        updateReportsAlert()
                    }

                    if ( extraAnimalsSwitch ) {
                        setTimeout(() => {
                            location.reload();
                        }, 1500)
                    }
                },
                cancel: function () {
                    // close
                },
            },
            onContentReady: function () {
                //this.buttons.confirm.disable();
                datepickerInit();
            }
        })
    } else {
        blockActionsAlert()
    }
}

function hideEmptyPaddockRow(sourceElement)
{
    const total = $(sourceElement).closest('tr').find('td:eq(1)').text().trim()
    const switchState = $('#ledger-table').data('hide-empty-paddocks')

    if ( total == 0) {
        $(sourceElement).closest('tr').attr('data-empty', 1)
        if ( switchState ) {
            $(sourceElement).closest('tr').addClass('d-none')
        }

        if ( $(sourceElement).closest('table').hasClass('group-table')
            && $(sourceElement).closest('table').find('tr:first td:eq(1)').text().trim() == 0 )
        {

            $(sourceElement).closest('tr[data-group-id]').attr('data-empty', 1)
            if ( switchState ) {
                $(sourceElement).closest('tr[data-group-id]').addClass('d-none')
            }
        }

    }
}

$('body').on('input change', '.jconfirm-box [name="custom_created_at_datepicker"]', function (e) {
    $('.jconfirm-box input').css('border-color', '#ced4da')
    $('.jconfirm-box .jconfirm-buttons button:first').removeAttr('disabled')

    $('.jconfirm-box input:first').each(function () {
        if ($(this).val() === '') {
            $(this).css('border-color', 'red')
            $('.jconfirm-box .jconfirm-buttons button:first').attr('disabled', 'disabled')
        }
    })
})

export function initCustomDragAndDrop(certainCattleType = '') {
    if (!certainCattleType) {
        initDragAndDropElements('.js-paddock-total', '.js-paddock-total-dropzone', 'column', function (event) {
            event.preventDefault()

            const sourceElement = $('[data-draggable-state="dragging"]')
            const targetElement = $(this)

            onMovedActionByDragging(sourceElement, targetElement, true)

            setTimeout(() => {
                this.classList.remove('dropzone--highlighted')
            }, 2000)
        })
    }

    initDragAndDropElements(`.js-cattle-type-draggable${certainCattleType}`, `.js-cattle-type-dropzone${certainCattleType}`, 'column', function (event) {
        event.preventDefault()

        const sourceElement = $('[data-draggable-state="dragging"]')
        const targetElement = $(this)

        onMovedActionByDragging(sourceElement, targetElement, false, 123)

        setTimeout(() => {
            this.classList.remove('dropzone--highlighted')
        }, 2000)
    })
}

function initDragAndDropElements(
    draggableElementsSelector = null,
    dropzonesSelector = null,
    scope = 'column',
    dropHandler = null
) {
    if (!draggableElementsSelector ||
        !dropzonesSelector ||
        typeof dropHandler !== 'function'
    ) {
        return
    }

    const draggableElements = Array.from(document.querySelectorAll(`${draggableElementsSelector}`))
    const dropzones = Array.from(document.querySelectorAll(`${dropzonesSelector}`))

    draggableElements.forEach((cell, index) => {
        cell.addEventListener('dragstart', dragStartHandler)
        cell.addEventListener('dragend', dragEndHandler)
    })

    function dragStartHandler(event) {
        $(this).attr('data-draggable-state', 'dragging')
        event.dataTransfer.effectAllowed = 'move'

        const draggableElementIndex = $(this).index()

        $(this).addClass('dropzone--from')

        dropzones.forEach((zone, index) => {
            if (scope === 'column' && $(zone).index() === draggableElementIndex) {
                zone.addEventListener('dragover', dragOverHandler)
                zone.addEventListener('drop', dropHandler)
                zone.addEventListener('dragenter', dragEnterHandler)
                zone.addEventListener('dragleave', dragLeaveHandler)
            }
        })
    }

    function dragEndHandler() {
        $(this).removeAttr('data-draggable-state')
        $(this).removeClass('dropzone--from')

        dropzones.forEach((zone, index) => {
            zone.removeEventListener('dragover', dragOverHandler)
            zone.removeEventListener('drop', dropHandler)
            zone.removeEventListener('dragenter', dragEnterHandler)
            zone.removeEventListener('dragleave', dragLeaveHandler)
        })
    }

    function dragOverHandler(event) {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
    }

    function dragEnterHandler(event) {
        this.classList.add('dropzone--highlighted')
    }

    function dragLeaveHandler(event) {
        this.classList.remove('dropzone--highlighted')
    }
}


let mouseDown = false

$('.js-ledger-table tbody').on('mousedown touchstart', '#ledger-table tbody td:not(:first):not([draggable="true"])', function (event) {
    if (event.target.classList.contains('btn')) return

    event.preventDefault()
    mouseDown = true
})

$('.js-ledger-table tbody').on('mousedown touchstart', '#ledger-table tbody td:not(:first):not([draggable="true"])', function (event) {
    if (event.target.classList.contains('btn')) return

    event.preventDefault()
})

$(window.document).on('mouseup touchend', function (event) {
    // Capture this event anywhere in the document, since the mouse may leave our element
    // while mouse is down and then the 'up' event will not fire within the element.
    mouseDown = false
})

const origin = window.location.origin

$(`form[action="${origin}/login"]`).on('submit', function () {
    $(this).find('button').attr('disabled', 'disabled')
})

if (window.location.pathname.includes('ledger') && $('.table-responsive.my-3 .js-ledger-table tbody tr').length === 1) {
    $(window).on('load', function () {
        $('.fa-folder').trigger('click')
    })
}


require('./ledger/calculate-numbers-in-cells')
require('./ledger/change-paddock-handle')
