import axios from "axios";
import {updatePaddocksSelector} from "../cattle-costs";

const autoComplete = require("@tarekraafat/autocomplete.js");

if(window.location.pathname === '/cattle-database' || window.location.pathname === '/cattle-database-search') {
    $('.cattle-search').each(async function(i, el) {
        let cattleData = await axios.get(`/get-cattle-data?search=yes`);
        await initialAutocomplete(el, cattleData.data.cattle);
    })
}

export async function initialAutocomplete(el, data)
{
    if ( $(el).next('ul').length !== 0 ) {
        $(el).closest('div').after(el).remove()

        el = $('.js-cost-type-container:not(.d-none) .js-search-input')[0]
    }
    let autoCompleteJS = new autoComplete( {
        selector: '#' + $(el).attr('id'),
        placeHolder: "Search for...",
        data: {
            src: data,
        },
        threshold: 0,
        resultsList: {
            element: (list, data) => {
                if (!data.results.length) {
                    // Create "No Results" message element
                    const message = document.createElement("div");
                    // Add class to the created element
                    message.setAttribute("class", "no_result");
                    // Add message text content
                    message.innerHTML = `<span>Results not found</span>`;
                    // Append message element to the results list
                    list.prepend(message);
                } else {
                    let title = document.createElement("p")
                    title.innerHTML = `<strong>${data.results.length}</strong> variations`
                    list.prepend(title)
                }
            },
            tabSelect: true,
            maxResults: undefined,
            noResults: true,
        },
        resultItem: {
            highlight: true,
        },
        events: {
            input: {
                selection: async (event) => {
                    const selection = event.detail.selection.value;
                    autoCompleteJS.input.value = selection;
                    //$(autoCompleteJS.input).trigger('change')

                    if ( $(autoCompleteJS.input).hasClass('js-costs-cattle') ) {
                        await axios.get(`/get-animal-info?animalId=${autoCompleteJS.input.value}`)
                            .then(async response => {
                                const animalData = response.data.animalData ?? []
                                const propertyId = animalData.property_id ?? '0'
                                const paddockId = animalData.paddock_id ?? '0'
                                const cattleTypeId = animalData.cattle_type_id ?? '0'

                                if ( propertyId !== null ) {
                                    await updatePropertySelector(propertyId, paddockId, '.js-costs-property-for-animal-type')
                                }

                                if ( cattleTypeId !== null ) {
                                    $('.js-costs-cattle-type-for-animal-type').val(cattleTypeId).trigger('change')
                                }

                            })
                    } else {
                        await axios.get(`/get-lot-number-info?lotNumber=${autoCompleteJS.input.value}`)
                            .then(async response => {
                                const lotNumberData = response.data.lotNumberData ?? []

                                const propertyId = lotNumberData.property.id ?? '0'
                                const paddockId = lotNumberData.id ?? '0'

                                if ( propertyId !== null ) {
                                    await updatePropertySelector(propertyId, paddockId, '.js-costs-property-for-paddock-type')
                                }

                            })
                    }
                },
                focus: () => {
                    autoCompleteJS.start()
                }
            },
        }
    } );
}

async function updatePropertySelector(propertyId, paddockId, selector)
{
    $(selector).val(propertyId)
    updatePaddocksSelector(propertyId, paddockId)
}
