$('.js-remove-new-email-for-friendship-request').on('click', function(e) {
    let inputCount = $('#inviteToFriendshipModal input[name="email[]"]').length;
    let inputHtml = $('#inviteToFriendshipModal input[name="email[]"]:first').prop('outerHTML');

    if(inputCount === 1) {
        return;
    }

    $('#inviteToFriendshipModal input[name="email[]"]:last').remove();
});
