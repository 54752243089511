$('.js-group-checkbox').on('change', function (e) {
    const groupId = $(this).data('group-id')

    if ( this.checked ) {
        $(`.js-cattle-type-checkbox[data-group-id="${groupId}"]`).prop('checked', true).trigger('change')
    } else {
        $(`.js-cattle-type-checkbox[data-group-id="${groupId}"]`).prop('checked', false).trigger('change')
    }
})

$('.js-cattle-type-checkbox').on('change', function (e) {
    const groupId = $(this).data('group-id')

    if ( $(`.js-cattle-type-checkbox[data-group-id="${groupId}"]:checked`).length !== 0 ) {
        $(`.js-group-checkbox[data-group-id="${groupId}"]`).prop('checked', true)
    } else {
        $(`.js-group-checkbox[data-group-id="${groupId}"]`).prop('checked', false)
    }

    if ( !this.checked ) {
        $(this).closest('div').find('input:last').prop('checked', false)
        $(this).closest('div').find('div').addClass('d-none')
    } else {
        $(this).closest('div').find('div').removeClass('d-none')
    }
})
