export default function breedingTab(idx, isShow) {
  if (isShow !== 0) {
    return `
      <div class="modal-body text-start tab-pane autocomplete--full-width" id="breedinga${idx}" role="tabpanel" aria-labelledby="breedinga${idx}-tab">
        <div class="container">
          <div class="row mb-2">
            <div class="col">
              <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                <div class="col-md-4">
                  <label for="weight">Select a Sire</label>
                </div>
                <div class="col-md-8">
                  <input id="dad${idx}" class="form-control cattle-search w-100" placeholder="Search for Cattle..."
                    name="dad[]" type="text" value="" aria-controls="autoComplete_list_5" aria-autocomplete="both">
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                <div class="col-md-4">
                  <label for="weight">Select a Dam</label>
                </div>
                <div class="col-md-8">
                  <input id="mom${idx}" class="form-control cattle-search w-100" placeholder="Search for Cattle..."
                    name="mom[]" type="text" value="" aria-controls="autoComplete_list_1" aria-autocomplete="both">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  } else {
    return ''
  }
}
