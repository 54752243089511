import axios from "axios";
import {authSessionExpiredAlert} from "../actions-alerts";

checkForAuthSession()

function checkForAuthSession()
{

    axios.get(`/check-auth-session` )
        .then(function(response) {
            let state = response.data.state;
            const timeout = 3.96e+6

            if ( state ) {
                setTimeout(() => { checkForAuthSession(); }, timeout);
            } else {

                if ( $('body').data('auth') == '1' ) {
                    authSessionExpiredAlert()
                }
            }
        });
}
