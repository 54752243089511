let modal = $('#addCattleType'),
    cattleTypeNameEl = $('#setCattleTypeName');

$('.js-exists-cattles-select').on('change', function() {
    if ( $(this).val() != 0 ) {
        var $isExistsCattleInACurrentProperty = false;

        $('[data-cattle]').each(function(index) {
            if ($(this).text() === $('.js-exists-cattles-select').val()) {
                $isExistsCattleInACurrentProperty = true;
            }
        });

        if ($isExistsCattleInACurrentProperty === true) {
            $('.js-exists-cattles-select').val("0");
            return;
        }
        let catId = $(this).find(':selected').attr('data-cattle-id');
        let inputCatId = $('.js-form-add-cattle-type-by-select').find('input[name="cattle_type_id"]').val(catId);

        $('.js-exists-cattles-select').val("0");

        $('.js-form-add-cattle-type-by-select').trigger('submit');
    }
});
