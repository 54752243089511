import axios from 'axios'
import {isArray} from 'lodash'
import {initPaddockDragging} from './drag&drop-order-in-table'
import {
    colorizeIconsAndColumns,
    colorizeIcon,
    colorizeHeaderColumn,
    colorizeBodyColumn,
    colorizeFooterColumn
} from './ledger'
import {changeWidthOfPaddockGroupsTable} from "./ledger/change-width-of-paddocks-groups";

if ( window.location.pathname.includes('ledger')) {

    $('.js-property-folder').on('click', function () {
        const button = $(this)
        const isOpen = button.hasClass('opened')
        const parentTableRow = button.parents('tr')
        const propertyId = button.attr('data-id')
        const icon = button.children('i:first-child')
        const propertyFolderBottomPosition = $(button).offset().top + $(button).outerHeight(true) + 8

        let firstTh

        for (let td of parentTableRow) {
            $(td).css('border', '1px solid #dee2e7')
        }

        function changeIconsColor(tinycolor) {
            const color = tinycolor?.toHexString()
            const id = firstTh.attributes['data-cattle-type-id'].value
            const similarTh = document.querySelectorAll(`th[data-current-cattle-type-id="${id}"]`)
            for (let th of similarTh) {
                th.setAttribute('data-cattle-type-color', `${color}`)
                for (let child of th.children) {
                    colorizeIcon($($(child).children('i')), color)
                }
            }
        }

        button.css('pointer-events', 'none')

        if (button.hasClass('opened')) {

            $(parentTableRow).trigger('click')

            $(parentTableRow).closest('tr').find('td:first').css({
                'position': 'static',
                'padding': '',
                'z-index': '0'
            })

            $(parentTableRow).closest('tr').find('td div:first').css({
                'height': '',
                'border-top': '',
                'border-right': '',
                'padding': ''
            })
        } else {
            $(parentTableRow).closest('tr').find('td:first').css({
                'position': 'relative',
                'padding': '0',
                'z-index': '6'
            })

            $(parentTableRow).closest('tr').find('td div:first').css({
                'height': '100%',
                'border-top': '1px solid #858181',
                'border-right': '1px solid #858181',
                'padding': '0.5rem 0.5rem'
            })
        }

        const blurWrapper = document.querySelector('.blur__wrapper')
        if (blurWrapper) {
            if (blurWrapper.style.display !== 'none') {
                blurWrapper.style.display = 'none'
            } else {
                blurWrapper.style.display = 'block'
            }
        }

        if (isOpen) {
            $(`.js-paddock-table-wrapper-${propertyId}`).remove()
            button.css('pointer-events', 'initial')
            button.toggleClass('opened')
            icon.toggleClass('fas fa-folder-open')
            icon.toggleClass('fas fa-folder')

            $(`.fa-folder`).css('opacity', '1')
            $('.blur__wrapper').remove()
        } else {
            $(button).find('i:first').addClass('d-none')
            $(button).find('i:last').removeClass('d-none')

            $(`button:not([data-id="${propertyId}"]) .fa-folder`).css('opacity', '0.1')
            $('.js-property-folder').css('pointer-events', 'none')

            axios
                .get(`/ledger/${propertyId}`)
                .then(response => {
                    let tableWrapper = document.createElement('div')
                    tableWrapper.classList.add(`js-paddock-table-wrapper-${propertyId}`)
                    tableWrapper.classList.add('inner-table')
                    tableWrapper.classList.add('css-inner-table')
                    tableWrapper.dataset.id = propertyId
                    $(tableWrapper).html($(response.data.html).find('table')[0])
                    $(tableWrapper).css({
                        'position': 'absolute',
                        'top': `${propertyFolderBottomPosition}px`,
                        'left': '48px',
                        'width': 'calc(100% - 60px)',
                        'overflow-x': 'auto'
                    })

                    const propertyColumn = tableWrapper.querySelectorAll('.inserted-table-th')
                    for (let item of propertyColumn) {
                        item.style.width = `${button.parents('td').innerWidth() + 1}px`
                        item.style.minWidth = `${button.parents('td').innerWidth() + 1}px`
                    }

                    const totalColumn = tableWrapper.querySelectorAll('.inserted-table-total')
                    for (let item of totalColumn) {
                        item.style.width = `${button.parents('td').next().innerWidth() + 1}px`
                        item.style.minWidth = `${button.parents('td').next().innerWidth() + 1}px`
                    }

                    const groupPropertyColumn = tableWrapper.querySelectorAll('.group-table-th')
                    groupPropertyColumn.forEach((item) => {
                        item.addEventListener('click', function () {
                            let $parentTable = $(this).closest('.group-table')
                            if ($parentTable.hasClass('grouped')) {
                                $parentTable.find('tbody').removeAttr('hidden')
                                $parentTable.removeClass('grouped')
                                $(this).find('.expand-arrows').css('display', 'none')
                                $(this).find('.collapse-arrows').css('display', '')
                            } else {
                                $parentTable.find('tbody').attr('hidden', '')
                                $parentTable.addClass('grouped')
                                $(this).find('.expand-arrows').css('display', '')
                                $(this).find('.collapse-arrows').css('display', 'none')
                            }
                        })
                    })
                    for (let item of groupPropertyColumn) {
                        item.style.width = `${button.parents('td').innerWidth() + 1}px`
                    }

                    const groupTotalColumn = tableWrapper.querySelectorAll('.group-table-total')
                    for (let item of groupTotalColumn) {
                        item.style.width = `${button.parents('td').next().innerWidth() + 1}px`
                    }

                    const blurWrapper = document.createElement('tr')
                    blurWrapper.classList.add('blur__wrapper')

                    $('body').append(tableWrapper)

                    if (!document.querySelector('.blur__wrapper')) {
                        $(blurWrapper).insertAfter(parentTableRow)
                    }

                    if (response.data.receiveNotification) {
                        $('#ledger-table').before(`
                            <div class="alert alert-danger alert-dismissible">
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                <strong>
                                    The cattle will now show up at the top of the Paddock Book in the Receive Paddock, you will
                                    need to use the “Moved” Action to move them to the appropriate paddock.
                                </strong>
                            </div>
                        `)
                    }

                    $(tableWrapper).find('[data-toggle="popover"]').popover({
                        trigger: 'hover focus',
                        html: true
                    })

                    //button.addClass('isLoaded')
                    button.addClass('opened')
                    icon.toggleClass('fas fa-folder-open')
                    icon.toggleClass('fas fa-folder')

                    const cattleTypes = response.data.cattleTypes

                    for (const id of Object.keys(cattleTypes)) {
                        colorizeHeaderColumn(id, cattleTypes[`${id}`][0].color)
                        colorizeBodyColumn(id, cattleTypes[`${id}`][0].color)
                        colorizeFooterColumn(id, cattleTypes[`${id}`][0].color)
                        const similarTh = document.querySelectorAll(`th[data-current-cattle-type-id="${id}"]:not([data-cattle-type-color="1"])`)
                        for (let th of similarTh) {
                            for (let child of th.children) {
                                colorizeIcon($(child), cattleTypes[`${id}`][0].color)
                            }
                        }
                    }

                    const icons = ($('.color-picker').closest('th'))
                    icons.each(() => {})

                    // $('.color-picker').spectrum({
                    //     type: 'component',
                    //     showAlpha: false,
                    //     cancelText: 'Reset',
                    //     chooseText: '',
                    //     containerClassName: 'cpicker-container',
                    //     show: function () {firstTh = this.parentNode.parentNode},
                    //     hide: tinycolor => changeIconsColor(tinycolor)
                    // })

                    $('.color-picker').spectrum({
                        type: 'component',
                        showAlpha: false,
                        cancelText: 'Reset',
                        chooseText: '',
                        containerClassName: 'cpicker-container'
                    })

                    $('.sp-cancel').on('click', function(event) {
                        event.preventDefault()

                        const cattleTypeId = $('.active-picker').closest('th').data('current-cattle-type-id')

                        colorizeIcon($('.active-picker'), '')
                        colorizeHeaderColumn(cattleTypeId, '', true)
                        colorizeBodyColumn(cattleTypeId, '')
                        colorizeFooterColumn(cattleTypeId, '')

                        axios.post('/color', {
                            'color': null,
                            'cattle_type_id': cattleTypeId
                        })

                        $(this).closest('.sp-container').spectrum("destroy");
                    })


                    changeWidthOfPaddockGroupsTable($('#ledger-table')[0])
                    initPaddockDragging($(tableWrapper).find('table')[0])

                    if ( $('.bi-calendar-check').length !== 0 ) {
                        $('.bi-calendar-check').each(function(e) {
                            let el = $(this).closest('div').find('a:last')

                            if ( $(el)[0].offsetWidth == $(el)[0].scrollWidth ) {
                                $(el).removeAttr('title')
                            }
                        })
                    }
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    $(button).find('i:last').addClass('d-none')
                    $(button).find('i:first').removeClass('d-none')

                    $('.fa-folder').css('opacity', '1')
                    $('.js-property-folder').css('pointer-events', 'initial')
                })
        }
    })
}
