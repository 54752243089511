import {updateAgesSelectsInAModal} from "../cattle-type/actions-cattle-type";


$('.js-report-table').on('change', '.js-action-select-in-table', async function (event) {

    await changeAction($(this), false)
});

export async function changeAction(select, isUpdate = false, transactionData = null, isCopy = false)
{
    $('.js-report-table span.js-small-text').remove()
    $('.js-report-table .is-invalid').removeClass('is-invalid')

    const val = $(select).val();

    let prefilledPropertyId = $('input[name="property_id"]:last').val();
    let prefilledPaddockId = $(select).closest('tr').find('input[name="current_paddock_id"]').val();
    let prefilledCattleTypeId = $(select).closest('tr').find('td[data-cattle-type-from] select:not(.d-none)').val();

    let prefilledYearOfBirth = $(select).closest('tr').find('td[data-current-year-of-birth] select').val();
    let prefilledQuantity = $(select).closest('tr').find('td[data-quantity] input').val();


    let currentYear = new Date().getFullYear();

    if ( val === 'Transfer In' ) {
        $(select).closest('tr').find('select[name="updated_property_id[]"]').trigger('change').val(prefilledPropertyId)
    }

    if ( $(select).closest('.js-report-table').length > 0 ) {
        hideMainActionElementsInTable($(select));
        if ( val === '0' ) {
            handleMsNumberInTable();
            return;
        }
        await handleHorizontalActionProps($(select), isUpdate, isCopy);


        if (
            !isCopy
            && $(select).closest('tr').find('.js-cattle-types-buffer option').length !== 0
            && val !== 'Transfer In'
        ) {
            const cattleTypesBuffer = $('.js-cattle-types-buffer')

            $(select).closest('tr').find('td[data-cattle-type-from] select').empty().append($(cattleTypesBuffer).prop('innerHTML') )
            $(cattleTypesBuffer).empty()
        }


        if ( val === 'Brand' || val === 'Bought' ) {

            if ( $(select).closest('tr').find('td[data-quantity] input[name="quantity[]"]').attr('readonly') ) {
                $(select).closest('tr').find('td[data-quantity] input[name="quantity[]"]').removeAttr('readonly').val('')
            }

            $(select).closest('tr').find('td[data-cattle-type-from] select option').removeAttr('disabled')

            $(select).closest('tr').find('td[data-cattle-type-from] select').removeAttr('multiple');
            $(select).closest('tr').find('td[data-cattle-type-from] select option[value="all"]').removeAttr('selected').addClass('d-none');

            if (  $(select).closest('tr').find('td[data-cattle-type-from] select option[value="0"]').length === 0 ) {
                $(select).closest('tr').find('td[data-cattle-type-from] select').prepend('<option value="0" data-empty-type="1">Choose</option>')
            }

            const firstCattleType = $(select).closest('tr').find('td[data-cattle-type-from] select option:eq(0)').val()

            if ( !isUpdate && !isCopy ) {
                $(select).closest('tr').find('td[data-cattle-type-from] select').val(firstCattleType).trigger('change')
            }

            const deletedOptionsSelect = $(select).closest('tr').find('.js-deleted-types-buffer')
            if ( !isCopy && $(deletedOptionsSelect).find('option').length !== 0 ) {
                $(select).closest('tr').find('td[data-cattle-type-from] select').append($(deletedOptionsSelect).prop('innerHTML'))
                $(deletedOptionsSelect).empty()
            }

            $(select).closest('tr').find('td[data-cattle-type-from] select option[data-empty-type="1"]:not([value="all"])').removeClass('d-none')

        } else {
            $(select).closest('tr').find('td[data-cattle-type-from] select').attr('multiple', '');
            $(select).closest('tr').find('td[data-cattle-type-from] select option[value="all"]').removeClass('d-none');
            $(select).closest('tr').find('td[data-cattle-type-from] select option').removeAttr('selected')

            $(select).closest('tr').find('td[data-cattle-type-from] select option[data-empty-type="1"]:not([value="all"]):not([data-prefilled-type="1"])').addClass('d-none')

            let deletedOptionsSelect = $(select).closest('tr').find('.js-deleted-types-buffer')
            let deletedOptions = ''

            $(select).closest('tr').find('td[data-cattle-type-from] select option[class="d-none"]').each(function() {

                if ( (!isUpdate || (isUpdate && $(this).val() != transactionData['cattle_type_from'])) ) {
                    deletedOptions += $(this).prop('outerHTML')
                    $(this).remove()
                }


            })

            if ( !isCopy && val !== 'Transfer In' ) {
                $(deletedOptionsSelect).append(deletedOptions)
            }
        }


        if ( $(select).closest('tr').hasClass('pre-filled') ) {

            $(select).closest('tr').find('td[data-property-from-id] input:first').val(prefilledPropertyId);
            $(select).closest('tr').find('td[data-cattle-type-from] select:not(.d-none)').val(prefilledCattleTypeId).trigger('change');


            if ( val === 'Change Age' ) {
                $(select).closest('tr').find('td[data-updated-year-of-birth] select').append(`<option value="${prefilledYearOfBirth}">${currentYear - prefilledYearOfBirth}</option>`)
            }
            $(select).closest('tr').find('td[data-quantity] input#quantity').val(prefilledQuantity);
            if ( val !== 'Brand' && val !== 'Bought' ) {
                $('.js-report-table [name="quantity[]"]').trigger('input')

            }

            await axios.get('/property-cattle-type?cattle_type_id=' + prefilledCattleTypeId + '&property_id=' + prefilledPropertyId)
                .then((response) => {
                    let cattleTypeGender = response.data.data.gender;

                    cattleTypeGender === 'Female'
                        ? $(select).closest('tr').find('.js-select-cattle-female-status').removeAttr('disabled')
                        : $(select).closest('tr').find('.js-select-cattle-female-status').attr('disabled', '');

                });


        } else {

            if ( $(select).closest('tr').find(`td[data-cattle-type-from] select:not(.d-none) option[value="${prefilledCattleTypeId}"]`).length !== 0 ) {

                if (
                    ((val === 'Brand' || val === 'Bought') && prefilledCattleTypeId[0] !== 'all')
                    || (val !== 'Brand' && val !== 'Bought')
                ) {
                    $(select).closest('tr').find('td[data-cattle-type-from] select:not(.d-none)').val(prefilledCattleTypeId);
                }

            }

            await updateAgesSelectsInAModal($(select).closest('.action-in-table'))
        }

        $(select).closest('tr').find('#cattleToReclass option:first').attr('selected', 'selected');

        $(select).closest('tr').find('#cattleToReclass').trigger('change')

        $(select).closest('tr').find('[name="mob[]"]').val('')

        if ( val === 'Moved' ) {
            $(select).closest('tr').find('select[name="updated_paddock_id[]"] option[value="0"]').addClass('d-none');
            $(select).closest('tr').find('select[name="updated_paddock_id[]"] option').removeAttr('selected');
            $(select).closest('tr').find(`select[name="updated_paddock_id[]"]`).val(prefilledPaddockId);
        }

    }

    return 1
}

/**
 * @param {HTMLSelectElement} elSelect
 */
export function hideMainActionElementsInTable(elSelect) {
    elSelect.closest('.action-in-table')
        .find('input, select, button')
        .not(`
            .datepicker,
            .js-action-select-in-table,
            .js-datepicker-formatted-value,
            [name="transaction_id"]
        `)
        .attr('disabled', '');
}

/**
 * @param {HTMLSelectElement} elSelect
 */
export async function handleHorizontalActionProps(elSelect, isUpdate, isCopy)
{
    const val = elSelect.val();
    const elAction = elSelect.closest('.action-in-table');
    const currentPaddockId = $('.js-ledger-table:last tbody tr:first td:first').data('paddock-id');
    const currentPropertyId = $('.js-actions-form input[name="property_id"]').val();

    showCommonPropsInTable(elAction);
    handleMsNumberInTable();


    $(elAction).find('td[data-treatment] select').removeAttr('disabled');

    fieldsSelectionByAction(val, elAction, currentPaddockId);


    if ( val === 'Brand' || val === 'Bought' ) {
        $(elAction).find('.js-action-select-cattle-type:first-child').addClass('d-none');
        $(elAction).find('.js-action-select-cattle-type:first-child').attr('disabled', '')

        $(elAction).find('.js-action-select-cattle-type:last-child').removeClass('d-none');
        $(elAction).find('.js-action-select-cattle-type:last-child').removeAttr('disabled')
    } else {
        $(elAction).find('.js-action-select-cattle-type:last-child').addClass('d-none');
        $(elAction).find('.js-action-select-cattle-type:last-child').attr('disabled', '')

        $(elAction).find('.js-action-select-cattle-type:first-child').removeClass('d-none');
        $(elAction).find('.js-action-select-cattle-type:first-child').removeAttr('disabled')
    }


    const elSma = $(elAction).find('input[name="sma[]"]:not([disabled])');
    const elNVD =


    $(elAction).find('.js-property-block-for-transfer-out input, .js-property-block-for-transfer-in input').attr('value', currentPropertyId);


    if ( !isUpdate && !isCopy && elSma.length > 0 && elSma.val().length === 0 ) {
        await axios.get('/generate-sma')
            .then(response => {

                const smaActions = $('.js-report-table .action-in-table input[name="nvd[]"]:not(:disabled)')

                if ( $(smaActions).length === 1) {

                    const generatedSMA = response.data.data.sma;
                    elSma.val(generatedSMA);
                } else {
                    const firstSmaActionRow = $(smaActions).first().closest('.action-in-table')
                    const existingSMA = $(firstSmaActionRow).find('input[name="sma[]"]').val()
                    elSma.val(existingSMA)

                    if (
                        $(elAction).find('.js-action-select-in-table').val() === 'Sold'
                        || $(elAction).find('.js-action-select-in-table').val() === 'Bought'
                        || $(elAction).find('.js-action-select-in-table').val() === 'Transfer Out'
                        || $(elAction).find('.js-action-select-in-table').val() === 'Transfer In'
                    ) {
                        const firstNVD = $(firstSmaActionRow).find('input[name="nvd[]"]').val()
                        const firstDecks = $(firstSmaActionRow).find('input[name="decks[]"]').val()

                        $(elAction).find('input[name="nvd[]"]').val(firstNVD)
                        $(elAction).find('input[name="decks[]"]').val(firstDecks)
                    }
                }

            })
            .then(() => {
                elAction.addClass('current')
                $('.modal#sma [data-hide="sma"]').removeAttr('hidden')

                if ( !isUpdate ) {
                    document.querySelector('.current .js-edit-sma-in-table').click()
                }
            })
    }
}

function fieldsSelectionByAction(val, elAction, currentPaddockId)
{
    elAction.find('.js-destination select').attr('disabled', 'disabled').addClass('d-none');
    elAction.find('.js-destination [name="purchased_from[]"]').addClass('d-none');
    elAction.find('input[name="purchase_price[]"]').addClass('d-none');
    elAction.find('input[name="cost_of_carry[]"]').addClass('d-none');
    elAction.find('input[name="mob[]"]').removeAttr('disabled');

    if ( val === 'Change Age' ) {
        elAction.find('.js-destination [name="updated_year_of_birth[]"]').removeAttr('disabled').removeClass('d-none');

        if ( $('.js-report-table').data('is-full-level') ) {
            $(elAction).find('input[name="mob[]"]').removeAttr('disabled');
        }

    }
    else if ( val === 'Bought' ) {
        elAction.find(`
            input[name="nvd[]"],
            input[name="decks[]"],
            select[name="agent[]"],
            input[name="sma[]"],
            select[name="transport[]"],
            input[name="sma_note[]"]
        `).removeAttr('disabled');

        elAction.find('.js-destination [name="purchased_from[]"]').removeClass('d-none').removeAttr('disabled');
        //elAction.find('input[name="purchased_from[]"], input[name="purchased_to[]"]').removeAttr('disabled')

    }
    else if ( val === 'Brand' ) {
        elAction.find('.js-destination select:first').removeClass('d-none');
        elAction.find('input[name="purchase_price[]"]').removeClass('d-none').removeAttr('disabled');
    }
    else if ( val === 'Sold' ) {
        elAction.find(`
            input[name="nvd[]"],
            input[name="decks[]"],
            select[name="agent[]"],
            input[name="sma[]"],
            select[name="transport[]"],
            input[name="sma_note[]"],
            select[name="sold_note[]"]
        `).removeAttr('disabled');

        elAction.find('.js-destination .js-property-block-for-sale select').removeAttr('disabled').removeClass('d-none');
        elAction.find('input[name="cost_of_carry[]"]').removeClass('d-none').removeAttr('disabled');

        if ( $('.js-report-table').data('is-full-level') ) {
            $(elAction).find('input[name="mob[]"]').removeAttr('disabled');
        }
    }
    else if ( val === 'Mortality' ) {
        elAction.find('.js-destination select:first').removeClass('d-none');

        if ( $('.js-report-table').data('is-full-level') ) {
            $(elAction).find('input[name="mob[]"]').removeAttr('disabled');
        }
    }
    else if ( val === 'Transfer In' ) {
        elAction
            .find(`
            input[name="nvd[]"],
            input[name="decks[]"],
            input[name="agent[]"],
            input[name="sma[]"],
            select[name="transport[]"],
            input[name="sma_note[]"]
        `).removeAttr('disabled');

        elAction.find('.js-destination .js-property-block-for-transfer-in select').removeAttr('disabled').removeClass('d-none');


        if ( $('.js-report-table').data('is-full-level') ) {
            $(elAction).find('input[name="mob[]"]').removeAttr('disabled');
        }
    }
    else if ( val === 'Transfer Out' ) {
        elAction
            .find(`
            input[name="nvd[]"],
            input[name="decks[]"],
            input[name="agent[]"],
            input[name="sma[]"],
            select[name="transport[]"],
            input[name="sma_note[]"]
        `).removeAttr('disabled');

        elAction.find('.js-destination .js-property-block-for-transfer-out select').removeAttr('disabled').removeClass('d-none');
        const prefillPropertyId = elAction.find('.js-destination .js-property-block-for-transfer-out select option:eq(1)').val()
        elAction.find('.js-destination .js-property-block-for-transfer-out select').val(prefillPropertyId);

        if ( $('.js-report-table').data('is-full-level') ) {
            $(elAction).find('input[name="mob[]"]').removeAttr('disabled');
        }
    }
    else if ( val === 'Reclass' || val === 'Weaned' ) {
        elAction.find('.js-destination [name="updated_cattle_type_id[]"]').removeAttr('disabled').removeClass('d-none').val('0').trigger('change');
        elAction.find('[name="updated_year_of_birth[]"]:first').removeAttr('disabled');

        if ($('.js-report-table').data('is-full-level')) {
            $(elAction).find('input[name="mob[]"]').removeAttr('disabled');
        }

    } else if ( val === 'Process Weaners' ) {
        elAction.find('.js-destination [name="updated_cattle_type_id[]"]').removeAttr('disabled').removeClass('d-none').val('0').trigger('change');
        elAction.find('.js-destination [name="updated_paddock_id[]"]').removeAttr('disabled').removeClass('d-none');
        elAction.find('[name="updated_year_of_birth[]"]:first').removeAttr('disabled');

        if ($('.js-report-table').data('is-full-level')) {
            $(elAction).find('input[name="mob[]"]').removeAttr('disabled');
        }

    }
    else if ( val === 'Bushed' ) {
        elAction.find('.js-destination select:first').removeClass('d-none');

        if ($('.js-report-table').data('is-full-level')) {
            $(elAction).find('input[name="mob[]"]').removeAttr('disabled');
        }
    }
    else if ( val === 'Moved' ) {
        elAction.find('.js-destination [name="updated_paddock_id[]"]').removeAttr('disabled').removeClass('d-none');

        if ($('.js-report-table').data('is-full-level')) {
            $(elAction).find('input[name="mob[]"]').removeAttr('disabled');
        }

    }

}

/**
 * @param  {HTMLElement} elAction
 */
export function showCommonPropsInTable(elAction) {
    elAction.find(`
        .js-action-select-cattle-type,
        select[name="year_of_birth[]"],
        input[name="quantity[]"],
        input[name="note[]"],
        input[name="head_price[]"],
        button,
        input[name="property_id"],
        input[name="current_paddock_id"]
    `).removeAttr('disabled');
}

export function handleMsNumberInTable() {
    let msDisabled = true;
    $('.action-in-table').each(function (index) {
        let actionValue = $(this).find('.js-action-select-in-table').val();
        if (actionValue !== '0'
            && msDisabled === true
        ) {
            $(this).find('input[name="ms_number"]').removeAttr('disabled', '');
            let ms = $(this).find('input[name="ms_number"]').val()
            //$(this).find('input[name="ms_number"]').val(ms);
            msDisabled = false;
        }
    });
    if (msDisabled) {
        $('.action-in-table').find('input[name="ms_number"]').attr('disabled', '');
        $('.action-in-table').find('input[name="ms_number"]').val('');
    }
}
