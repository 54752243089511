import {changeWidthOfPaddockGroupsTable} from "./change-width-of-paddocks-groups";

$('body').on('click', '.js-ledger-table .js-expand-ages', event => {
    event.preventDefault()

    let target = event.target

    if (target.hasAttribute('href')) {
        target = $(target).find('i')
    }

    let table = $(target).closest('.js-ledger-table')
    let cattleTypeId = $(target).closest('td').data('current-cattle-type-id')
    let colspan = $(target).closest('td').data('colspan')

    if ( $(target).hasClass('fa-expand-alt') ) {
        $(target).closest('td').attr('colspan', 1)
        $(table).find(`tbody tr`).each(function () {
            $(this).find(`td[data-current-cattle-type-id="${cattleTypeId}"]:not(.d-none):last`)
                .attr('colspan', 1)
        })

        $(target).closest('td').nextAll(`.age-hidden[data-current-cattle-type-id="${cattleTypeId}"]:not(.extra-hidden)`)
            .removeClass('d-none')
        $(table).find(`tbody td.age-hidden[data-current-cattle-type-id="${cattleTypeId}"]:not(.extra-hidden)`)
            .removeClass('d-none')

        $(table).find(`tbody td.age-border-total[data-current-cattle-type-id="${cattleTypeId}"]`)
            .addClass('d-none')

        $(target).closest('td').addClass('d-none')
        $(target).closest('td').next('td').removeClass('d-none')
    } else {
        $(target).closest('td').nextAll(`.age-hidden[data-current-cattle-type-id="${cattleTypeId}"]`)
            .addClass('d-none')
        $(table).find(`tbody td.age-hidden[data-current-cattle-type-id="${cattleTypeId}"]`)
            .addClass('d-none')

        $(table).find(`tbody td.age-border-total[data-current-cattle-type-id="${cattleTypeId}"]`)
            .removeClass('d-none')

        $(target).closest('td').addClass('d-none')
        $(target).closest('td').prev('td').removeClass('d-none')

        $(target).closest('td').attr('colspan', colspan)
        $(table).find(`tbody tr`).each(function () {
            $(this).find(`td[data-current-cattle-type-id="${cattleTypeId}"]:not(.empty):not(.d-none):last`)
                .attr('colspan', colspan)
        })
    }

    let cattleTypeNewColspan

    if ($(table).find('tbody tr:first td').children('.group-table').length > 0) {
        cattleTypeNewColspan = $(table).find(`tbody tr:first .group-table tr:first td[data-current-cattle-type-id="${cattleTypeId}"]:not(.d-none)`).length
    } else {
        cattleTypeNewColspan = $(table).find(`tbody tr:first td[data-current-cattle-type-id="${cattleTypeId}"]:not(.d-none)`).length
    }

    $(table).find('thead tr:first-child th[data-current-cattle-type-id="' + cattleTypeId + '"]')
        .attr('colspan', cattleTypeNewColspan)
    $(table).find('tfoot tr:first-child td[data-current-cattle-type-id="' + cattleTypeId + '"]')
        .attr('colspan', cattleTypeNewColspan)

    changeWidthOfPaddockGroupsTable($('#ledger-table')[0])
})
