export default function feedlotTab(idx, isShow) {
  if (isShow !== 0) {
    return `
      <div class="modal-body text-start tab-pane" id="feedlota${idx}" role="tabpanel" aria-labelledby="feedlota${idx}-tab">
          <div class="container">
              <div class="row mb-2">
                  <div class="col">
                      <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                          <div class="col-md-4">
                              <label for="weight">Entry Weight</label>
                          </div>
                          <div class="col-md-8">
                              <input class="form-control w-100" placeholder="" name="entry_weight[]" type="number" value="">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-2">
                  <div class="col">
                      <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                          <div class="col-md-4">
                              <label for="weight">Exit Weight</label>
                          </div>
                          <div class="col-md-8">
                              <input class="form-control w-100" placeholder="" name="exit_weight[]" type="number" value="">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-2">
                  <div class="col">
                      <div class="row align-items-center bg-gray-primary rounded-2 py-2">
                          <div class="col-md-4">
                              <label for="weight">Days on Feed</label>
                          </div>
                          <div class="col-md-8">
                              <input class="form-control w-100" placeholder="" name="feed[]" type="number" value="">
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    `
  } else {
    return ''
  }
}