/**
 * Scroll handler to process tables with sticky header.
 * Using CSS 'position: sticky' isn't possible because table parent may have 'overflow: auto' that breaks expected 'position: sticky' work.
 */
export function handleScrollToMakeTableHeaderSticky() {
    const tables = Array.from(document.querySelectorAll('.table-head-sticky'));

    tables.forEach(table => {
        const tableHeader = table.querySelector('thead');
        const tableHeaderHeight = tableHeader.getBoundingClientRect().height;
        const mainHeaderHeight = $('nav.navbar').innerHeight();

        const isTableVisible = table.getBoundingClientRect().bottom > 0 || table.getBoundingClientRect().top > mainHeaderHeight;

        if (!isTableVisible) {
            return
        }

        if (
            table.getBoundingClientRect().top <= mainHeaderHeight
            && table.getBoundingClientRect().bottom > mainHeaderHeight + tableHeaderHeight
        ) {
            $(tableHeader).css('transform', `translateY(${Math.round(-table.getBoundingClientRect().top + mainHeaderHeight)}px)`);
            $(table).css('border-collapse', 'separate');
            $(table).css('border-spacing', '0');
        } else if (table.getBoundingClientRect().bottom <= mainHeaderHeight + tableHeaderHeight) {
            return
        } else {
            $(tableHeader).css('transform', '');
            $(table).css('border-collapse', 'collapse');
        }
    })
}

if ($('.table-head-sticky').length > 0) {
    window.addEventListener('scroll', handleScrollToMakeTableHeaderSticky, {passive: true});
}
