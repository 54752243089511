$('.js-report-table').on('input', 'input[name="purchased_from[]"]', function (e) {
    const target = e.target

    if ( $(target).val() !== '' ) {
        $(target).closest('div').find('select[name="purchased_from[]"]').attr('disabled', 'disabled')
    } else {
        $(target).closest('div').find('select[name="purchased_from[]"]').removeAttr('disabled')
    }
})

$('.js-report-table').on('change', 'select[name="purchased_from[]"]', function (e) {
    const target = e.target

    if ( $(this).val() !== '0' ) {
        $(target).closest('div').find('input[name="purchased_from[]"]').attr('disabled', 'disabled')
    } else {
        $(target).closest('div').find('input[name="purchased_from[]"]').removeAttr('disabled')
    }
})
