import Sortable from 'sortablejs'
import axios from 'axios'
import {initCustomDragAndDrop} from './ledger'

$('body').on('click', '.js-expand-cattle-types, .js-expand-ages', event => {
    setTimeout(function () {
        initPaddockDragging($(event.target).closest('table')[0], false)
    }, 300)
})

export function initPaddockDragging(table, initCells = true) {
    function initSortable(tableBody, index = '') {
        new Sortable(tableBody, {
            group: {
                name: 'group-table',
                pull: false,
                put: false
            },
            animation: 150,
            draggable: `.draggable${index}`,
            handle: `.dragme${index}`,
            fallbackOnBody: true,
            swapThreshold: 0.65,
            onEnd: event => {
                let el = event.item
                let currentPropertyId = $(el).closest('table').data('id')
                let to = event.newIndex

                let group = $(el).closest('table').closest('tr').data('group-id')
                    ? '&group=' + $(el).closest('table').closest('tr').data('group-id')
                    : ''

                let paddockId = $(el).data('paddock-id') !== undefined
                    ? $(el).data('paddock-id')
                    : $(el).find('tbody tr:first').data('paddock-id')

                let afterPaddockId = $(el).closest('tbody').find(`tr:nth-child(${to})`).data('paddock-id')

                if ( afterPaddockId === undefined ) {
                    afterPaddockId = $(el).closest('tbody').find(`tr:nth-child(${to}) tbody tr:first`).data('paddock-id')
                }

                axios.get(`/change-paddock-order?paddockId=${paddockId}&afterPaddockId=${afterPaddockId}&propertyId=${currentPropertyId}${group}`)
            }
        })
    }

    initSortable(table.querySelector('tbody'))

    Array.from(table.querySelectorAll('.group-table')).forEach(groupTable => {
        let i = groupTable.getAttribute('data-drag')
        initSortable(groupTable.querySelector('tbody'), i)
    })

    if (initCells) initCustomDragAndDrop()
}
