import Log from "laravel-mix/src/Log";
import {confirmMontlyAlert} from "./actions-alerts";
import {setInvalid} from "./cattle-type/actions-cattle-type";
import {addFutureYearToSelect} from "./cattle-type/table-actions-cattle-type";


require('./cattle-return/show-actions-modal')

$('#cattleReturnActions [data-action="notMortality"] select[name="updated_cattle_type_id[]"]').on('change', function (e) {
    const propertyId = window.location.pathname.split('/')[2]

    addAgesToSelect(propertyId, $(this).val(),)
})

export function addAgesToSelect(propertyId, cattleTypeToId) {
    $('#cattleReturnActions .container[data-action="notMortality"] select[name="updated_year_of_birth[]"]').empty()

    axios.get('/property-cattle-type?cattle_type_id=' + cattleTypeToId + '&property_id=' + propertyId)
    .then((response) => {
        let cattleTypeMinYearOfBirth = response.data.data.min_year_of_birth;
        let currentYear = new Date().getFullYear();
        let cattleTypeHidden = response.data.data.hide_years_of_birth

        let selectOptions = '';

        if ( !cattleTypeHidden ) {
            selectOptions += `<option value="mixed">mixed</option>`;
            selectOptions += addFutureYearToSelect();
            for (let i = currentYear; i >= cattleTypeMinYearOfBirth; i--) {
                selectOptions += `<option value="${i}">${i}</option>`;
            }
        } else {
            selectOptions += `<option value="hidden">hidden</option>`;
        }

        $('#cattleReturnActions .container[data-action="notMortality"] select[name="updated_year_of_birth[]"]').append(selectOptions)
    })
}

$('#cattleReturnActions [data-action="notMortality"] input[name="quantity[]"]').on('input', function (e) {

    //let animalsAvaible = $(this).data('total')

    // if( $(this).val() <= 0 || $(this).val() === '' || $(this).val() > animalsAvaible ) {
    //     $('#cattleReturnActions .js-small-text').removeClass('d-none')
    //     $('#cattleReturnActions .js-small-text').text(`Number of animals available - ${animalsAvaible}`)
    //     $('#cattleReturnActions button[type="submit"]').attr('disabled', 'disabled')
    // } else {
    //     $('#cattleReturnActions .js-small-text').addClass('d-none')
    //
    // }

    $('#cattleReturnActions button[type="submit"]').removeAttr('disabled')
})

$('#cattleReturnActions [data-action="mortality"] input:first').on('input', function (e) {

    const total = $(this).data('total')
    const currentMortalities = $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:first-child .col-md-6 div span:first-child').text()
    const animalsAvaible = parseInt(total) - parseInt(currentMortalities)

    if( $(this).val() <= 0 || $(this).val() === '' || $(this).val() > animalsAvaible ) {
        //$('#cattleReturnActions button[type="submit"]').attr('disabled', 'disabled')
        //$('#cattleReturnActions .container .row.mb-2:eq(1) .col-md-6 span').text('(0%)')

        $('#cattleReturnActions .js-small-text:last').removeClass('d-none')
        $('#cattleReturnActions .js-small-text:last').text(`Number of animals available - ${animalsAvaible}`)
        $('#cattleReturnActions button[type="submit"]').attr('disabled', 'disabled')

        $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:eq(1) .col-md-6 span').addClass('d-none')
        $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:eq(2) .col-md-6 div span:last-child').addClass('d-none')

        return
    }


    let currentPercentMortalities = $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:first-child .col-md-6 div span:last-child').text().replace(/[()%]/g, '')

    let additionalMortalities = $(this).val() === '' ? 0 : $(this).val()
    let percentAdditionalMortalities = Math.round(total === 0 ? 101 : parseInt(additionalMortalities) / parseInt(total) * 100)

    $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:eq(1) .col-md-6 span').text(`(${percentAdditionalMortalities}%)`)

    let newTotalMortalities = parseInt(currentMortalities) + parseInt(additionalMortalities)
    let newTotalPercentMortalities = parseInt(currentPercentMortalities) + parseInt(percentAdditionalMortalities)
    if(newTotalPercentMortalities > 100) {
        newTotalPercentMortalities = 100
    }

    $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:eq(2) .col-md-6 div span:first-child').text(`${newTotalMortalities}`)
    $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:eq(2) .col-md-6 div span:last-child').text(`(${newTotalPercentMortalities}%)`)


    $('#cattleReturnActions .js-small-text:last').addClass('d-none')

    if($('#cattleReturnActions .js-small-text').length === 1) {
        $('#cattleReturnActions button[type="submit"]').removeAttr('disabled')
    }


    $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:eq(1) .col-md-6 span').removeClass('d-none')
    $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:eq(2) .col-md-6 div span:last-child').removeClass('d-none')


})


$('#cattleReturnActions').on('hide.bs.modal', function (e) {
    $('#cattleReturnActions input').val('')
    $('#cattleReturnActions .container[data-action="mortality"] .row.mb-2:last-child .col-md-6 div span:last-child').text(`(0%)`)


})

require('./cattle-return/change-action-in-modal')

$('.property-select-block').on('change', '.js-cattle-return-select-property', function (e) {
    e.preventDefault();
    let propertyId = $(this).val();
    if (propertyId != 0) {

        $('#cattleReturnLoader').modal('show')

        let url = '/cattle-return/' + propertyId;

        window.location = url;
    }
    return false;
});

$('.property-select-block').on('change', '.js-cattle-return-select-group', function (e) {
    e.preventDefault();
    let groupId = $(this).val();
    let params = (new URL(document.location)).searchParams;

    if (groupId != 0) {

        $('#cattleReturnLoader').modal('show')

        if(params.get('groupId') !== null) {
            params.delete("groupId");
        }

        let newParams = params.size !== 0 ? '?' + params : params

        window.history.replaceState({}, document.title, window.location.pathname + newParams);

        let url = params.get('date_from') !== null ? window.location + '&groupId=' + groupId : window.location + '?groupId=' + groupId;
        window.location = url;
    } else {
        if( params.get('groupId') !== null ) {
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);

            params.delete("groupId");

            let newParams = params.size !== 0 ? '?' + params : params

            window.history.replaceState({}, document.title, window.location.pathname + newParams);
            window.location = window.location.href
        }
    }
    return false;
});

if (window.location.pathname.includes('cattle-return')) {
    $(window).on('load', async function () {

        let propertyId = $('.js-cattle-return-select-property select').val();

        changeWidthOfCattleTypesGroupsTable()
        function changeWidthOfCattleTypesGroupsTable() {
            let widthCellsArray = []

            $('.cattle-return-table').find(`tbody:first tr:first th`).each(function (i) {
                widthCellsArray.push($(this).css('width'));
            });

            $('.group-table').each(function(i) {
                $(this).find('thead tr:first th, thead tr:first td').css('width', widthCellsArray[0]);
                $(this).find('thead tr:not(:first)').each(function (i) {
                    $(this).find('th, td').each(function(ii) {
                        $(this).css('width', widthCellsArray[ii + 1]);
                    })
                });

                $(this).find(`tbody tr`).each(function (i) {
                    if($(this).find('td, th').length === 1) {
                        $(this).find('td, th').css('width', widthCellsArray[0]);
                    } else {
                        $(this).find('td, th').each(function(ii) {
                            $(this).css('width', widthCellsArray[ii + 1]);
                        })
                    }
                });
            })



        }

        $('.js-confirm-monthly-button').on('click', function () {
            confirmMontlyAlert(propertyId)
        })

        if (propertyId !== '0') {
            let date = '';
            let today = new Date()

            let startDate = await axios.get(`/get-transactions-dates?property=${propertyId}`);
            date = startDate.data.data.date;

            startDate = date.split('-')[1] + '-' + date.split('-')[0];
            let endDate = String(today.getMonth() + 1) + '-' + today.getFullYear()

            $(".datepicker-monthly").datepicker({
                format: 'mm-yyyy',
                startView: 'months',
                minViewMode: 'months',
                autoclose: true,
                todayHighlight: true,
                orientation: 'bottom',
                startDate: startDate,
                endDate: endDate,
            })
        } else {
            $('.datepicker-monthly').attr('disabled', 'disabled');
            $('.datepicker-monthly').attr('placeholder', 'Select a property first');
            $('form button').addClass('d-none');
        }

        $('.js-hide-empty-ages').trigger('click')
    })

    require('./cattle-return/fill-datepickers')
}

require('./cattle-return/switch-empty-ages-handle')


