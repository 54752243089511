export default function meatImagesTab(idx) {
    return `
    <div class="modal-body text-start tab-pane" id="meat-imagea${idx}" role="tabpanel" aria-labelledby="meat-imagea${idx}-tab">
      <div class="edit-cattle-instance container">
        <div class="image-upload">
          <label for="meat-imagea-upload-${idx}" class="image-upload-label" id="meat-imagea-upload-label-${idx}">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.6869 14.6503L16.67 7.59458C16.1206 6.30295 15.3013 5.57039 14.3664 5.52219C13.4411 5.474 12.5447 6.11981 11.8603 7.35361L10.029 10.6405C9.64343 11.3345 9.09402 11.749 8.49643 11.7972C7.88919 11.855 7.28195 11.5369 6.79038 10.9104L6.57833 10.6405C5.89398 9.78264 5.04578 9.36816 4.1783 9.45491C3.31081 9.54167 2.56864 10.1393 2.07706 11.1128L0.40957 14.4383C-0.188029 15.6432 -0.130197 17.0408 0.573427 18.1782C1.27705 19.3156 2.50116 20 3.84094 20H16.1399C17.4315 20 18.6363 19.3542 19.3496 18.2746C20.0821 17.195 20.1978 15.8359 19.6869 14.6503ZM5.18168 6.51598C6.04572 6.51598 6.87438 6.17272 7.48535 5.56173C8.09632 4.95074 8.43956 4.12206 8.43956 3.25799C8.43956 2.39392 8.09632 1.56523 7.48535 0.954243C6.87438 0.343251 6.04572 0 5.18168 0C4.31764 0 3.48899 0.343251 2.87802 0.954243C2.26705 1.56523 1.92381 2.39392 1.92381 3.25799C1.92381 4.12206 2.26705 4.95074 2.87802 5.56173C3.48899 6.17272 4.31764 6.51598 5.18168 6.51598Z" fill="#7F8A94" />
            </svg>
            <span class="image-upload-label__description">
              Drop your image here, or <span>browse</span>
            </span>
            <span class="image-upload-label__info">
              Supports: JPG, JPEG2000, PNG, WEBP <br>
              Maximum file size: 10MB <br>
              Maximum number of images: 3
            </span>
          </label>
          <input type="file" name="meat_images[${idx}][]" id="meat-imagea-upload-${idx}"
            accept=".jpg, .jpeg, .png, .webp" class="image-upload-input" multiple />
        </div>

        <div id="meat-uploadinga-errors-${idx}" class="uploading-errors"></div>
        <div id="meat-uploadeda-images-${idx}" class="uploaded-images"></div>
      </div>
    </div>
  `
}
