import {changeWidthOfPaddockGroupsTable} from "./change-width-of-paddocks-groups";

$('body').on('click', '.js-ledger-table .js-expand-cattle-types', event => {
    event.preventDefault()

    let target = event.target

    if ( target.hasAttribute('href') ) {
        target = $(target).find('i')
    }

    let table = $(target).closest('.js-ledger-table')
    let groupId = $(target).closest('th').data('group-id')

    if ($(target).hasClass('fa-compress-alt')) {
        $(table).find(`thead:first tr:last td[data-group-id="${groupId}"] .fa-compress-alt:not(.d-none)`).trigger('click')
    }

    let groupColspan

    if ($(table).find('tbody tr:first td').children('.group-table').length > 0) {
        groupColspan = $(table).find(`tbody tr:first .group-table tr:first td[data-group-id="${groupId}"].d-none:not(.age-hidden)`).not('.extra-hidden').length
    } else {
        groupColspan = $(table).find(`tbody tr:first td[data-group-id="${groupId}"].d-none:not(.age-hidden)`).not('.extra-hidden').length
    }

    const beforeCattleTypes = $(table).find(`thead:first tr:first th[data-group-id="${groupId}"]:not(.d-none)`)
    const afterCattleTypes = $(table).find(`thead:first tr:first th[data-group-id="${groupId}"].d-none`)
    beforeCattleTypes.addClass('d-none')
    afterCattleTypes.removeClass('d-none')

    const beforeAgeCategories = $(table).find(`thead:first tr:last td[data-group-id="${groupId}"]:not(.d-none):not(.group-cell)`).not('.extra-hidden')
    const afterAgeCategories = $(table).find(`thead:first tr:last td[data-group-id="${groupId}"].d-none:not(.age-hidden):not(.group-cell)`).not('.extra-hidden')
    beforeAgeCategories.addClass('d-none')
    afterAgeCategories.removeClass('d-none')

    const beforeQuantities = $(table).find(`tbody tr td[data-group-id="${groupId}"]:not(.d-none):not(.group-cell)`).not('.extra-hidden')
    const afterQuantities = $(table).find(`tbody tr td[data-group-id="${groupId}"].d-none:not(.age-hidden):not(.group-cell)`).not('.extra-hidden')
    beforeQuantities.addClass('d-none')
    afterQuantities.removeClass('d-none')

    const beforeTotal = $(table).find(`tfoot tr td[data-group-id="${groupId}"]:not(.d-none)`)
    const afterTotal = $(table).find(`tfoot tr td[data-group-id="${groupId}"].d-none`)
    beforeTotal.addClass('d-none')
    afterTotal.removeClass('d-none')

    if ($(target).hasClass('fa-compress-alt')) {
        $(table).find(`thead:first tr:first th[data-group-id="${groupId}"]:not(.d-none)`).attr('colspan', groupColspan)
        $(table).find(`tfoot tr td[data-group-id="${groupId}"]:not(.d-none)`).attr('colspan', groupColspan)
    } else {
        $(table).find(`thead:first tr:first th[data-group-id="${groupId}"]:not(.d-none)`).each(function () {
            let cattleTypeColspan = $(table).find(`thead:first tr:last td[data-group-id="${groupId}"][data-current-cattle-type-id="${$(this)
                .data('current-cattle-type-id')}"]:not(.d-none)`).length

            $(this).attr('colspan', cattleTypeColspan)
            $(table).find(`tfoot tr td[data-group-id="${groupId}"][data-current-cattle-type-id="${$(this)
                .data('current-cattle-type-id')}"]:not(.d-none)`)
                .attr('colspan', cattleTypeColspan)
        })
    }

    changeWidthOfPaddockGroupsTable($('#ledger-table')[0])
})
