$('.group-name__edit-icon').on('click', function() {
    let groupNameBlock = $('.group-name'),
        elGroupNameInput = $('.group-name-input');

    elGroupNameInput.attr('data-old-value', elGroupNameInput.val());
    groupNameBlock.addClass('d-none');
    elGroupNameInput.attr('type', 'text')
        .trigger('focus');
    $('.group-name__check-icon').removeClass('d-none');

});

$('.group-name-input').on('blur', function() {
    let groupNameBlock = $('.group-name');
    let elGroupNameInput = $(this),
        newGroupName = $(this).val(),
        groupId = $('[data-group-id]').attr('data-group-id'),
        oldValue = $(this).attr('data-old-value');

    if (newGroupName === '') {
        $('.group-name span').text(oldValue);
        elGroupNameInput.val(oldValue);
    }
    else if (newGroupName !== oldValue) {
        $('.group-name span').text(newGroupName);
        axios.post('/group/updateAjax', {
            "group_type": window.location.pathname,
            "group_id": groupId,
            "group_title": $(this).val()
        })
    }
    $(this).attr('type', 'hidden');
    $('.group-name__check-icon').addClass('d-none');

    groupNameBlock.removeClass('d-none');
});

$('body').on('click', '.js-destroy-group-btn', function(e) {
    let deleteBtn = e.target;
    if ($(deleteBtn).attr('data-target-group-id')) {
        const targetGroupId = $(deleteBtn).attr('data-target-group-id');

        $(`#destroyGroupForm-${targetGroupId}`).trigger('submit');
    } else {
        $(`#destroyGroupForm`).trigger('submit');
    }
});

$('.user-group__sub-group').hide();

$('.user-group__header .btn-details, #showFullGroupsStructure').on('click', function (e) {
    const groupId = $(this).data('id');
    const singleSubGroup = document.querySelector('.js-user-group__sub-group')

    if(!$(this).hasClass('uploaded')) {
        axios.get(`/get-groups-structure?id=${groupId}`)
            .then((response) => {
                    if (groupId !== 'all') {
                        $(this).addClass('uploaded')
                        if (singleSubGroup) {
                            $('.js-user-group__sub-group').append(response.data.structure)
                                .slideDown();
                        } else {
                            $(this).closest('li').find('.user-group__sub-group').first()
                                .append(response.data.structure)
                                .slideDown();
                        }
                        $(this).find('i').toggleClass('opened');
                    } else {
                        $('.user-group__header .btn-details').each(function (i) {
                            let id = $(this).data('id');
                            $(this).addClass('uploaded');
                            $(this).find('i').toggleClass('opened');

                            $(this).closest('li').find('.user-group__sub-group').first()
                                .empty()
                                .append(response.data.structure[id])
                                .slideDown();
                        });

                        $(this).addClass('uploaded');
                        $(this).find('i').toggleClass('opened');
                    }
                }
            );
    } else {
        if(groupId === 'all') {
            $('.user-group__header .btn-details').each(function (i) {
                let structure = $(this).closest('li').find('.user-group__sub-group').first();
                if (structure.css('display') === 'none') {
                    structure.slideToggle();
                    $(this).find('i').toggleClass('opened');
                }
            });
        } else {
            if (singleSubGroup) {
                $('.js-user-group__sub-group').slideToggle();
            } else {
                $(this).closest('li').find('.user-group__sub-group').first().slideToggle();
            }
            $(this).find('i').toggleClass('opened');

        }
    }
});
