$('body').on(
    'click',
    '.js-ledger-table td:not([data-paddock-id], .group-table-th), .cattle-return-table td:not(.table-secondary), .sma-details-table td',
    function (e) {

        clearTimeout(window.time)

        const reg = new RegExp('^[0-9]+$');
        let selectedValueContainer = $(this).find('a').length !== 0 ? $(this).find('a') : $(this)
        let selectedValue = $(selectedValueContainer).text().trim()

        if ( reg.test(selectedValue) && selectedValue !== '0' ) {
            $(this).toggleClass('in-calculated')
        }

        if ( $('.in-calculated').length > 1 ) {
            let sum = 0;
            $('.in-calculated').each(function (i) {
                let valueContainer = $(this).find('a').length !== 0 ? $(this).find('a') : $(this)
                let value = $(valueContainer).text().trim()

                sum += parseInt(value)
            })

            $('#sticky-side-modal').removeClass('d-none')
            $('#sticky-side-modal span').text(sum)

        } else {
            $('#sticky-side-modal').addClass('d-none')
        }

    })

$('#sticky-side-modal-settings').on('click', function (e) {
    $('#sticky-side-modal div a:last').toggleClass('d-none')
})

$('.js-clear-allocation').on('click', function (e) {
    $('#sticky-side-modal').addClass('d-none')
    $('.in-calculated').removeClass('in-calculated')
    $('#sticky-side-modal-settings').trigger('click')
})
