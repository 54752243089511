import {round} from "@popperjs/core/lib/utils/math";

export function changeWidthOfPaddockGroupsTable(table) {
    let widthCellsArray = []

    $('#ledger-table').find('thead:first tr:last td').each(function (i) {
        let cellWidth = $(this).css('width')

        if ( parseInt(cellWidth) < 20 ) {
            cellWidth = widthCellsArray[i - 1]
        }

        if ( $(this).find('div').length !== 0 ) {
            cellWidth = parseInt(cellWidth) + 10
        }

        //$(this).css('width', cellWidth)
        //$(this).css('min-width', cellWidth)
        //$(this).css('max-width', cellWidth)

        widthCellsArray.push(cellWidth)
    })

    $(table).find('thead tr td').each(function (i) {
        let cellWidth = widthCellsArray[i]

        $(this).css('width', cellWidth)
        $(this).css('min-width', cellWidth)
        $(this).css('max-width', cellWidth)
    })

    $(table).find(`tbody tr`).each(function () {
        $(this).find('td').each(function (i) {
            let cellWidth = widthCellsArray[i]

            $(this).css('width', cellWidth)
            $(this).css('min-width', cellWidth)
            $(this).css('max-width', cellWidth)
        })
    })
}
